<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Zustand der Parcours</h5>
        </div>
          <el-table :data="parcours" header-row-class-name="text-primary" empty-text="Es wurden keine Parcours gefunden.">
            <el-table-column prop="parcoursName" label="Parcours">
            </el-table-column>
            <el-table-column prop="inspector" label="Prüfer">
              <template slot-scope="scope">
                <div class="row pl-3">
                  <div class="column">
                    <el-select class="select"
                      placeholder="Prüfer wählen"
                      filterable
                      size="mini"
                      v-model="scope.row.inspector">
                      <el-option v-for="option in trainer"
                        class="select"
                        :value="option.trainerName"
                        :label="option.trainerName"
                        :key="option.trainerName">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="condition" label="Zustand">
              <template slot-scope="scope">
                <div class="row pl-3">
                  <div class="column">
                    <el-select class="select"
                      placeholder="Zustand wählen"
                      size="mini"
                      v-model="scope.row.condition">
                      <el-option class="select" value="1" label="Gut" key="1"></el-option>
                      <el-option class="select" value="2" label="Muss beobachtet werden" key="2"></el-option>
                      <el-option class="select" value="3" label="Mängel" key="3"></el-option>
                      <el-option class="select" value="4" label="Gesperrt wegen erheblicher Mängel" key="4"></el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="note" label="Anmerkung">
              <template slot-scope="scope">
                <div class="row pl-3">
                  <div class="column">
                    <el-input size="small"
                      type="textarea" autosize
                      style="text-align:center"
                      v-model="scope.row.note" controls-position="right" placeholder="Anmerkung zum Zustand"
                      :disabled="scope.row.condition == '1'"></el-input>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import Vue from 'vue'
  import { db } from '../../../../../firebaseDb'
  import {Table, TableColumn, Tooltip, Input, Select, Option, TimeSelect, TimePicker} from 'element-ui'
  import moment from 'moment-timezone'
  import router from '../../../../../main'
  import { MessageBox } from 'element-ui';
  import { Wizard, WizardTab} from 'src/components/UIComponents'
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import store from "../../../../../store"
  import Button from '../../../../UIComponents/Button.vue'

  extend("required", required);

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Tooltip)
  Vue.use(TimePicker)

export default {
  components: {
    Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect
  },
  data() {
    return {
      trainer: [],
      parcours: []
    };
  },
  created () {
    // get list of parcours
    const parcoursRef = db.collection('parcours')
    parcoursRef.where('locationID', '==', store.getters.userlocation).orderBy('position').onSnapshot((snapshotChange) => {
      this.parcours = []
      snapshotChange.forEach((doc) => {
        this.parcours.push({
          parcoursName: doc.data().Name,
          inspector: store.getters.HT,
          condition: '1',
          position: doc.data().position,
          note: ''
        })
      })
    })

    // get list of todays trainers
    const trainerRef = db.collection('trainer')
    trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).orderBy('Name').onSnapshot((snapshotChange) => {
      this.trainer = []
      snapshotChange.forEach((doc) => {
        this.trainer.push({
          trainerName: doc.data().Name
        })
      })
    })
  },
  mounted() {
    this.$watch(
      "$parent.active",
      (newVal, oldVal) => {
        if(newVal === true) {
          this.updateInspector()
        }
      }
    );
  },
  methods: {
    validate() {
      if(this.parcours.find(o => o.condition == '')) {
        return false
      } else {
        return true
      }
    },
    updateInspector () {
      for(let i in this.parcours) {
        this.parcours[i].inspector = store.getters.HT
      }
    }
  }
};
</script>
<style lang="scss">
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
}
</style>
