<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-auto">
            <h5 class="card-title">Kassenstart</h5>
          </div>
          <div class="col-md-auto pt-2">
            <el-radio-group v-model="kasse" size="medium" :change="getWechselgeld()">
              <el-radio-button label="Kasse 1"></el-radio-button>
              <el-radio-button label="Kasse 2" :disabled="location == 'DA'"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-sm-3 pb-3 pt-1" v-if="!alreadySaved">
            <ValidationProvider name="Trainer" rules="required" v-slot="{ errors }">
              <el-select class="select" placeholder="Trainer wählen" filterable v-model="trainerKassenstart">
                <el-option v-for="option in trainer" class="select" :value="option.trainerName"
                  :label="option.trainerName" :key="option.trainerName">
                </el-option>
              </el-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Der Trainer muss angegeben werden.' : null }}</span>
            </ValidationProvider>

          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <div class="row pt-4">
            <div class="col-md-3 offset-1 mr-5">
              <h6>Münzgeld</h6>
              <table class="table table-borderless table-sm mt-3 table-striped">
                <thead>
                  <th scope="col" class="w-25"></th>
                  <th scope="col" class="w-25">Anzahl</th>
                  <th scope="col" class="text-right w-25">Wert</th>
                </thead>
                <tbody>
                  <tr>
                    <td>1 Cent</td>
                    <td>{{ Cent1 }}</td>
                    <td class="text-right">
                      {{
                        (Cent1 * 0.01)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>2 Cent</td>
                    <td>{{ Cent2 }}</td>
                    <td class="text-right">
                      {{
                        (Cent2 * 0.02)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>5 Cent</td>
                    <td>{{ Cent5 }}</td>
                    <td class="text-right">
                      {{
                        (Cent5 * 0.05)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>10 Cent</td>
                    <td>{{ Cent10 }}</td>
                    <td class="text-right">
                      {{
                        (Cent10 * 0.1)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>20 Cent</td>
                    <td>{{ Cent20 }}</td>
                    <td class="text-right">
                      {{
                        (Cent20 * 0.2)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>50 Cent</td>
                    <td>{{ Cent50 }}</td>
                    <td class="text-right">
                      {{
                        (Cent50 * 0.5)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>1 Euro</td>
                    <td>{{ Euro1 }}</td>
                    <td class="text-right">
                      {{
                        (Euro1 * 1)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>2 Euro</td>
                    <td>{{ Euro2 }}</td>
                    <td class="text-right">
                      {{
                        (Euro2 * 2)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-3 ml-4">
              <h6>Scheingeld</h6>
              <table class="table table-borderless table-sm mt-3 table-striped">
                <thead>
                  <th scope="col" class="w-25"></th>
                  <th scope="col" class="w-25">Anzahl</th>
                  <th scope="col" class="text-right w-25">Wert</th>
                </thead>
                <tbody>
                  <tr>
                    <td>5 Euro</td>
                    <td>{{ Euro5 }}</td>
                    <td class="text-right">
                      {{
                        (Euro5 * 5)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>10 Euro</td>
                    <td>{{ Euro10 }}</td>
                    <td class="text-right">
                      {{
                        (Euro10 * 10)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>20 Euro</td>
                    <td>{{ Euro20 }}</td>
                    <td class="text-right">
                      {{
                        (Euro20 * 20)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>50 Euro</td>
                    <td>{{ Euro50 }}</td>
                    <td class="text-right">
                      {{
                        (Euro50 * 50)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>100 Euro</td>
                    <td>{{ Euro100 }}</td>
                    <td class="text-right">
                      {{
                        (Euro100 * 100)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>200 Euro</td>
                    <td>{{ Euro200 }}</td>
                    <td class="text-right">
                      {{
                        (Euro200 * 200)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>500 Euro</td>
                    <td>{{ Euro500 }}</td>
                    <td class="text-right">
                      {{
                        (Euro500 * 500)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                      €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-sm-8 pr-5 pt-1 pb-4 text-right">
            <h4 style="margin-top: 0 !important">
              Gesamt:
              {{
                (
                  wechselgeldSum
                )
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
              }}
              €
            </h4>
          </div>

          <div class="row" v-if="!alreadySaved">
            <div class="col-sm-8 offset-1">
              <div class="form-check ml-3">
                <input class="form-check-input" type="radio" id="wechselgeldKorrekt" name="wechselgeld" value=true
                  v-model="wechselgeldKorrekt" checked>
                <label class="form-check-label font-size14 pl-2" for="wechselgeldKorrekt"><b>Der angezeigte
                    Wechselgeldbestand ist korrekt und stimmt mit dem in {{ kasse }} vorhandenem Geld
                    überein.</b></label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" type="radio" id="wechselgeldFalsch" name="wechselgeld" value=false
                  v-model="wechselgeldKorrekt">
                <label class="form-check-label font-size14 pl-2" for="wechselgeldFalsch"><b>Der angezeigte
                    Wechselgeldbestand ist nicht korrekt.</b></label>
              </div>
            </div>

          </div>
          <div class="row pt-3" v-if="wechselgeldKorrekt == 'false'">
            <div class="col-sm-7 offset-1">
              <span class="">Bitte die festgestellten Abweichungen beim Wechselgeld beschreiben:</span>
              <textarea class="form-control px-2 mt-2 mb-3" placeholder="Festgestellte Abweichungen..." rows="3"
                v-model="beschreibungAbweichung"></textarea>
              <span style="color: red;"><b>ACHTUNG: Die Kasse trotz Abweichung mit dem oben angezeigten Soll-Betrag
                  öffnen!</b></span>
            </div>

          </div>
          <div class="row pt-2" v-if="!alreadySaved">
            <div class="col-sm-12 pr-5 pt-1 pb-3 text-right">
              <p-button type="success" @click="save()">Kassenstart
                speichern</p-button>
            </div>
          </div>
          <div class="row" v-if="alreadySaved">
            <div class="col-sm-5 offset-1">
              <el-alert :description="'Der Kassenstart für ' + kasse + ' wurde heute bereits gespeichert.'" type="success"
                show-icon :closable="false" class="mb-3">
              </el-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover, RadioButton, RadioGroup, Radio, Select, Option, MessageBox } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Radio);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    MessageBox
  },

  data() {
    return {
      loading: true,
      location: store.getters.userlocation,
      alreadySaved: false,
      kasse: "Kasse 1",
      trainer: [],
      trainerKassenstart: "",
      kassenbestand: 0,
      Cent1: 0,
      Cent2: 0,
      Cent5: 0,
      Cent10: 0,
      Cent20: 0,
      Cent50: 0,
      Euro1: 0,
      Euro2: 0,
      Euro5: 0,
      Euro10: 0,
      Euro20: 0,
      Euro50: 0,
      Euro100: 0,
      Euro200: 0,
      Euro500: 0,
      wechselgeldKorrekt: true,
      wechselgeldstand: null,
      beschreibungAbweichung: "",
      wechselgeldSum: 0,
      reportName: moment().format("YYYYMMDD") + '-' + store.getters.userlocation,
    };
  },
  created() {
    // get list of trainers (HT and Kasse)
    async function getTrainerKasseOrHT() {
      const trainerRef = db.collection("trainer");
      const isKasse = trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).where("isKasse", "==", true).get();
      const isHT = trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).where("isHT", "==", true).get();

      const [KasseQuerySnapshot, HTQuerySnapshot] = await Promise.all([
        isKasse,
        isHT,
      ]);

      const KasseArray = KasseQuerySnapshot.docs;
      const HTArray = HTQuerySnapshot.docs;

      const trainerArray = KasseArray.concat(HTArray);

      return trainerArray;
    }

    getTrainerKasseOrHT().then((result) => {
      this.trainer = [];
      result.forEach((doc) => {
        const index = this.trainer.findIndex((element) => {
          if (element.trainerName == doc.data().Name) {
            return true;
          }
        });
        if (index === -1) {
          this.trainer.push({
            trainerName: doc.data().Name,
          });
        }
      });
    });
    this.getWechselgeld().then((result) => {
      this.loading = false;
    })

  },
  methods: {
    getWechselgeld() {
      this.checkIfExists()
      const kassenbestandRef = db.collection("kassenbestand");
      kassenbestandRef
        .doc(this.kasse + '-' + store.getters.userlocation)
        .get()
        .then((doc) => {
          this.Cent1 = doc.data().Cent1
          this.Cent2 = doc.data().Cent2
          this.Cent5 = doc.data().Cent5
          this.Cent10 = doc.data().Cent10
          this.Cent20 = doc.data().Cent20
          this.Cent50 = doc.data().Cent50
          this.Euro1 = doc.data().Euro1
          this.Euro2 = doc.data().Euro2
          this.Euro5 = doc.data().Euro5
          this.Euro10 = doc.data().Euro10
          this.Euro20 = doc.data().Euro20
          this.Euro50 = doc.data().Euro50
          this.Euro100 = doc.data().Euro100
          this.Euro200 = doc.data().Euro200
          this.Euro500 = doc.data().Euro500
          this.wechselgeldstand = new Date(doc.data().Date.seconds * 1000)
          this.wechselgeldSum = doc.data().WechselgeldSum
        });
    },
    save() {
      if (this.trainerKassenstart == '') {
        MessageBox.alert(
          'Bitte auswählen, welcher Trainer den Kassenstart durchgeführt hat.',
          {
            confirmButtonText: "OK",
            type: "warning",
            callback: (action) => { },
          }
        );
      } else if ((this.wechselgeldKorrekt == false || this.wechselgeldKorrekt == 'false') && (this.beschreibungAbweichung.length < 3)) {
        MessageBox.alert(
          'Bitte die festgestellte Abweichung beim  Wechselgeld beschreiben (z.B. "Es fehlt ein 2 Euro Stück").',
          {
            confirmButtonText: "OK",
            type: "warning",
            callback: (action) => { },
          }
        );
      } else {
        const kassenstartRef = db.collection(
          "tagesreports/" + this.reportName + "/Kassenstart"
        );
        kassenstartRef.doc(this.kasse).set({
          Timestamp: new Date(),
          trainerKassenstart: this.trainerKassenstart,
          wechselgeldKorrekt: this.wechselgeldKorrekt,
          beschreibungAbweichung: this.beschreibungAbweichung,
        }).then(() => {
          this.$notify({
            message: 'Kassenstart wurde erfolgreich gespeichert!',
            icon: 'fa fa-check-circle',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'success',
            showClose: true,
            timeout: 5000
          })
          router.push({ name: 'Overview' })
        });
      }

    },
    checkIfExists() {
      const kassenstartRef = db.collection(
        "tagesreports/" + this.reportName + "/Kassenstart"
      );
      kassenstartRef.doc(this.kasse).get().then((doc) => {
        if (doc.exists) {
          this.alreadySaved = true
        } else {
          this.alreadySaved = false
        }
      })
    }
  },
};
</script>
<style>
.el-table .cell {
  word-break: break-word !important;
}

.table-sm td,
.table-sm th {
  padding: 0.2rem !important;
}

.font-size14 {
  font-size: 14px !important;
}
</style>
