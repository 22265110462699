<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="title">Projekte</h5>
      </div>
      <div class="col-md-auto">
        <el-select
                v-model="filterCategory"
                placeholder="Kategorie"
                @change="updateFilterCategory"
              >
              <el-option
                key="Alle"
                label="Alle Projekte"
                value="Alle"
              ></el-option>
              <el-option
                key="Bau"
                label="Bau"
                value="Bau"
              ></el-option>
              <el-option
                key="Betrieb"
                label="Betrieb"
                value="Betrieb"
              ></el-option>
              <el-option
                key="Büro"
                label="Büro"
                value="Büro"
              ></el-option>
        </el-select>
      </div>
      <div class="col-md-auto text-right" v-if="role > 1">
        <p-button type="success" @click="addProject()" class="mt-0"><i
            class="bi bi-file-earmark-plus"></i> Neues Projekt</p-button>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-lg-auto" v-for="project in projects" :key="project.key">

    <div class="card project">
      <h5 class="card-header project-header" :style=getProjectStyle(project) @click="editProject(project)">{{ project.title }}</h5>
      <div class="card-body">
        <div v-for="task in project.tasks" :key="task.key" class="card task shadow" @click="editTask(project, task)">
          <h6 class="card-header">{{ task.title }}</h6>
          <div class="card-body">            
            <div class="row">
              <div class="col-md-6 pl-0">
                <el-tag
                  :type="task.status == 'offen' ? 'danger' : task.status == 'in Arbeit' ? 'warning' : task.status == 'erledigt' ? 'success' : 'info'"
                  :closable="false"
                  size="small"
                  v-if="task.status"
                  class="task-tag"
                >
                  {{task.status}}
                </el-tag>
              </div>
              <div class="col-md-6">
                <p class="card-text" v-if="task.dueDate"><b><i class="bi bi-calendar-check"></i></b> {{ task.dueDate }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="card-text" v-if="task.assignedTo"><b><i class="bi bi-person-fill"></i></b> {{ task.assignedTo }}</p>
              </div>
            </div>
          </div>
        </div>
        <p-button type="neutral" @click="addTask(project.key)" class="mt-0" icon><i
            class="bi bi-plus-lg"></i></p-button>
      </div>
    </div>
    </div>
    </div>

    <modal
      :show.sync="showProjectModal"
      headerClasses="justify-content-center"
      modalMaxWidth="600px"
    >
      <h5 slot="header" class="title mb-0" v-if="mode == 'add'">Neues Projekt</h5>
      <h5 slot="header" class="title mb-0" v-if="mode == 'edit'">Projekt bearbeiten</h5>

      <div class="row mt-3 ml-2">
        <label>Titel</label>
        <el-input v-model="editProjectTitle" placeholder="Titel des Projekts" prefix-icon="bi bi-kanban"></el-input>
      </div>
      <div class="row mt-3 ml-2">
        <label>Kategorie</label>
        <el-select
                    class="select"
                    placeholder="Kategorie"
                    filterable
                    v-model="editProjectCategory"
                    @change="updateFilterCategory"
                  >
                    <el-option
                      class="select"
                      value="Bau"
                      label="Bau"
                      key="Bau"
                    ></el-option>
                    <el-option
                      class="select"
                      value="Betrieb"
                      label="Betrieb"
                      key="Betrieb"
                    ></el-option>
                    <el-option
                      class="select"
                      value="Büro"
                      label="Büro"
                      key="Büro"
                    ></el-option>
          </el-select>
      </div>
      <div class="row mt-4 ml-2">
        <label>Farbe</label>
      </div>
      <div class="row mt-0 ml-0">
        <div class="col-md-6 pl-2">
          <el-color-picker v-model="editProjectColor"></el-color-picker>
        </div>
        <div class="col-md-6">
          <p-button type="danger" @click="removeProject()" class="mt-0" v-if="mode == 'edit'"><i
            class="bi bi-trash3"></i> Projekt entfernen</p-button>
        </div>
      </div>
      
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showProjectModal = false; editProjectTitle = ''; editProjectColor = ''"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="saveProject" :disabled="editProjectTitle == null || editProjectTitle == ''"
            >Speichern</p-button
          >
        </div>
      </template>
    </modal>

    <modal
      :show.sync="showTaskModal"
      headerClasses="justify-content-center"
      modalMaxWidth="600px"
    >
      <h5 slot="header" class="title mb-0" v-if="mode == 'add'">Neue Aufgabe</h5>
      <h5 slot="header" class="title mb-0" v-if="mode == 'edit'">Aufgabe bearbeiten</h5>

      <div class="row mt-3 ml-2">
        <label>Titel</label>
        <el-input v-model="editTaskTitle" placeholder="Titel der Aufgabe" prefix-icon="bi bi-list-task"></el-input>
      </div>
      <div class="row mt-3 ml-2">
        <label>Beschreibung</label>
        <el-input v-model="editTaskDescription" placeholder="Beschreibung" type="textarea" :rows="3"></el-input>
      </div>
      <div class="row mt-3 ml-2">
        <div class="col-md-6 pl-0">
          <label>Status</label>
          <el-select
                    class="select"
                    placeholder="Status"
                    filterable
                    v-model="editTaskStatus"
                  >
                    <el-option
                      class="select"
                      value="offen"
                      label="offen"
                      key="offen"
                    ></el-option>
                    <el-option
                      class="select"
                      value="in Arbeit"
                      label="in Arbeit"
                      key="in Arbeit"
                    ></el-option>
                    <el-option
                      class="select"
                      value="erledigt"
                      label="erledigt"
                      key="erledigt"
                    ></el-option>
                    <el-option
                      class="select"
                      value="warten"
                      label="warten"
                      key="warten"
                    ></el-option>
          </el-select>
        </div>
        <div class="col-md-6 pr-0">
          <label>Fälligkeitsdatum</label>
          <el-date-picker
                    v-model="editTaskDueDate"
                    type="date"
                    placeholder="Fälligkeitsdatum"
                    :picker-options="pickerOptions1"
                    format="dd.MM.yyyy"
                  >
                  </el-date-picker>
        </div>
      </div>
      <div class="row mt-3 ml-2 mb-4">
        <label>Zugewiesen an</label>
        <el-select
                  class="select"
                  placeholder="Person wählen"
                  filterable
                  v-model="editTaskAssignedTo"
                >
                  <el-option
                    v-for="option in trainer"
                    class="select"
                    :value="option.trainerName"
                    :label="option.trainerName"
                    :key="option.trainerName"
                  >
                  </el-option>
                </el-select>
      </div>
      <div class="row mt-3 ml-2 mb-4">
        <p-button type="danger" @click="removeTask()" class="mt-0" v-if="mode == 'edit'"><i
            class="bi bi-trash3"></i> Aufgabe entfernen</p-button>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showTaskModal = false; editTaskAssignedTo = null; editTaskDescription = null; editTaskDueDate = null; editTaskStatus = null; editTaskTitle = null"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="saveTask()" :disabled="editTaskStatus == null || editTaskTitle == null || editTaskTitle == ''"
            >Speichern</p-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Loading,
  Popover,
  MessageBox,
  Input,
  Select,
  Option,
  ColorPicker,
  Tag
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import firebase from "firebase/compat/app";
import store from "../../../../store";
import { Modal } from "src/components/UIComponents";
import SelectLocation from "/src/components/KET/Views/Components/KETSelectLocation.vue";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);
Vue.use(ColorPicker);

export default {
  components: {
    SelectLocation,
    MessageBox,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tag.name]: Tag
  },

  data() {
    return {
      loading: true,
      trainer: [],
      location: store.getters.userlocation,
      role: store.getters.userrole,
      projects: "",
      showProjectModal: false,
      showTaskModal: false,
      editProjectTitle: null,
      editProjectColor: null,
      editProjectHidden: false,
      editProjectId: null,
      editProjectCategory: null,
      editTaskTitle: null,
      editTaskDescription: null,
      editTaskStatus: null,
      editTaskDueDate: null,
      editTaskAssignedTo: null,
      editTaskHidden: false,
      editTaskId: null,
      editTaskProjectId: null,
      filterCategory: "Alle",
      mode: "add",
      pickerOptions1: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "In 1 Woche",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
          {
            text: "In 2 Wochen",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 14);
              picker.$emit("pick", date);
            },
          },
          {
            text: "In 1 Monat",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getProjects();
    this.getTrainer();
  },
  methods: {
    // get all projects from firestore and the tasks from each project (subcollection of project) and store it in projects array
    getProjects() {
      const projectsRef = db.collection("projects");
      var query = null;
      if(this.filterCategory == 'Alle' || this.filterCategory == null) {
        query = projectsRef.where('Hidden', '==', false).orderBy("Title");
      } else {
        query = projectsRef.where('Hidden', '==', false).where('Category', '==', this.filterCategory).orderBy("Title");
      }
      
      var tasks = [];
      query.get().then((querySnapshot) => {
        this.projects = [];
        querySnapshot.forEach((doc) => {
          const tasksRef = db
            .collection("projects")
            .doc(doc.id)
            .collection("Tasks");
          tasksRef.where('Hidden', '==', false).orderBy("Title").get().then((taskSnapshot) => {
            tasks = [];
            
            taskSnapshot.forEach((task) => {
              var created = null;
              var lastUpdated = null;
              var dueDate = null;
              var dueDateRaw = null;
              if (task.data().Created != null) {
                created = moment(new Date(task.data().Created.seconds * 1000)).format("DD.MM.YY").toString();;
              } else {
                created = null;
              }
              if (task.data().LastUpdated != null) {
                lastUpdated = moment(new Date(task.data().LastUpdated.seconds * 1000)).format("DD.MM.YY").toString();;
              } else {
                lastUpdated = null;
              }
              if (task.data().DueDate != null) {
                dueDate = moment(new Date(task.data().DueDate.seconds * 1000)).format("DD.MM.YY").toString();
                dueDateRaw = moment(new Date(task.data().DueDate.seconds * 1000));
              } else {
                dueDate = null;
                dueDateRaw = null;
              }
              tasks.push({
                key: task.id,
                title: task.data().Title,
                description: task.data().Description,
                status: task.data().Status,
                dueDate: dueDate,
                dueDateRaw: dueDateRaw,
                created: created,
                lastUpdated: lastUpdated,
                assignedTo: task.data().AssignedTo,
              });
            });
            this.projects.push({
              key: doc.id,
              title: doc.data().Title,
              color: doc.data().Color,
              category: doc.data().Category,
              tasks: tasks,
            });
          });
        });
        this.loading = false;
      });
    },
    getTrainer() {
      // get list of trainers
      const trainerRef = db.collection("trainer");
      trainerRef
        .orderBy("Name")
        .onSnapshot((snapshotChange) => {
          this.trainer = [];
          snapshotChange.forEach((doc) => {
            this.trainer.push({
              trainerName: doc.data().Name,
            });
          });
        });
    },
    addProject() {
      this.editProjectTitle = null;
      this.editProjectColor = this.getRandomColor();
      this.editProjectCategory = null;
      this.mode = "add";
      this.showProjectModal = true;
    },
    saveProject() {
      if (this.mode == "add") {
        db.collection("projects")
          .add({
            Title: this.editProjectTitle,
            Color: this.editProjectColor,
            Category: this.editProjectCategory,
            Hidden: false,
            Created: new Date(),
          })
          .then(() => {
            this.showProjectModal = false;
            this.editProjectTitle = null;
            this.editProjectColor = null;
            this.editProjectHidden = false;
            this.editTaskProjectId = null;
            this.editProjectCategory = null;
            this.getProjects();
          })
          .catch(() => {
            console.log("Adding project failed");
          });
      } else if (this.mode == "edit") {
        db.collection("projects")
          .doc(this.editProjectId)
          .update({
            Title: this.editProjectTitle,
            Color: this.editProjectColor,
            Category: this.editProjectCategory,
            LastUpdated: new Date(),
          })
          .then(() => {
            this.showProjectModal = false;
            this.editProjectTitle = null;
            this.editProjectColor = null;
            this.editProjectHidden = false;
            this.editTaskProjectId = null;
            this.editProjectCategory = null;
            this.getProjects();
          })
          .catch(() => {
            console.log("Updating project failed");
          });
      }
    },
    addTask(p) {
      this.mode = "add";
      this.editTaskTitle = null;
      this.editTaskDescription = null;
      this.editTaskStatus = "offen";
      this.editTaskDueDate = null;
      this.editTaskAssignedTo = null;
      this.editTaskProjectId = p;
      this.showTaskModal = true;
    },
    saveTask() {
      if (this.mode == "add") {
        db.collection("projects").doc(this.editTaskProjectId).collection("Tasks")
          .add({
            Title: this.editTaskTitle,
            Description: this.editTaskDescription,
            Status: this.editTaskStatus,
            DueDate: this.editTaskDueDate,
            AssignedTo: this.editTaskAssignedTo,
            Hidden: false,
            Created: new Date(),
          })
          .then(() => {
            this.showTaskModal = false;
            this.editTaskTitle = null;
            this.editTaskDescription = null;
            this.editTaskStatus = null;
            this.editTaskDueDate = null;
            this.editTaskAssignedTo = null;
            this.editTaskHidden = false;
            this.getProjects();
          })
          .catch(() => {
            console.log("Adding task failed");
          });
      } else if (this.mode == "edit") {
        db.collection("projects").doc(this.editTaskProjectId).collection("Tasks")
          .doc(this.editTaskId)
          .update({
            Title: this.editTaskTitle,
            Description: this.editTaskDescription,
            Status: this.editTaskStatus,
            DueDate: this.editTaskDueDate,
            AssignedTo: this.editTaskAssignedTo,
            LastUpdated: new Date(),
          })
          .then(() => {
            this.showTaskModal = false;
            this.editTaskTitle = null;
            this.editTaskDescription = null;
            this.editTaskStatus = null;
            this.editTaskDueDate = null;
            this.editTaskAssignedTo = null;
            this.editTaskHidden = false;
            this.getProjects();
          })
          .catch(() => {
            console.log("Updating task failed");
          });
      }
    },
    editProject(p) {
      this.editProjectTitle = p.title;
      this.editProjectColor = p.color;
      this.editProjectId = p.key;
      this.editProjectCategory = p.category;
      this.mode = "edit";
      this.showProjectModal = true;
    },
    editTask(p, t) {
      this.editTaskTitle = t.title,
      this.editTaskDescription = t.description,
      this.editTaskStatus = t.status
      if(t.dueDateRaw != null) {
        this.editTaskDueDate = moment(t.dueDateRaw).toDate();
      } else {
        this.editTaskDueDate = null;
      }
      this.editTaskAssignedTo = t.assignedTo,
      this.editTaskId = t.key;
      this.editTaskProjectId = p.key;
      this.mode = "edit";
      this.showTaskModal = true;
    },
    removeProject() {
      MessageBox.confirm(
        "Soll das Projekt " + this.editProjectTitle + " wirklich gelöscht werden?",
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              // remove project
              db.collection("projects")
                .doc(this.editProjectId)
                .update({
                  Hidden: true,
                });
              this.showProjectModal = false;
              this.getProjects();
            }
          },
        }
      );
    },
    removeTask() {
      MessageBox.confirm(
        "Soll die Aufgabe " + this.editTaskTitle + " wirklich gelöscht werden?",
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              // remove task
              db.collection("projects").doc(this.editTaskProjectId).collection("Tasks")
                .doc(this.editTaskId)
                .update({
                  Hidden: true,
                });
              this.showTaskModal = false;
              this.getProjects();
            }
          },
        }
      );
    },
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color
    },
    getProjectStyle(p) {
      return {
        '--projectColor': p.color,
      };
    },
    updateFilterCategory() {
      this.getProjects();
    }
  },
};
</script>
<style>
.project-header {
  padding-bottom: 10px !important;
  border-bottom: 6px solid var(--projectColor) !important;
  background-color: rgb(235, 234, 234) !important;
}
.project {
  max-width: 100% !important;
  min-width: 300px !important;
}
.btn-project-edit {
  background-color: rgb(235, 234, 234) !important;;
}
textarea.form-control,
.form-group textarea.el-input__inner {
  max-height: 250px !important;
}
.task-tag {
  color: white !important;
}
.task-tag.el-tag--info {
  background-color: #8d8e8e !important;
}
</style>
