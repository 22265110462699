<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Rabatte verwalten</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6">
              </div>
              <div class="col-md-6" v-if="role == 3">
                <div>
                  <el-select v-model="location" placeholder="Standort wählen" @change="loadRabatte">
                    <el-option key="NB" label="Neroberg" value="NB"></el-option>
                    <el-option key="DA" label="Darmstadt" value="DA"></el-option>
                    <el-option key="FRA" label="Frankfurt" value="FRA"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="rabatte" header-row-class-name="text-primary" empty-text="Es wurden keine Rabatte gefunden.">
            <el-table-column prop="rabattGruppe" label="Rabattgruppe" width="350">
            </el-table-column>
            <el-table-column prop="rabattBeschreibung" label="Beschreibung" width="850">
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="right" label="">
              <template slot-scope="props">
                <el-tooltip content="Rabatt bearbeiten" placement="top">
                  <p-button type="success" size="sm" icon @click="editRabatt(props.$index, props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Rabatt löschen" placement="top">
                  <p-button type="danger" size="sm" icon @click="removeRabatt(props.$index, props.row)">
                    <i class="fa fa-trash"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <!--  end col-md-6  -->
        </div>
        <div class="col-sm-12 text-right mt-4 mb-2">
          <p-button type="primary" @click="modals.newRabatt = true">Neuer Rabatt</p-button>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.newRabatt" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">Neuer Rabatt</h5>
      <fg-input placeholder="Bezeichnung der Rabattgruppe (z.B. Studierende)" label="Rabattgruppe"
        v-model="newRabattGruppe"></fg-input>
      <label class="control-label">Beschreibung</label>
      <textarea class="form-control px-2" placeholder="Beschreibung des Rabatts (z.B. 10 %) und Bedingungen" rows="5"
        v-model="newRabattBeschreibung"></textarea>
      <p-button type="default" @click="chooseFileNew" v-if="!imageData">Anhang hochladen</p-button>
      <p class="pt-3" v-if="imageData">Anhang: {{ imageData.name }}</p>
      <input type="file" @change="prepareImage" ref="imageuploadNew" accept="image/*" style="display: none">

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="
  modals.newRabatt = false;
newRabattGruppe = null;
newRabattBeschreibung = null;
imageData = null;
          ">Abbrechen</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="addRabatt">Rabatt speichern</p-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="modals.editRabatt" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">Rabatt bearbeiten</h5>
      <fg-input placeholder="Bezeichnung der Rabattgruppe (z.B. Studierende)" label="Rabattgruppe"
        v-model="editRabattGruppe"></fg-input>
      <label class="control-label">Beschreibung</label>
      <textarea class="form-control px-2" placeholder="Beschreibung des Rabatts (z.B. 10 %) und Bedingungen" rows="5"
        v-model="editRabattBeschreibung"></textarea>
      <img v-if="editRabattAttachment" :src="editRabattAttachment" width="300px" class="p-3" />
      <p-button type="default" @click="chooseFileEdit" v-if="!imageData">Anhang hochladen</p-button>
      <p class="pt-3" v-if="imageData">Anhang: {{ imageData.name }}</p>
      <input type="file" @change="prepareImage" ref="imageuploadEdit" accept="image/*" style="display: none">

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="
  modals.editRabatt = false;
editRabattGruppe = null;
editRabattBeschreibung = null;
imageData = null;
          ">Abbrechen</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="modifyRabatt">Rabatt speichern</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { db, FirebaseStorage } from "../../../../firebaseDb";
import { Table, TableColumn, Tooltip, Select, Option, Input, Upload } from "element-ui";
import { Modal } from "src/components/UIComponents";
import store from "../../../../store";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Upload.name]: Upload,
    Modal,
  },

  data() {
    return {
      rabatte: [],
      newRabattGruppe: null,
      newRabattBeschreibung: null,
      editRabattGruppe: null,
      editRabattBeschreibung: null,
      editRabattAttachment: null,
      editRabattID: null,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      modals: {
        editRabatt: false,
        newRabatt: false,
      },
      imageData: null,
      uploadValue: 0
    };
  },
  created() {
    this.loadRabatte();
  },
  methods: {
    loadRabatte() {
      const rabatteRef = db.collection("rabatte");
      rabatteRef.where('locationID', '==', this.location).orderBy("Group").onSnapshot((snapshotChange) => {
        this.rabatte = [];
        snapshotChange.forEach((doc) => {
          this.rabatte.push({
            key: doc.id,
            rabattGruppe: doc.data().Group,
            rabattBeschreibung: doc.data().Discount,
            rabattAttachment: doc.data().Attachment || null
          });
        });
      });
    },
    removeRabatt(index, row) {
      db.collection("rabatte")
        .doc(row.key)
        .delete()
        .then(() => {
          console.log("Rabatt " + row.rabattGruppe + " removed.");
        })
        .catch(() => {
          console.log("Error removing Rabatt.");
        });
    },
    chooseFileNew() {
      this.$refs.imageuploadNew.click()
    },
    chooseFileEdit() {
      this.$refs.imageuploadEdit.click()
    },
    prepareImage(event) {
      this.uploadValue = 0;
      this.imageData = event.target.files[0];
    },
    addRabatt() {
      if (this.newRabattGruppe && this.newRabattBeschreibung) {
        if (this.imageData) {
          const storageRef = FirebaseStorage.ref(this.imageData.name).put(this.imageData);
          storageRef.on('state_changed', snapshot => {
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }, error => { console.log(error.message) },
            () => {
              this.uploadValue = 100;
              storageRef.snapshot.ref.getDownloadURL().then((url) => {
                db.collection("rabatte")
                  .add({
                    Group: this.newRabattGruppe,
                    Discount: this.newRabattBeschreibung,
                    locationID: this.location,
                    Attachment: url
                  })
                  .then(() => {
                    this.newRabattGruppe = null;
                    this.newRabattBeschreibung = null;
                    this.imageData = null;
                    this.modals.newRabatt = false;
                    console.log("Rabatt with attachment added.");
                  })
                  .catch(() => {
                    console.log("Adding Rabatt with attachment failed");
                  });
              });
            }
          );
        } else {
          db.collection("rabatte")
            .add({
              Group: this.newRabattGruppe,
              Discount: this.newRabattBeschreibung,
              locationID: this.location,
              Attachment: null
            })
            .then(() => {
              this.newRabattGruppe = null;
              this.newRabattBeschreibung = null;
              this.imageData = null;
              this.modals.newRabatt = false;
              console.log("Rabatt added.");
            })
            .catch(() => {
              console.log("Adding Rabatt failed");
            });
        }
      }
    },
    editRabatt(index, row) {
      this.editRabattID = row.key
      this.editRabattGruppe = row.rabattGruppe
      this.editRabattBeschreibung = row.rabattBeschreibung
      this.editRabattAttachment = row.rabattAttachment
      this.modals.editRabatt = true
    },
    modifyRabatt() {
      if (this.editRabattGruppe && this.editRabattBeschreibung) {
        if (this.imageData) {
          const storageRef = FirebaseStorage.ref(this.imageData.name).put(this.imageData);
          storageRef.on('state_changed', snapshot => {
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }, error => { console.log(error.message) },
            () => {
              this.uploadValue = 100;
              storageRef.snapshot.ref.getDownloadURL().then((url) => {
                db.collection("rabatte")
                  .doc(this.editRabattID)
                  .update({
                    Group: this.editRabattGruppe,
                    Discount: this.editRabattBeschreibung,
                    locationID: this.location,
                    Attachment: url
                  })
                  .then(() => {
                    this.editRabattGruppe = null;
                    this.editRabattBeschreibung = null;
                    this.imageData = null;
                    this.modals.editRabatt = false;
                    console.log("Rabatt with attachment updated.");
                  })
                  .catch(() => {
                    console.log("Error updating rabatt with attachment " + error);
                  });
              });
            }
          );
        } else {
          db.collection("rabatte")
            .doc(this.editRabattID)
            .update({
              Group: this.editRabattGruppe,
              Discount: this.editRabattBeschreibung,
              locationID: this.location
            })
            .then(() => {
              this.editRabattGruppe = null;
              this.editRabattBeschreibung = null;
              this.imageData = null;
              this.modals.editRabatt = false;
              console.log("Rabatt updated.");
            })
            .catch(() => {
              console.log("Error updating rabatt " + error);
            });
        }
      }

    },
  },
};
</script>
<style>
.el-table .cell {
  word-break: break-word !important;
}
</style>
