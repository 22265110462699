import "firebase/auth"
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import "firebase/firestore"
import "firebase/functions"
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import store from "./store"
//import * as firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyBN3kjPImTRLZ5sTWjnS6AbNhqnZPKyATA",
    authDomain: "ket2-600f4.firebaseapp.com",
    projectId: "ket2-600f4",
    storageBucket: "ket2-600f4.appspot.com",
    messagingSenderId: "37562700665",
    appId: "1:37562700665:web:1f6ae1782c9a0d3faf15a8"
}

// const firebaseApp = 
export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()
export const storage = getStorage(firebaseApp);
export const FirebaseStorage = firebase.storage();
const fc = getFunctions(firebaseApp, "europe-west3");
// connectFunctionsEmulator(fc, "localhost", 5001);
export { fc };

firebaseApp.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        let userdetails = new Object()
        const unsubscribe = firebaseApp.auth().onAuthStateChanged(user => {
            if(user) {
                firebaseApp.auth().currentUser.getIdTokenResult(true).then((tokenResult) => {
                    console.log('loc: ' + tokenResult.claims.location + ' role: ' + tokenResult.claims.role)
                    store.commit('SET_USERLOCATION', tokenResult.claims.location)
                    store.commit('SET_USERROLE', tokenResult.claims.role)
                })
            }
            store.dispatch("fetchUser", user),
            unsubscribe(),
            resolve(user)
        }, reject)
    })
}

export const auth = firebase.auth()
export const provider = new firebase.auth.EmailAuthProvider();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

// export const db = firebaseApp.firestore()

const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
db.settings({ timestampsInSnapshots: true }) 