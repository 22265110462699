<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">

      </nav>
      <div class="copyright pull-right">
        KET - Version {{version}}
      </div>
    </div>
  </footer>
</template>
<script>
  import store from "../../../store"
  export default {
    data() {
      return {
        version: store.getters.appVersion
      }
    },
  }

</script>
<style>

</style>
