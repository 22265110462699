<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Toilettenliste {{ monthHeader }}</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6" v-if="role == 3">
            <div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadReport"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>
            </div>
              <div class="col">
              <div>
              <el-date-picker
                v-model="monthStart"
                type="month"
                placeholder="Monat wählen"
                :clearable="false"
                :picker-options="pickerOptions1"
                format="MMMM yyyy"
                @change="loadReport"
              >
              </el-date-picker>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 pb-3">
          <el-table
            :data="reportByDate"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Einträge gefunden werden."
            v-loading="loading"
          >
            <el-table-column prop="date" label="Datum" width="220">
            </el-table-column>
            <el-table-column prop="trainerName" label="Trainer">
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-12 pt-4">
          <el-table
            :data="reportByName"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Reinigungen gefunden werden."
            v-loading="loading"
            :default-sort="{ prop: 'trainerName', order: 'ascending' }"
          >
            <el-table-column prop="trainerName" label="Trainer" width="300">
            </el-table-column>
            <el-table-column prop="count" label="Anzahl der Reinigungen">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Tooltip,
  Loading,
  Select,
  Option
} from "element-ui";
import moment from "moment-timezone";
import store from "../../../../store";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Loading);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      loading: true,
      reportByDate: [],
      reportByName: [],
      monthStart: moment().startOf("month").toDate(),
      monthEnd: moment().endOf("month").toDate(),
      monthHeader: moment().format("MMMM YYYY"),
      location: store.getters.userlocation,
      role: store.getters.userrole,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
        shortcuts: [],
      },
    };
  },
  created() {
    moment.locale("de");
    // get data from reports of selected month
    this.loadReport();
  },
  methods: {
    loadReport() {
      this.loading = true
      this.monthEnd = moment(this.monthStart).endOf("month").toDate();
      this.monthHeader = moment(this.monthStart).format("MMMM YYYY");
      const listRef = db.collection("tagesreports");
      listRef
        .where("Date", ">=", this.monthStart)
        .where("Date", "<=", this.monthEnd)
        .where('locationID', '==', this.location)
        .orderBy("Date")
        .onSnapshot((snapshotChange) => {
          this.reportByDate = [];
          this.reportByName = [];
          snapshotChange.forEach((doc) => {
            if (doc.data().Toiletten) {
              this.reportByDate.push({
                date: moment(new Date(doc.data().Date.seconds * 1000))
                  .format("dd, DD.MM.yyyy")
                  .toString(),
                trainerName: doc.data().Toiletten,
              });

              if (doc.data().Toiletten != "Keine Reinigung") {
                if (
              this.reportByName.filter(
                (e) => e.trainerName === doc.data().Toiletten
              ).length > 0
              ) {
                this.reportByName[
                  this.reportByName.findIndex(
                    (obj) => obj.trainerName === doc.data().Toiletten
                  )
                ].count++;
              } else {
                this.reportByName.push({
                  trainerName: doc.data().Toiletten,
                  count: 1,
                });
              }
              }
            }
            
          });
          this.loading = false
        });
    },
  },
};
</script>
<style>
</style>
