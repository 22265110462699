<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Basis/Premium Pakete {{ date }}</h5>
          </div>
          <div class="col-sm-3">
            <el-date-picker
              v-model="date"
              type="date"
              placeholder="Datum auswählen"
              :clearable="false"
              :picker-options="pickerOptions1"
              format="dd.MM.yyyy"
              value-format="dd.MM.yyyy"
              @change="getBookings(date)"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="bookings"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Buchungen gefunden werden."
            v-loading="loading"
          >
            <el-table-column prop="time" label="Uhrzeit" width="120"></el-table-column>
            <el-table-column prop="customer" label="Name"></el-table-column>
            <el-table-column prop="tables" label="Tische" width="100" align="center"></el-table-column>
            <el-table-column prop="product" label="Paket"></el-table-column>
            <el-table-column prop="participants" label="Teilnehmer" width="150" align="center"></el-table-column>
            <el-table-column prop="bookingId" label="Bestell-Nr.">
              <template slot-scope="scope">
                <a :href="'https://www.kletterwald-darmstadt.de/contao?do=booking_orders_details&id=' + scope.row.bookingId" target="_blank" v-if="location == 'DA'"><i class="bi bi-box-arrow-up-right"></i> {{ scope.row.bookingId }}</a>
                <a :href="'https://www.kletterwald-neroberg.de/contao?do=booking_orders_details&id=' + scope.row.bookingId" target="_blank" v-else-if="location == 'NB'"><i class="bi bi-box-arrow-up-right"></i> {{ scope.row.bookingId }}</a>
                <span v-else>{{ scope.row.bookingId }}</span>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions"
              align="right"
              label="">
              <template slot-scope="scope">
                <el-tooltip content="Urkunden drucken" placement="top">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="printCertificates(scope.row.attendeeFirstnames)"
                    :disabled="scope.row.attendeeFirstnames.length == 0"
                  >
                    <i class="bi bi-file-earmark-medical"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import { httpsCallable } from "firebase/functions";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
  },

  data() {
    return {
      loading: true,
      bookings: [],
      location: store.getters.userlocation,
      date: moment().format("DD.MM.YYYY"),
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < moment().startOf("day").valueOf();
        },
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
    };
  },
  created() {
    // get bookings from Contao
    this.getBookings(this.date);
  },
  methods: {
    getBookings(date) {
      console.log("Getting bookings from Contao for day " + date + " in " + this.location + "...");
      this.loading = true;
      var getSpecials = httpsCallable(fc, "contao-getBasicPremiumPackages");
        getSpecials({
          location: this.location,
          date: date
        }).then((result) => {
          console.log('Received data from Contao.')
          this.bookings = result.data.packages
          this.loading = false
        });
    },
    printCertificates(names) {
      console.log("Printing certificates...");

      var pdfMake = require('pdfmake/build/pdfmake.min.js')
      var pdfFonts = require('../../../../assets/fonts/vfs_fonts.js')
      pdfMake.vfs = pdfFonts.pdfMake.vfs

      var ct = []

      for (var i = 0; i < names.length; i++) {
        if(names[i] != '') {
          if(i == (names.length - 1)) {
            ct.push({ text: names[i], style: 'certificateName', absolutePosition: {y: 433} })
          } else {
            ct.push({ text: names[i], style: 'certificateName', absolutePosition: {y: 433}, pageBreak: 'after' })
          }
        }
      }

      pdfMake.fonts = {
        Kalam: {
          normal: 'Kalam-Regular.ttf',
          bold: 'Kalam-Bold.ttf',
          italics: 'Kalam-Light.ttf',
          bolditalics: 'Kalam-Bold.ttf'
        },
      }
      var docDefinition = {
          content: ct,
          styles: {
            certificateName: {
              fontSize: 50,
              font: 'Kalam',
              bold: true,
              alignment: 'center',
            }
          }
        }

      pdfMake.createPdf(docDefinition).print()
    },
  },
};
</script>
<style>
</style>
