<template>
  <div v-loading="loading">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <h5 class="card-title">Tagesreport {{ reportDateHeader }}</h5>
              </div>
              <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6" v-if="role == 3">
            <div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadReport"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>
            </div>
              <div class="col">
              <div>
                  <el-date-picker
                    v-model="reportDateTimeStart"
                    type="date"
                    placeholder="Datum wählen"
                    :clearable="false"
                    :picker-options="pickerOptions1"
                    format="dd.MM.yyyy"
                    @change="loadReport"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
          <div class="card-body row pb-3">
            <div class="col-md-2"><b>HT:</b> {{ HT }}</div>
            <div class="col-md-2">
              <b>Geöffnet:</b> {{ Open }}<br /><b>Geschlossen:</b> {{ Close }}
            </div>
            <div class="col-md-2"><b>Wetter:</b> {{ Weather }}</div>
            <div class="col"><b>Trainer:</b> {{ Trainer }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-9">
                <h6 class="card-title">Kassenstart</h6>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col">
              <collapse>
                <collapse-item
                  v-for="(start) in Kassenstart"
                  :title="start.Title"
                  :name="start.Title"
                  :key="start.Title"
                >
                  <div>
                    <div class="row">
                      <div class="col"><b>Trainer:</b></div>
                      <div class="col text-right">
                        {{
                          start.Trainer
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Uhrzeit:</b></div>
                      <div class="col text-right">
                        {{
                          start.Timestamp
                        }} Uhr
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Wechselgeld:</b></div>
                      <div class="col text-right">
                        <span v-if="start.WechselgeldKorrekt == 'Korrekt'" style="color: green"><b>
                          {{
                            start.WechselgeldKorrekt
                          }}
                        </b></span>
                        <span v-else style="color: red"><b>
                          {{
                            start.WechselgeldKorrekt
                          }}
                        </b></span>
                        
                      </div>
                    </div>
                    <div class="row mt-2" v-if="start.WechselgeldKorrekt == 'Nicht korrekt'">
                      <div class="col"><b>Beschreibung der Abweichung:</b></div>
                      <div class="col text-right">
                        {{
                          start.Abweichung
                        }}
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </div>
          </div>
        </div>
        
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-9">
                <h6 class="card-title">Kassenabschlüsse</h6>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col">
              <collapse>
                <collapse-item
                  v-for="(abschluss, index) in Kasse"
                  :title="abschluss.Title"
                  :name="abschluss.Title"
                  :key="abschluss.Title"
                >
                  <div>
                    <div class="row">
                      <div class="col"><b>Aktueller Kassenbestand:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Kassenbestand.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Kassenstartbetrag:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Wechselgeldstart.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>EC-Kassenschnitt:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.ECSchnitt.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                        ({{ abschluss.ECPercent.toFixed(0) + " %" }})
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Gutscheine:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Gutscheine.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }} ({{ abschluss.GutscheineAnzahl.toFixed(0) }})
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Stornos:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Stornos.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }} ({{ abschluss.StornosAnzahl.toFixed(0) }})
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Online bezahlt:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Online.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                        ({{ abschluss.OnlinePercent.toFixed(0) + " %" }})
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Soll-Bestand:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.SollBestand.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Kassendifferenz:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Kassendifferenz.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <b>Wechselgeld nach Kassenabrechnung:</b>
                      </div>
                      <div class="col text-right">
                        {{
                          abschluss.WechselgeldEnde.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Ausgaben (Gesamtbetrag):</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Ausgaben.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Summe der Rechnungen:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.SummeRechnungen.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Z-FIN GESAMT:</b></div>
                      <div class="col text-right">
                        <b>{{
                          abschluss.ZFin.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}</b>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Bankeinzahlung (Entnahme):</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.Bankeinzahlung.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Gesamt unbar bezahlt:</b></div>
                      <div class="col text-right">
                        {{
                          abschluss.CashlessTotal.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                        }}
                        ({{ abschluss.CashlessPercent.toFixed(0) + " %" }})
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Kasse gegengezählt durch:</b></div>
                      <div class="col text-right">
                        {{ abschluss.TrainerRecount }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Bankeinzahlung durch:</b></div>
                      <div class="col text-right">
                        {{ abschluss.TrainerBankeinzahlung }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col"><b>Kassenabschluss:</b></div>
                      <div class="col text-right">
                        {{ abschluss.KassenTrainer }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <b>Rechnungen (Buchungs-Nummern):</b>
                      </div>
                      <div class="col text-right">
                        {{ abschluss.Rechnungen }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <b>Veranstaltungen (Buchungs-Nummern):</b>
                      </div>
                      <div class="col text-right">
                        {{ abschluss.Veranstaltungen }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <b>Erstellt:</b>
                      </div>
                      <div class="col text-right">
                        {{ abschluss.Timestamp }} Uhr
                      </div>
                    </div>
                    <div class="row mt-4" v-if="abschluss.isValid">
                      <div class="col">
                        
                      </div>
                      <div class="col text-right">
                        <p-button type="default" @click="editKasse(index)">
                    <i class="fa fa-edit"></i> Bearbeiten
                  </p-button>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </div>
          </div>
        </div>

        <div class="">
          <time-line type="simple">
            <time-line-item
              class="timeline-inverted"
              v-for="anmerkung in Anmerkungen"
              :key="anmerkung.key"
              :badgeType="anmerkung.type"
              badgeIcon="nc-icon nc-single-copy-04"
            >
              <badge slot="header" type="danger" v-if="anmerkung.important"
                >Wichtig!</badge
              >
              <p slot="content">{{ anmerkung.text }}</p>

              <h6 slot="footer" class="pt-2">
                <i class="fa fa-clock-o"></i>
                {{ anmerkung.timestamp }} Uhr
              </h6>
            </time-line-item>
          </time-line>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-9">
                <h6 class="card-title">Checklisten</h6>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col">
              <collapse>
                <collapse-item title="Betriebsbeginn HT" name="HTStart">
                  <div class="col-sm-12">
                    <el-table
                      :data="HTStart"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="HTStartProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ HTStartProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Betriebsbeginn Kasse" name="KasseStart">
                  <div class="col-sm-12">
                    <el-table
                      :data="KasseStart"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="KasseStartProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ KasseStartProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Betriebsende HT" name="HTEnde">
                  <div class="col-sm-12">
                    <el-table
                      :data="HTEnde"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="HTEndeProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ HTEndeProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Betriebsende Kasse" name="KasseEnde">
                  <div class="col-sm-12">
                    <el-table
                      :data="KasseEnde"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="KasseEndeProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ KasseEndeProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Veranstaltung Start" name="VAStart" v-if="VAStart.length > 0">
                  <div class="col-sm-12">
                    <el-table
                      :data="VAStart"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="VAStartProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ VAStartProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Veranstaltung Ende" name="VAEnde" v-if="VAEnde.length > 0">
                  <div class="col-sm-12">
                    <el-table
                      :data="VAEnde"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="VAEndeProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ VAEndeProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Büro" name="Buero" v-if="Buero.length > 0">
                  <div class="col-sm-12">
                    <el-table
                      :data="Buero"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Aufgabe"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Erledigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Wert"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="BueroProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ BueroProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
                <collapse-item title="Hüttencheckliste" name="Huette" v-if="Huette.length > 0">
                  <div class="col-sm-12">
                    <el-table
                      :data="Huette"
                      header-row-class-name="text-primary"
                      empty-text="Checkliste wurde nicht ausgefüllt."
                    >
                      <el-table-column
                        prop="Task"
                        label="Zu prüfen"
                        width="300"
                      ></el-table-column>
                      <el-table-column
                        prop="checked"
                        label="Wird benötigt"
                        width="100"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.checked" class="text-center">
                            <i
                              class="bi bi-check-circle-fill"
                              style="font-size: 1.2rem; color: green"
                            ></i>
                          </div>
                          <div v-if="!scope.row.checked" class="text-center">
                            <i
                              class="bi bi-x-circle-fill"
                              style="font-size: 1.2rem; color: red"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="benötigte Menge"
                      ></el-table-column>
                    </el-table>
                    <div class="row pt-4 pl-2" v-if="HuetteProcessedBy.length > 0">
                      <i>Ausgefüllt von: {{ HuetteProcessedBy }}</i>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-9">
                <h6 class="card-title">Parcours</h6>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12">
              <el-table
                :data="Parcours"
                header-row-class-name="text-primary"
                empty-text="Checkliste wurde nicht ausgefüllt."
              >
                <el-table-column prop="Name" label="Parcours"></el-table-column>
                <el-table-column
                  prop="Inspector"
                  label="Prüfer"
                ></el-table-column>
                <el-table-column
                  prop="Condition"
                  label="Zustand"
                ><template slot-scope="scope">
                  <span v-if="scope.row.Condition == 'Gut'">{{ scope.row.Condition }}</span>
                  <el-tooltip v-else :content="scope.row.Note" placement="left">
                    <span>{{ scope.row.Condition }}</span>
                  </el-tooltip>
                </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.editKassenabschluss" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">
        Kassenabschluss {{ editKassenabschlussTitle }} bearbeiten
      </h5>
      <div class="row">
        <label class="col-md-6 col-form-label">Aktueller Kassenbestand</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussKassenbestand" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Kassenstartbetrag</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussWechselgeldstart" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">EC-Kassenschnitt</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussEC" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Gutscheine</label>
        <div class="col-md-2">
          <fg-input type="number" v-model="editKassenabschlussGutscheineAnzahl" min="0">
            <template slot="addonRight">Gutscheine</template>
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input type="number" v-model="editKassenabschlussGutscheine" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Stornos</label>
        <div class="col-md-2">
          <fg-input type="number" v-model="editKassenabschlussStornosAnzahl" min="0">
            <template slot="addonRight">Stornos</template>
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input type="number" v-model="editKassenabschlussStornos" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Online bezahlt</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussOnline" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Soll-Bestand</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussSoll" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Wechselgeld nach Kassenabrechnung</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussWechselgeld" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Ausgaben (Gesamtbetrag)</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussAusgaben" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Bankeinzahlung (Entnahme)</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussEntnahme" min="0">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Rechnungen (Buchungs-Nummern)</label>
        <div class="col-md-6">
          <fg-input type="text" v-model="editKassenabschlussRechnungen">
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Veranstaltungen (Buchungs-Nummern)</label>
        <div class="col-md-6">
          <fg-input type="text" v-model="editKassenabschlussVeranstaltungen">
          </fg-input>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-md-6 col-form-label">Summe der Rechnungen</label>
        <div class="col-md-6">
          <fg-input type="number" v-model="editKassenabschlussSummeRechnungen">
            <template slot="addonRight">€</template>
          </fg-input>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="modals.editKassenabschluss = false"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="save">Speichern</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Tooltip,
  Loading,
  Select,
  Option
} from "element-ui";
import moment from "moment-timezone";
import {
  Collapse,
  CollapseItem,
  TimeLine,
  TimeLineItem,
  Badge,
  Modal
} from "src/components/UIComponents";
import { doc } from "@firebase/firestore";
import store from "../../../../store";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Loading);

export default {
  components: {
    CollapseItem,
    Collapse,
    TimeLine,
    TimeLineItem,
    Badge,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      loading: true,
      reportDateTimeStart: moment().startOf("day").toDate(),
      reportDateTimeEnd: moment().startOf("day").toDate(),
      reportDateHeader: moment().format("DD.MM.YYYY"),
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
      HT: "",
      Open: "",
      Close: "",
      Weather: "",
      Trainer: "",
      Parcours: [],
      Kasse: [],
      Kassenstart: [],
      HTStart: [],
      HTEnde: [],
      KasseStart: [],
      KasseEnde: [],
      Huette: [],
      VAStart: [],
      VAEnde: [],
      Buero: [],
      VAStartProcessedBy: '',
      VAEndeProcessedBy: '',
      HuetteProcessedBy: '',
      BueroProcessedBy: '',
      HTStartProcessedBy: '',
      HTEndeProcessedBy: '',
      KasseStartProcessedBy: '',
      KasseEndeProcessedBy: '',
      Anmerkungen: [],
      location: store.getters.userlocation,
      role: store.getters.userrole,
      modals: {
        editKassenabschluss: false,
      },
      // Edit Kassenabschluss
      editKassenabschlussTitle: 0,
      editKassenabschlussKassenbestand: 0,
      editKassenabschlussWechselgeldstart: 0,
      editKassenabschlussEC: 0,
      editKassenabschlussGutscheine: 0,
      editKassenabschlussGutscheineAnzahl: 0,
      editKassenabschlussStornos: 0,
      editKassenabschlussStornosAnzahl: 0,
      editKassenabschlussOnline: 0,
      editKassenabschlussSoll: 0,
      editKassenabschlussWechselgeld: 0,
      editKassenabschlussAusgaben: 0,
      editKassenabschlussEntnahme: 0,
      editKassenabschlussRechnungen: '',
      editKassenabschlussVeranstaltungen: '',
      editKassenabschlussSummeRechnungen: 0,
    };
  },
  created() {
    moment.locale("de");

    if (this.$route.params.date) {
      this.reportDateTimeStart = moment(this.$route.params.date, "YYYYMMDD")
        .startOf("day")
        .toDate();
    }
    // get data from reports of selected month
    this.loadReport();
  },
  methods: {
    loadReport() {
      this.loading = true
      this.reportDateTimeEnd = moment(this.reportDateTimeStart)
        .endOf("day")
        .toDate();
      this.reportDateHeader = moment(this.reportDateTimeStart).format(
        "DD.MM.YYYY"
      );
      var reportName = moment(this.reportDateTimeStart).format("YYYYMMDD") + '-' + this.location;

      // load general data
      const reportRef = db.collection("tagesreports");
      reportRef
        .doc(reportName)
        .get()
        .then((doc) => {
          if (doc.exists) {
            (this.HT = doc.data().HT || "-"),
              (this.Open = doc.data().Open || "-" + " Uhr"),
              (this.Close = doc.data().Close || "-" + " Uhr"),
              (this.Weather = doc.data().Wetter || "-"),
              (this.Trainer = doc.data().Trainer || "-");
          } else {
            (this.HT = "-"),
              (this.Open = "-"),
              (this.Close = "-"),
              (this.Weather = "-"),
              (this.Trainer = "-");
          }
        });

      // load parcours check protocoll
      const parcoursRef = db.collection(
        "tagesreports/" + reportName + "/Parcours"
      );
      parcoursRef.orderBy("Position").onSnapshot((snapshotChange) => {
        this.Parcours = [];
        snapshotChange.forEach((doc) => {
          var condition = "";
          if (doc.data().Condition == 1) {
            condition = "Gut";
          }
          if (doc.data().Condition == 2) {
            condition = "Muss beobachtet werden";
          }
          if (doc.data().Condition == 3) {
            condition = "Mängel";
          }
          if (doc.data().Condition == 4) {
            condition = "Gesperrt wegen erheblicher Mängel";
          }
          this.Parcours.push({
            Name: doc.data().Name,
            Inspector: doc.data().Inspector,
            Condition: condition,
            Note: doc.data().Note
          });
        });
      });

      // load checklists
      const htstartRef = db.collection(
        "tagesreports/" + reportName + "/HTStart"
      );
      htstartRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.HTStart = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.HTStartProcessedBy = doc.data().processedBy
          } else {
            this.HTStart.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      const htendeRef = db.collection("tagesreports/" + reportName + "/HTEnde");
      htendeRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.HTEnde = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.HTEndeProcessedBy = doc.data().processedBy
          } else {
            this.HTEnde.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      const kassestartRef = db.collection(
        "tagesreports/" + reportName + "/KasseStart"
      );
      kassestartRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.KasseStart = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.KasseStartProcessedBy = doc.data().processedBy
          } else {
            this.KasseStart.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      const kasseendeRef = db.collection(
        "tagesreports/" + reportName + "/KasseEnde"
      );
      kasseendeRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.KasseEnde = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.KasseEndeProcessedBy = doc.data().processedBy
          } else {
            this.KasseEnde.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      const vastartRef = db.collection(
        "tagesreports/" + reportName + "/VAStart"
      );
      vastartRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.VAStart = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.VAStartProcessedBy = doc.data().processedBy
          } else {
            this.VAStart.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });
      const vaendeRef = db.collection(
        "tagesreports/" + reportName + "/VAEnde"
      );
      vaendeRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.VAEnde = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.VAEndeProcessedBy = doc.data().processedBy
          } else {
            this.VAEnde.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      const bueroRef = db.collection(
        "tagesreports/" + reportName + "/Buero"
      );
      bueroRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.Buero = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.BueroProcessedBy = doc.data().processedBy
          } else {
            this.Buero.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      const huetteRef = db.collection(
        "tagesreports/" + reportName + "/Huette"
      );
      huetteRef.orderBy("position").onSnapshot((snapshotChange) => {
        this.Huette = [];
        snapshotChange.forEach((doc) => {
          if(doc.id == 'trainer') {
            this.HuetteProcessedBy = doc.data().processedBy
          } else {
            this.Huette.push({
              Task: doc.data().Task,
              checked: doc.data().checked,
              value: doc.data().value,
            });
          }
        });
      });

      // load Kassenstart
      const kassenStartRef = db.collection(
        "tagesreports/" + reportName + "/Kassenstart"
      );
      kassenStartRef.onSnapshot((snapshotChange) => {
        this.Kassenstart = [];
        snapshotChange.forEach((doc) => {
          var wechselgeld = ''
          if(doc.data().wechselgeldKorrekt == true) {
            wechselgeld = "Korrekt"
          } else {
            wechselgeld = "Nicht korrekt"
          }
          this.Kassenstart.push({
            Title: doc.id,
            Trainer: doc.data().trainerKassenstart,
            Timestamp: moment(new Date(doc.data().Timestamp.seconds * 1000))
                  .format("HH:mm")
                  .toString(),
            WechselgeldKorrekt: wechselgeld,
            Abweichung: doc.data().beschreibungAbweichung || ""
          })
        })
        this.loading = false
      });
      // load Kasse
      const kasseRef = db.collection(
        "tagesreports/" + reportName + "/Kassenabschluss"
      );
      kasseRef.orderBy("Kasse").onSnapshot((snapshotChange) => {
        this.Kasse = [];
        snapshotChange.forEach((doc) => {
          var title = ''
          if(doc.data().isValid) {
            title = doc.id
          } else {
            title = doc.id + ' (ungültig)'
          }
          this.Kasse.push({
            Title: title,
            Date: doc.data().Date,
            isValid: doc.data().isValid,
            Timestamp: moment(new Date(doc.data().Timestamp.seconds * 1000))
                .format("DD.MM.YY HH:mm:ss")
                .toString(),
            Kasse: doc.data().Kasse,
            Ausgaben: doc.data().Ausgaben,
            Bankeinzahlung: doc.data().Bankeinzahlung,
            ECSchnitt: doc.data().ECSchnitt,
            Gutscheine: doc.data().Gutscheine,
            GutscheineAnzahl: doc.data().GutscheineAnzahl || 0,
            Stornos: doc.data().Stornos || 0,
            StornosAnzahl: doc.data().StornosAnzahl || 0,
            Kassenbestand: doc.data().Kassenbestand,
            KassenbestandDenomination: doc.data().KassenbestandDenomination,
            Kassendifferenz: doc.data().Kassendifferenz,
            KassenTrainer: doc.data().Kassentrainer,
            HT: doc.data().HT,
            banktransferDoc: doc.data().banktransferDoc,
            Online: doc.data().Online,
            Rechnungen: doc.data().Rechnungen,
            SollBestand: doc.data().SollBestand,
            TrainerBankeinzahlung: doc.data().TrainerBankeinzahlung,
            TrainerRecount: doc.data().TrainerRecount,
            Veranstaltungen: doc.data().Veranstaltungen,
            WechselgeldEnde: doc.data().WechselgeldEnde,
            WechselgeldDenomination: doc.data().WechselgeldDenomination,
            Wechselgeldstart: doc.data().Wechselgeldstart,
            SummeRechnungen: doc.data().SummeRechnungen || 0,
            ZFin: doc.data().ZFin,
            ECPercent: (doc.data().ECSchnitt * 100) / doc.data().ZFin || 0,
            OnlinePercent: (doc.data().Online * 100) / doc.data().ZFin || 0,
            CashlessTotal: doc.data().Online + +doc.data().ECSchnitt,
            CashlessPercent:
              ((doc.data().Online + +doc.data().ECSchnitt) * 100) /
              doc.data().ZFin || 0, 
          });
        });
        this.loading = false
      });

      // load Anmerkungen
      const anmerkungenRef = db.collection("anmerkungen");
      anmerkungenRef
        .where("timestamp", ">=", this.reportDateTimeStart)
        .where("timestamp", "<=", this.reportDateTimeEnd)
        .where('locationID', '==', this.location)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshotChange) => {
          this.Anmerkungen = [];
          snapshotChange.forEach((doc) => {
            var type = null;
            if (doc.data().important) {
              type = "danger";
            } else {
              type = "success";
            }
            this.Anmerkungen.push({
              key: doc.id,
              text: doc.data().text,
              important: doc.data().important,
              timestamp: moment(new Date(doc.data().timestamp.seconds * 1000))
                .format("dddd, DD. MMM HH:mm")
                .toString(),
              type: type,
            });
          });
        });
    },
    editKasse(index) {
      this.editKassenabschlussDate = this.Kasse[index].Date
      this.editKassenabschlussKasse = this.Kasse[index].Kasse
      this.editKassenabschlussTitle = this.Kasse[index].Title
      this.editKassenabschlussWechselgeldstart = this.Kasse[index].Wechselgeldstart.toFixed(2)
      this.editKassenabschlussKassentrainer = this.Kasse[index].KassenTrainer
      this.editKassenabschlussHT = this.Kasse[index].HT
      this.editKassenabschlussTrainerBankeinzahlung = this.Kasse[index].TrainerBankeinzahlung
      this.editKassenabschlussTrainerRecount = this.Kasse[index].TrainerRecount
      this.editKassenabschlussKassenbestand = this.Kasse[index].Kassenbestand.toFixed(2)
      this.editKassenabschlussEC = this.Kasse[index].ECSchnitt.toFixed(2)
      this.editKassenabschlussGutscheine = this.Kasse[index].Gutscheine.toFixed(2)
      this.editKassenabschlussGutscheineAnzahl = this.Kasse[index].GutscheineAnzahl.toFixed(0)
      this.editKassenabschlussStornos = this.Kasse[index].Stornos.toFixed(2)
      this.editKassenabschlussStornosAnzahl = this.Kasse[index].StornosAnzahl.toFixed(0)
      this.editKassenabschlussOnline = this.Kasse[index].Online.toFixed(2)
      this.editKassenabschlussSoll = this.Kasse[index].SollBestand.toFixed(2)
      this.editKassenabschlussWechselgeld = this.Kasse[index].WechselgeldEnde.toFixed(2)
      this.editKassenabschlussAusgaben = this.Kasse[index].Ausgaben.toFixed(2)
      this.editKassenabschlussEntnahme = this.Kasse[index].Bankeinzahlung.toFixed(2)
      this.editKassenabschlussRechnungen = this.Kasse[index].Rechnungen
      this.editKassenabschlussVeranstaltungen = this.Kasse[index].Veranstaltungen
      this.editKassenabschlussbanktransferDoc = this.Kasse[index].banktransferDoc
      this.editKassenabschlussSummeRechnungen = this.Kasse[index].SummeRechnungen.toFixed(2)
      // show modal
      this.modals.editKassenabschluss = true
    },
    save() {
      const kassenabschlussRef = db.collection(
        "tagesreports/" + moment(this.reportDateTimeStart).format("YYYYMMDD") + '-' + this.location + "/Kassenabschluss"
      );
      const banktransferRef = db.collection("banktransfer");

      var batch = db.batch();

      batch.set(kassenabschlussRef.doc(this.editKassenabschlussTitle + '-1'), {
        Date: this.editKassenabschlussDate,
        Kasse: this.editKassenabschlussKasse,
        Wechselgeldstart: parseFloat(this.editKassenabschlussWechselgeldstart),
        Kassentrainer: this.editKassenabschlussKassentrainer,
        HT: this.editKassenabschlussHT,
        TrainerBankeinzahlung: this.editKassenabschlussTrainerBankeinzahlung,
        TrainerRecount: this.editKassenabschlussTrainerRecount ,
        Bankeinzahlung: parseFloat(this.editKassenabschlussEntnahme),
        Kassenbestand: parseFloat(this.editKassenabschlussKassenbestand),
        WechselgeldEnde: parseFloat(this.editKassenabschlussWechselgeld),
        ECSchnitt: parseFloat(this.editKassenabschlussEC),
        SollBestand: parseFloat(this.editKassenabschlussSoll),
        Gutscheine: parseFloat(this.editKassenabschlussGutscheine),
        GutscheineAnzahl: parseFloat(this.editKassenabschlussGutscheineAnzahl),
        Stornos: parseFloat(this.editKassenabschlussStornos),
        StornosAnzahl: parseFloat(this.editKassenabschlussStornosAnzahl),
        Online: parseFloat(this.editKassenabschlussOnline),
        Ausgaben: parseFloat(this.editKassenabschlussAusgaben),
        SummeRechnungen: parseFloat(this.editKassenabschlussSummeRechnungen),
        Rechnungen: this.editKassenabschlussRechnungen,
        Veranstaltungen: this.editKassenabschlussVeranstaltungen,
        ZFin: parseFloat(parseFloat(this.editKassenabschlussEC) + +parseFloat(this.editKassenabschlussSoll) + +parseFloat(this.editKassenabschlussAusgaben) + +parseFloat(this.editKassenabschlussEntnahme) + +parseFloat(this.editKassenabschlussSummeRechnungen) + +parseFloat(this.editKassenabschlussOnline) - parseFloat(this.editKassenabschlussWechselgeldstart)),
        Kassendifferenz: this.editKassenabschlussKassenbestand - this.editKassenabschlussEntnahme -this.editKassenabschlussSoll,
        banktransferDoc: this.editKassenabschlussbanktransferDoc,
        Timestamp: new Date(),
        isValid: true
      });
      batch.update(kassenabschlussRef.doc(this.editKassenabschlussTitle), { isValid: false })

      batch.update(banktransferRef.doc(this.editKassenabschlussbanktransferDoc), {
        betrag: parseFloat(this.editKassenabschlussEntnahme),
        confirmed: false,
      });

      batch.commit().then(() => {
        // hide modal
        this.modals.editKassenabschluss = false
      })     
    }
  },
};
</script>
<style>
.card-collapse .card .card-header {
  font-weight: bold;
}
</style>
