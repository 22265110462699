import { firebaseApp } from '../firebaseDb'
import DashboardLayout from '../components/KET/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/KET/Views/Dashboard/Overview.vue')


// Pages
import Login from 'src/components/KET/Views/Pages/Login.vue'

// Components pages
import KETListTrainer from 'src/components/KET/Views/Components/KETListTrainer.vue'
import KETEditTrainer from 'src/components/KET/Views/Components/KETEditTrainer.vue'
import KETKontaktliste from 'src/components/KET/Views/Components/KETKontaktliste.vue'
import KETRetterstatus from 'src/components/KET/Views/Components/KETRetterstatus.vue'
import KETAbwesenheiten from 'src/components/KET/Views/Components/KETAbwesenheiten.vue'
import KETPrintPDF from 'src/components/KET/Views/Components/KETPrintPDF.vue'
import KETManageChecklists from 'src/components/KET/Views/Components/KETManageChecklists.vue'
import KETManageParcours from 'src/components/KET/Views/Components/KETManageParcours.vue'
import KETShowChecklist from 'src/components/KET/Views/Components/KETShowChecklist.vue'
import KETTagesprotokoll from 'src/components/KET/Views/Components/KETTagesprotokoll.vue'
import KETAnmerkungen from 'src/components/KET/Views/Components/KETAnmerkungen.vue'
import KETToilettenliste from 'src/components/KET/Views/Components/KETToilettenliste.vue'
import KETBankeinzahlungen from 'src/components/KET/Views/Components/KETBankeinzahlungen.vue'
import KETKassenabschluss from 'src/components/KET/Views/Components/KETKassenabschluss.vue'
import KETTagesreport from 'src/components/KET/Views/Components/KETTagesreport.vue'
import KETTrainerschluessel from 'src/components/KET/Views/Components/KETTrainerschluessel.vue'
import KETUsers from 'src/components/KET/Views/Components/KETUsers.vue'
import KETVeranstaltungen from 'src/components/KET/Views/Components/KETVeranstaltungen.vue'
import KETVeranstaltungDetails from 'src/components/KET/Views/Components/KETVeranstaltungDetails.vue'
import KETBetriebsordner from 'src/components/KET/Views/Components/KETBetriebsordner.vue'
import KETRabattliste from 'src/components/KET/Views/Components/KETRabattliste.vue'
import KETManageRabatte from 'src/components/KET/Views/Components/KETManageRabatte.vue'
import KETKassenstart from 'src/components/KET/Views/Components/KETKassenstart.vue'
import KETBuschtrommel from 'src/components/KET/Views/Components/KETBuschtrommel.vue'
import KETHubgeraete from 'src/components/KET/Views/Components/KETHubgeraete.vue'
import KETVerleihliste from 'src/components/KET/Views/Components/KETVerleihliste.vue'
import KETKanban from 'src/components/KET/Views/Components/KETKanban.vue'
import KETExam from 'src/components/KET/Views/Components/KETExam.vue'
import KETSpecials from 'src/components/KET/Views/Components/KETSpecials.vue'
import KETPsaOverview from 'src/components/KET/Views/Components/KETPsaOverview.vue'

let HTMenu = {
  path: '/ht',
  component: DashboardLayout,
  redirect: '/ht/kontaktliste',
  children: [
    {
      path: 'kontaktliste',
      name: 'Kontaktliste',
      component: KETKontaktliste,
      meta: {
        auth: true,
        trainerBasis: true
      }
    },
    {
      path: 'retterstatus',
      name: 'Retterstatus',
      component: KETRetterstatus,
      meta: {
        auth: true,
        trainerBasis: true
      }
    },
    {
      path: 'tagesprotokoll',
      name: 'Tagesprotokoll',
      component: KETTagesprotokoll,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    },
    {
      path: 'anmerkungen',
      name: 'Anmerkungen',
      component: KETAnmerkungen,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    },
    {
      path: 'betriebsordner',
      name: 'Betriebsordner',
      component: KETBetriebsordner,
      meta: {
        auth: true,
        trainerBasis: true
      }
    },
    {
      path: 'reportrate',
      name: 'Reportrate',
      component: KETTrainerschluessel,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    },
    {
      path: 'buschtrommel',
      name: 'Buschtrommel',
      component: KETBuschtrommel,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: true
      }
    },
    {
      path: 'hubgeraete',
      name: 'Hub-Geräte',
      component: KETHubgeraete,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    },
    {
      path: 'projekte',
      name: 'Projekte',
      component: KETKanban,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    },
  ]
}

let VAMenu = {
  path: '/va',
  component: DashboardLayout,
  redirect: '/va/veranstaltungen',
  children: [
    {
      path: 'veranstaltungen',
      name: 'Veranstaltungen',
      component: KETVeranstaltungen,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: true
      }
    },
    {
      path: 'vadetails/:vaId?',
      name: 'VaDetails',
      component: KETVeranstaltungDetails,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    },
  ]
}

let PSAMenu = {
  path: '/psa',
  component: DashboardLayout,
  redirect: '/psa/smarties',
  children: [
    {
      path: 'smarties',
      name: 'Smartbelay-Sets',
      component: KETPsaOverview,
      meta: {
        auth: true,
        admin: false,
        trainerBasis: false
      }
    }
  ]
}

let PLMenu = {
  path: '/pl',
  component: DashboardLayout,
  redirect: '/pl/trainer',
  children: [
    {
      path: 'trainer',
      name: 'Trainer',
      component: KETListTrainer,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'edittrainer/:trainerId',
      name: 'editTrainer',
      component: KETEditTrainer,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'exam',
      name: 'exams',
      component: KETExam,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'abwesenheiten',
      name: 'Abwesenheiten',
      component: KETAbwesenheiten,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'managelists',
      name: 'ManageLists',
      component: KETManageChecklists,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'parcours',
      name: 'Parcours',
      component: KETManageParcours,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'toilettenliste',
      name: 'Toilettenliste',
      component: KETToilettenliste,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'bankeinzahlungen',
      name: 'Bankeinzahlungen',
      component: KETBankeinzahlungen,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'tagesreport/:date?',
      name: 'Tagesreport',
      component: KETTagesreport,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'users',
      name: 'Users',
      component: KETUsers,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'rabatte',
      name: 'Rabatte',
      component: KETManageRabatte,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    },
    {
      path: 'verleihliste',
      name: 'Verleihliste',
      component: KETVerleihliste,
      meta: {
        auth: true,
        admin: true,
        trainerBasis: false
      }
    }
  ]
}




let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    auth: false
  }
}



const routes = [
  {
    path: '/',
    component: Overview,
    redirect: '/KET/overview',
    meta: {
      auth: true
    }
  },
  loginPage,
  PLMenu,
  HTMenu,
  VAMenu,
  PSAMenu,

  {
    path: '/KET',
    component: DashboardLayout,
    redirect: '/KET/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
        meta: {
          auth: true
        }
      },
      {
        path: '/print/:file',
        name: 'Print',
        component: KETPrintPDF,
        meta: {
          auth: true
        }
      },
      {
        path: '/checklist/:list',
        name: 'Checklist',
        component: KETShowChecklist,
        meta: {
          auth: true
        }
      },
      {
        path: '/kassenabschluss',
        name: 'Kassenabschluss',
        component: KETKassenabschluss,
        meta: {
          auth: true
        }
      },
      {
        path: '/kassenstart',
        name: 'Kassenstart',
        component: KETKassenstart,
        meta: {
          auth: true
        }
      },
      {
        path: '/rabattliste',
        name: 'Rabattliste',
        component: KETRabattliste,
        meta: {
          auth: true
        }
      },
      {
        path: '/specials',
        name: 'Basis/Premium Pakete',
        component: KETSpecials,
        meta: {
          auth: true
        }
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes
