<template>
<div>
  <div class="card">
    <div class="card-header">
      <h3 class="title mb-1"><b>{{ trainerName }}</b></h3>
    </div>
    <div class="card-body">
      <form>
          <h5>Kontaktdaten</h5>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Telefon"
                      :disabled="true"
                      v-model="trainerTelefon">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="email"
                      label="E-Mail"
                      :disabled="true"
                      v-model="trainerMail">
            </fg-input>
          </div>
        </div>
        <h5 class="pt-4">Notfallkontakt</h5>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Telefon Notfallkontakt"
                      :disabled="true"
                      v-model="trainerNotfallkontaktNummer">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
        <h5 class="pt-4">Arbeitsbereiche</h5>
        <div class="row">
          <div class="col-md-4">
            <p-switch v-model="trainerIsEinweiser" type="primary">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
            </p-switch> Einweiser
          </div>
          <div class="col-md-4">
              <p-switch v-model="trainerIsRetter" type="primary">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
            </p-switch> Retter
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p-switch v-model="trainerIsKasse" type="primary">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
            </p-switch> Kasse
          </div>
          <div class="col-md-4">
              <p-switch v-model="trainerIsHT" type="primary">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
            </p-switch> Haupttrainer
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p-switch v-model="trainerIsVA" type="primary">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
            </p-switch> Veranstaltungen
          </div>
          <div class="col-md-4">
          </div>
        </div>
          </div>
          <div class="col-md-6">
          </div>
        </div>


        <div class="text-right">
          <button type="reset" class="btn btn-danger btn-fill btn-wd" @click.prevent="onCancel">
            Abbrechen
          </button>
          <button type="submit" class="btn btn-success btn-fill btn-wd" @click.prevent="updateTrainer">
            Speichern
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>

  <div class="alert alert-info" role="alert">
      <h4 class="alert-heading mt-0">Papershift</h4>
  Name, Kontaktdaten und der Notfallkontakt werden aus Papershift synchronisiert und können nur dort geändert werden.
</div>

</div>
</template>
<script>
  import Vue from 'vue'
  import { db } from '../../../../firebaseDb'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import router from '../../../../main'

  export default {
    components: {
        PSwitch
    },

    data () {
      return {
          trainerDocID: 0,
          trainerName: null,
          trainerTelefon: null,
          trainerMail: null,
          trainerNotfallkontaktNummer: null,
          trainerIsRetter: false,
          trainerIsHT: false,
          trainerIsKasse: false,
          trainerIsEinweiser: false,
          trainerIsVA: false,
      }
    },
    created () {
        this.trainerDocID = this.$route.params.trainerId

        // get data of trainer
        const trainerRef = db.collection('trainer')
        trainerRef.doc(this.trainerDocID).get().then((doc) => {
            this.trainerName = doc.data().Name
            this.trainerTelefon = doc.data().Telefon
            this.trainerMail = doc.data().Mail
            this.trainerNotfallkontaktNummer = doc.data().NotfallkontaktNummer
            if(doc.data().isRetter) {
              this.trainerIsRetter = doc.data().isRetter
            }
            if(doc.data().isHT) {
              this.trainerIsHT = doc.data().isHT
            }
            if(doc.data().isEinweiser) {
              this.trainerIsEinweiser = doc.data().isEinweiser
            }
            if(doc.data().isKasse) {
              this.trainerIsKasse = doc.data().isKasse
            }
            if(doc.data().isVA) {
              this.trainerIsVA = doc.data().isVA
            }
        })
    },
    methods: {
        onCancel () {
            router.push({ name: 'Trainer'})
        },
        updateTrainer () {
            const trainerRef = db.collection('trainer')
            trainerRef.doc(this.trainerDocID).update({ isEinweiser: this.trainerIsEinweiser, isRetter: this.trainerIsRetter, isKasse: this.trainerIsKasse, isHT: this.trainerIsHT, isVA: this.trainerIsVA }).then(() => {
                router.push({ name: 'Trainer'})
            }).catch((error) => {
                console.log('Fehler beim Speichern des Trainers: ' + error)
            })
        }
    }
  }

</script>
<style>

</style>
