<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <h5>Kassenabschluss</h5>
        <wizard @complete="wizardComplete">
          <template slot="header">
            <h3 class="card-title"></h3>
            <h3 class="description"></h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step0')">
            <template slot="label">
              <i class="bi bi-pc-display-horizontal"></i>
              Kasse
            </template>
            <step0 ref="step0" @on-validated="onStepValidated"></step0>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="bi bi-calendar2-event"></i>
              Allgemeines
            </template>
            <step1 ref="step1" @on-validated="onStepValidated"></step1>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="bi bi-cash-coin"></i>
              Zählen
            </template>
            <step2 ref="step2" @on-validated="onStepValidated"></step2>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="bi bi-bank"></i>
              Bank
            </template>
            <step3 ref="step3" @on-validated="onStepValidated"></step3>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="bi bi-wallet2"></i>
              Abrechnung
            </template>
            <step4 ref="step4" @on-validated="onStepValidated"></step4>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step5')">
            <template slot="label">
              <i class="bi bi-currency-euro"></i>
              Umsatz
            </template>
            <step5 ref="step5" @on-validated="onStepValidated"></step5>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step6')">
            <template slot="label">
              <i class="bi bi-card-checklist"></i>
              Abschluss
            </template>
            <step6 ref="step6" @on-validated="onStepValidated"></step6>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Step0 from "./Kassenabschluss/Step0.vue";
import Step1 from "./Kassenabschluss/Step1.vue";
import Step2 from "./Kassenabschluss/Step2.vue";
import Step3 from "./Kassenabschluss/Step3.vue";
import Step4 from "./Kassenabschluss/Step4.vue";
import Step5 from "./Kassenabschluss/Step5.vue";
import Step6 from "./Kassenabschluss/Step6.vue";
import store from "../../../../store";

extend("required", required);

export default {
  components: {
    MessageBox,
    Wizard,
    WizardTab,
    Step0,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
  },

  data() {
    return {
      wizardModel: {},
      reportName: moment().format("YYYYMMDD") + '-' + store.getters.userlocation,
      saved: false
    };
  },
  created() {},
  beforeRouteLeave(to, from, next) {
    if(!this.saved) {
      MessageBox.confirm(
        "Der Kassenabschluss wurde nicht gespeichert. Alle Änderungen gehen verloren. Seite wirklich verlassen?",
        "Warnung",
        {
          confirmButtonText: "Verlassen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              next()
            } else {
              next(false)
            }
          },
        }
      );
    } else {
      next()
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      const loading = this.$loading({
          lock: true,
          text: 'Kassenabschluss wird geprüft...',
          spinner: 'el-icon-loading'
      });
      
      this.reportName = moment(this.$refs["step1"].date, "DD.MM.YYYY").format("YYYYMMDD") + '-' + store.getters.userlocation
      const listRef = db.collection(
        "tagesreports/" + this.reportName + "/Kassenabschluss"
      );
      var docNames = []

      listRef.get().then((querySnapshot) => {
        docNames = []
        querySnapshot.forEach((doc) => {
          docNames.push(doc.id)
        });
      }).then(() => {
        var abschlussNameFound = false
        var abschlussNamePostfix = 1
        var abschlussName = this.$refs["step1"].kasse
        while(abschlussNameFound == false) {
          if(docNames.includes(abschlussName)) {
            abschlussNamePostfix++
            abschlussName = this.$refs["step1"].kasse + "-" + abschlussNamePostfix
          } else {
            abschlussNameFound = true
            loading.close();
            this.saveList(abschlussName);
          }
        }
      });
    },
    saveList(name) {
      const loading = this.$loading({
          lock: true,
          text: 'Kassenabschluss wird gespeichert...',
          spinner: 'el-icon-loading'
      });

      var kasse = this.$refs["step1"].kasse;
      const kassenabschlussRef = db.collection(
        "tagesreports/" + this.reportName + "/Kassenabschluss"
      );
      const tagesreportRef = db.collection("tagesreports");
      const kassenBestandRef = db.collection("kassenbestand");
      const anmerkungenRef = db.collection("anmerkungen");
      const banktransferRef = db.collection("banktransfer");

      var batch = db.batch();

      const idBank = banktransferRef.doc().id;
      batch.set(banktransferRef.doc(idBank), {
        date: moment(this.$refs["step1"].date, "DD.MM.YYYY").toDate(),
        kasse: this.$refs["step0"].kasse,
        betrag: parseFloat(this.$refs["step3"].bankeinzahlung),
        trainer: this.$refs["step1"].bankeinzahlungtrainer,
        confirmed: false,
        locationID: store.getters.userlocation
      });

      batch.set(kassenabschlussRef.doc(name), {
        Date: moment(this.$refs["step1"].date, "DD.MM.YYYY").toDate(),
        Kasse: this.$refs["step0"].kasse,
        Wechselgeldstart: parseFloat(this.$refs["step1"].wechselgeldstart),
        Kassentrainer: this.$refs["step1"].kassentrainer,
        HT: this.$refs["step1"].HT,
        TrainerBankeinzahlung: this.$refs["step1"].bankeinzahlungtrainer,
        TrainerRecount: this.$refs["step2"].recountTrainer,
        Bankeinzahlung: parseFloat(this.$refs["step3"].bankeinzahlung),
        Kassenbestand: parseFloat(this.$refs["step2"].kassenbestand),
        WechselgeldEnde: parseFloat(store.getters.cash.data.SumAll - store.getters.banktransfer.data.SumBank),
        ECSchnitt: parseFloat(this.$refs["step5"].ecschnitt),
        SollBestand: parseFloat(this.$refs["step5"].berechnetzbon),
        Gutscheine: parseFloat(this.$refs["step5"].gutscheine),
        GutscheineAnzahl: parseFloat(this.$refs["step5"].gutscheineAnzahl),
        Stornos: parseFloat(this.$refs["step5"].stornos),
        StornosAnzahl: parseFloat(this.$refs["step5"].stornosAnzahl),
        Online: parseFloat(this.$refs["step5"].online),
        Ausgaben: parseFloat(this.$refs["step5"].ausgaben),
        Rechnungen: this.$refs["step5"].rechnungen.join(", "),
        SummeRechnungen: 0,
        Veranstaltungen: this.$refs["step5"].veranstaltungen.join(", "),
        ZFin: parseFloat(this.$refs["step6"].zfin),
        Kassendifferenz: parseFloat(this.$refs["step6"].kassendifferenz),
        KassenbestandDenomination: [
          { Cent1: this.$refs["step2"].Cent1 },
          { Cent2: this.$refs["step2"].Cent2 },
          { Cent5: this.$refs["step2"].Cent5 },
          { Cent10: this.$refs["step2"].Cent10 },
          { Cent20: this.$refs["step2"].Cent20 },
          { Cent50: this.$refs["step2"].Cent50 },
          { Euro1: this.$refs["step2"].Euro1 },
          { Euro2: this.$refs["step2"].Euro2 },
          { Euro5: this.$refs["step2"].Euro5 },
          { Euro10: this.$refs["step2"].Euro10 },
          { Euro20: this.$refs["step2"].Euro20 },
          { Euro50: this.$refs["step2"].Euro50 },
          { Euro100: this.$refs["step2"].Euro100 },
          { Euro200: this.$refs["step2"].Euro200 },
          { Euro500: this.$refs["step2"].Euro500 },
        ],
        WechselgeldEndeDenomination: [
          { Cent1: store.getters.cash.data.Cent1 },
          { Cent2: store.getters.cash.data.Cent2 },
          { Cent5: store.getters.cash.data.Cent5 },
          { Cent10: store.getters.cash.data.Cent10 },
          { Cent20: store.getters.cash.data.Cent20 },
          { Cent50: store.getters.cash.data.Cent50 },
          { Euro1: store.getters.cash.data.Euro1 },
          { Euro2: store.getters.cash.data.Euro2 },
          { Euro5: store.getters.cash.data.Euro5 },
          { Euro10: store.getters.cash.data.Euro10 },
          { Euro20: store.getters.cash.data.Euro20 },
          { Euro50: store.getters.cash.data.Euro50 },
          { Euro100: store.getters.cash.data.Euro100 },
          { Euro200: store.getters.cash.data.Euro200 },
          { Euro500: store.getters.cash.data.Euro500 },
        ],
        Timestamp: new Date(),
        isValid: true,
        banktransferDoc: idBank
      });

      var onlineCovered = false;
      if (parseFloat(this.$refs["step5"].online) > 0) {
        onlineCovered = true
      }
      // check if tagesprotokoll already exists
      tagesreportRef.doc(this.reportName).get().then((doc) => {
        if(doc.exists) {
          console.log("xx Tagesreport found, update. Online already covered: " + onlineCovered)
          tagesreportRef.doc(this.reportName).update({
            Date: moment(this.$refs["step1"].date, "DD.MM.YYYY").toDate(),
            locationID: store.getters.userlocation,
            onlineBookingsCovered: onlineCovered
          });
        } else {
          console.log("xx No Tagesreport found, create. Online already covered: " + onlineCovered)
          tagesreportRef.doc(this.reportName).set({
            Date: moment(this.$refs["step1"].date, "DD.MM.YYYY").toDate(),
            locationID: store.getters.userlocation,
            onlineBookingsCovered: onlineCovered
          });
        }
      })
      
      var docName = kasse + '-' + store.getters.userlocation
      batch.update(kassenBestandRef.doc(docName), {
        Date: moment(this.$refs["step1"].date, "DD.MM.YYYY").toDate(),
        Bestand: parseFloat(store.getters.cash.data.SumAll - store.getters.banktransfer.data.SumBank),
        Cent1: parseInt(store.getters.cash.data.Cent1),
        Cent2: parseInt(store.getters.cash.data.Cent2),
        Cent5: parseInt(store.getters.cash.data.Cent5),
        Cent10: parseInt(store.getters.cash.data.Cent10),
        Cent20: parseInt(store.getters.cash.data.Cent20),
        Cent50: parseInt(store.getters.cash.data.Cent50),
        Euro1: parseInt(store.getters.cash.data.Euro1),
        Euro2: parseInt(store.getters.cash.data.Euro2),
        Euro5: parseInt(store.getters.cash.data.Euro5) - parseInt(store.getters.banktransfer.data.Euro5Bank),
        Euro10: parseInt(store.getters.cash.data.Euro10) - parseInt(store.getters.banktransfer.data.Euro10Bank),
        Euro20: parseInt(store.getters.cash.data.Euro20) - parseInt(store.getters.banktransfer.data.Euro20Bank),
        Euro50: parseInt(store.getters.cash.data.Euro50) - parseInt(store.getters.banktransfer.data.Euro50Bank),
        Euro100: parseInt(store.getters.cash.data.Euro100) - parseInt(store.getters.banktransfer.data.Euro100Bank),
        Euro200: parseInt(store.getters.cash.data.Euro200) - parseInt(store.getters.banktransfer.data.Euro200Bank),
        Euro500: parseInt(store.getters.cash.data.Euro500) - parseInt(store.getters.banktransfer.data.Euro500Bank),
        WechselgeldSum: parseFloat(store.getters.cash.data.SumAll - store.getters.banktransfer.data.SumBank),
      });
      if (this.$refs["step6"].newAnmerkung) {
        var dateAnmerkung = ""
        if (moment(this.$refs["step1"].date, "DD.MM.YYYY").isSame(moment(), 'day') ) {
          dateAnmerkung = new Date()
        } else {
          dateAnmerkung = moment(this.$refs["step1"].date, "DD.MM.YYYY").toDate()
        }
        const idAnmerkung = anmerkungenRef.doc().id;
        batch.set(anmerkungenRef.doc(idAnmerkung), {
          text:
            "Anmerkung zum Kassenabschluss: " +
            this.$refs["step6"].newAnmerkung,
          important: false,
          timestamp: dateAnmerkung,
          locationID: store.getters.userlocation,
          kasse: true
        });
      }
      var onlineStatus = navigator.onLine
      if (onlineStatus == false) {
        this.saved = false
        loading.close();
        this.$notify({
          message: "Keine Internetverbindung! Bitte Internetverbindung prüfen und erneut versuchen.",
          icon: "fa fa-exclamation-circle",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "danger",
          showClose: true,
          timeout: 20000,
        });
      } else {
        batch.commit().then(() => {
          this.saved = true
          loading.close();
          this.$notify({
            message: "Der Kassenabschluss wurde erfolgreich gespeichert!",
            icon: "fa fa-check-circle",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "success",
            showClose: true,
            timeout: 5000,
          });
          router.push({ name: "Overview" });
        }).catch((error) => {
          console.error("Error writing document Kassenabschlus: ", error);
          loading.close();
          this.$notify({
            message: "Fehler beim Speichern des Kassenabschlusses! Bitte Internetverbindung prüfen.",
            icon: "fa fa-exclamation-circle",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger",
            showClose: true,
            timeout: 10000,
          });
        });
      }
    },
    preventNav(event) {
      if (this.saved) return
      event.preventDefault()
      event.returnValue = ""
    }
  },
};
</script>
<style>
</style>
