<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Trainer</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6" v-if="role == 3">
            <div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadData"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>
            </div>
              <div class="col">
            <div>
              <p-button class="mb-0 mt-0" type="default" @click="startPsSync">Papershift Sync</p-button>
            </div>
            <div class="pt-0">
              <small class="text-muted"
                >Letzte Aktualisierung: {{ lastSync }}</small
              >
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="
              trainer.filter(
                (data) =>
                  !search ||
                  data.trainerName.toLowerCase().includes(search.toLowerCase())
              )
            "
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Trainer gefunden werden."
            v-loading="loading"
          >
            <el-table-column prop="trainerName" label="Name"> </el-table-column>
            <el-table-column prop="trainerTelefon" label="Telefon">
            </el-table-column>
            <el-table-column prop="trainerMail" label="E-Mail">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label=""
            >
              <template slot="header" slot-scope="scope"> <!-- eslint-disable-line vue/no-unused-vars -->
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="Trainer suchen..."
                ><i slot="prefix" class="bi bi-search"></i></el-input>
              </template>
              <template slot-scope="props">
                <el-tooltip content="Trainer bearbeiten" placement="top">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="editTrainer(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Trainer löschen" placement="top">
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="removeTrainer(props.$index, props.row)"
                  >
                    <i class="fa fa-times"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../firebaseDb";
import { Table, TableColumn, Tooltip, Input, Loading, Select, Option, MessageBox } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import { httpsCallable } from "firebase/functions";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
//Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    MessageBox
  },

  data() {
    return {
      trainer: [],
      lastSync: null,
      lastSyncTimestamp: null,
      search: "",
      loading: true,
      location: store.getters.userlocation,
      role: store.getters.userrole
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      // get last Papershift sync time
      const lastSyncRef = db.collection("papershiftSyncStatus");
      var docname = "trainer"
      lastSyncRef
        .doc(docname)
        .get()
        .then((doc) => {
          this.lastSync = moment(new Date(doc.data().lastSync.seconds * 1000))
            .format("DD.MM.YY HH:mm")
            .toString();
          this.lastSyncTimestamp = doc.data().lastSync.seconds * 1000
        });

      // get list of trainers
      const trainerRef = db.collection("trainer");
      trainerRef.where('locationID', 'array-contains', this.location).orderBy("Name").onSnapshot((snapshotChange) => {
        this.trainer = [];
        snapshotChange.forEach((doc) => {
          this.trainer.push({
            key: doc.id,
            trainerName: doc.data().Name,
            trainerID: doc.data().ID,
            trainerMail: doc.data().Mail,
            trainerTelefon: doc.data().Telefon,
          });
        });
        this.loading = false
      });
    },
    editTrainer(index, row) {
      router.push({ name: "editTrainer", params: { trainerId: row.key } });
    },
    removeTrainer(index, row) {
      MessageBox.confirm(
        "Soll der Trainer " + row.trainerName + " wirklich aus KET gelöscht werden? Der Trainer wird für alle zugeordneten Standorte entfernt.",
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              // remove trainer
              db.collection("trainer")
                .doc(row.key)
                .delete();
            }
          },
        }
      );
    },
    startPsSync() {
      if(new Date().getTime() - this.lastSyncTimestamp > 600000) { // greater 10mins
        const loading = this.$loading({
          lock: true,
          text: 'Papershift Sync läuft...',
          spinner: 'el-icon-loading'
        });
        console.log("Start PS trainer sync")
        var getTrainer = httpsCallable(fc, "papershift-getPSTrainer");
        getTrainer([]).then((result) => {
          console.log('PS Trainer Sync done')
          this.loadData()
          loading.close()
        });
      } else {
        MessageBox.alert(
            "Die Trainer können erst 10 Minuten nach der letzten Synchronisation erneut aus Papershift abgerufen werden. Die letzte Synchronisation war am " + this.lastSync + " Uhr.",
            {
              confirmButtonText: "OK",
              type: "warning",
              callback: (action) => {},
            }
          );
      }
    },
  },
};
</script>
<style>
</style>
