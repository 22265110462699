<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Bankeinzahlung</h5>
        </div>

        <div class="row">
          <div class="col-sm-5 offset-1 mr-5">
            <h6>Vorgeschlagene Bankeinzahlung</h6>
            <table class="table table-borderless table-sm mt-4 table-hover">
              <thead>
                <th scope="col" class="w-25"></th>
                <th scope="col" class="w-25">Anzahl</th>
                <th scope="col" class="w-25"></th>
                <th scope="col" class="text-right w-25">Wert</th>
              </thead>
              <tbody>
                <tr>
                  <td>5 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro5"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro5"
                        :min="0"
                        :max="parseInt(Euro5actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro5actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro5 * 5)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>10 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro10"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro10"
                        :min="0"
                        :max="parseInt(Euro10actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro10actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro10 * 10)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>20 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro20"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro20"
                        :min="0"
                        :max="parseInt(Euro20actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro20actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro20 * 20)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>50 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro50"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro50"
                        :min="0"
                        :max="parseInt(Euro50actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro50actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro50 * 50)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>100 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro100"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro100"
                        :min="0"
                        :max="parseInt(Euro100actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro100actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro100 * 100)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>200 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro200"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro200"
                        :min="0"
                        :max="parseInt(Euro200actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro200actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro200 * 200)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>500 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro500"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <el-input-number
                        v-model="Euro500"
                        :min="0"
                        :max="parseInt(Euro500actual)"
                      ></el-input-number>
                    </ValidationProvider>
                  </td>
                  <td>von {{ Euro500actual }}</td>
                  <td class="text-right">
                    {{
                      (Euro500 * 500)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="text-right">
              Summe:
              {{
                (
                  Euro5 * 5 +
                  Euro10 * 10 +
                  Euro20 * 20 +
                  Euro50 * 50 +
                  Euro100 * 100 +
                  Euro200 * 200 +
                  Euro500 * 500
                )
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
              }}
              €
            </h4>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  InputNumber,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store";

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);

export default {
  components: {
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      Euro5: 0,
      Euro10: 0,
      Euro20: 0,
      Euro50: 0,
      Euro100: 0,
      Euro200: 0,
      Euro500: 0,
      Euro5actual: 0,
      Euro10actual: 0,
      Euro20actual: 0,
      Euro50actual: 0,
      Euro100actual: 0,
      Euro200actual: 0,
      Euro500actual: 0,
      cashSumAll: 0,
      cashfloat: 0,
      bankeinzahlung: 0,
    };
  },
  created() {},
  mounted() {
    this.$watch("$parent.active", (newVal, oldVal) => {
      if (newVal === true) {
        this.updateCash();
        if (this.bankeinzahlung == 0) {
          this.getProposedBankTransfer();
        }
      }
    });
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.bankeinzahlung =
          this.Euro5 * 5 +
          this.Euro10 * 10 +
          this.Euro20 * 20 +
          this.Euro50 * 50 +
          this.Euro100 * 100 +
          this.Euro200 * 200 +
          this.Euro500 * 500;
        store.commit("SET_BANKTRANSFER", {
          Euro5Bank: this.Euro5,
          Euro10Bank: this.Euro10,
          Euro20Bank: this.Euro20,
          Euro50Bank: this.Euro50,
          Euro100Bank: this.Euro100,
          Euro200Bank: this.Euro200,
          Euro500Bank: this.Euro500,
          SumBank: this.Euro5 * 5 + this.Euro10 * 10 + this.Euro20 * 20 + this.Euro50 * 50 + this.Euro100 * 100 + this.Euro200 * 200 + this.Euro500 * 500
        });
        this.$emit("on-validated", res);
        return res;
      });
    },
    updateCash() {
      (this.Euro5actual = store.getters.cash.data.Euro5),
        (this.Euro10actual = store.getters.cash.data.Euro10),
        (this.Euro20actual = store.getters.cash.data.Euro20),
        (this.Euro50actual = store.getters.cash.data.Euro50),
        (this.Euro100actual = store.getters.cash.data.Euro100),
        (this.Euro200actual = store.getters.cash.data.Euro200),
        (this.Euro500actual = store.getters.cash.data.Euro500),
        (this.cashSumAll = store.getters.cash.data.SumAll),
        (this.cashfloat = store.getters.cashfloat);

        if(this.bankeinzahlung != 0) {
          if (this.Euro5 > store.getters.cash.data.Euro5 ||
              this.Euro10 > store.getters.cash.data.Euro10 ||
              this.Euro20 > store.getters.cash.data.Euro20 ||
              this.Euro50 > store.getters.cash.data.Euro50 ||
              this.Euro100 > store.getters.cash.data.Euro100 ||
              this.Euro200 > store.getters.cash.data.Euro200 ||
              this.Euro500 > store.getters.cash.data.Euro500
          ) {
            this.getProposedBankTransfer();
            MessageBox.alert('Die Bankeinzahlung wurde auf den vorgeschlagenen Wert zurückgesetzt, da die bisherige Einzahlung nach der Änderung des Kassenbestands ungültig war.', 'Bankeinzahlung', {
              confirmButtonText: 'OK',
              callback: action => {
              }
            });
          }
        }
    },
    getProposedBankTransfer() {
      var cash = [
        { amount: store.getters.cash.data.Euro500, value: 500, transfer: 0 },
        { amount: store.getters.cash.data.Euro200, value: 200, transfer: 0 },
        { amount: store.getters.cash.data.Euro100, value: 100, transfer: 0 },
        { amount: store.getters.cash.data.Euro50, value: 50, transfer: 0 },
        { amount: store.getters.cash.data.Euro20, value: 20, transfer: 0 },
        { amount: store.getters.cash.data.Euro10, value: 10, transfer: 0 },
        { amount: store.getters.cash.data.Euro5, value: 5, transfer: 0 },
      ];
      var target = Math.round((this.cashSumAll - this.cashfloat) / 100) * 100;

      if (target > 0) {
        cash.forEach(function (item) {
          for (var i = 1; i <= item.amount; i++) {
            if (target - item.value >= 0) {
              target = target - item.value;
              item.transfer++;
            }
          }
        });
        this.Euro500 = cash[0].transfer;
        this.Euro200 = cash[1].transfer;
        this.Euro100 = cash[2].transfer;
        this.Euro50 = cash[3].transfer;
        this.Euro20 = cash[4].transfer;
        this.Euro10 = cash[5].transfer;
        this.Euro5 = cash[6].transfer;
      } else {
        this.Euro500 = 0;
        this.Euro200 = 0;
        this.Euro100 = 0;
        this.Euro50 = 0;
        this.Euro20 = 0;
        this.Euro10 = 0;
        this.Euro5 = 0;
      }
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
}
.table-sm td,
.table-sm th {
  padding: 0.2rem !important;
}
input[type="number"] {
  text-align: right;
}
</style>
