<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-6 ml-auto mr-auto">
              <div class="card text-center pagenotfound">
                <div class="card-header">
                <h2 class="">Hoppla!</h2>
                </div>
                <div class="card-body">
                  <p>Da ist wohl etwas schief gelaufen.</p>
                  <p>Gehe am besten zurück zur Übersicht und probiere es erneut.</p>
                </div>
                <div class="card-footer">
                  <p-button type="primary" @click="toOverview">Zurück zur Übersicht</p-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(../static/img/background/background-login.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Card, Checkbox, Button, InfoSection} from 'src/components/UIComponents';
  import AppFooter from './../KET/Views/Pages/Layout/AppFooter'
  import router from "../../main";
  export default {
    components: {
      Card,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      toOverview() {
        router.push({ name: "Overview" });
      },
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
.pagenotfound h2 {
  margin-bottom: 15px !important;
}
.pagenotfound {
  opacity: 0.9;
}
.pagenotfound p {
  font-size: 1.0rem;
}
</style>
