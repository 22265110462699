<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h5 class="title">Abseilgeräte</h5>
      </div>
      <SelectLocation v-if="role > 2" @locationChanged="updateLocation"></SelectLocation>
      <div class="col-md-auto">
        <p-button type="default" @click="showRescues = true" class="mt-0"><i class="bi bi-tree"></i> Alle Rettungen</p-button>
      </div>
    </div>
    <div class="card-body row">
      <div class="col">
            <div class="row mt-0">
              <div
                class="col-md-4"
                v-for="hub in hubs"
                :key="hub.name"
              >
                <div class="card mt-3" style="width: 20rem">
                  <div class="card-body">
                    <h5 class="card-title">{{ hub.name }} <i class="bi bi-exclamation-triangle-fill" style="color: orange;" title="Wartung fällig" v-if="(hub.deviceType == 'hub' && (hub.metersDown >= 500 || hub.metersUp >= 4)) || (hub.deviceType == 'safedescent' && hub.metersDown >= 7600 && hub.rescueAktivCount == 0) || (hub.deviceType == 'safedescent' && hub.metersDown >= 750 && hub.rescueAktivCount > 0)"></i><i class="bi bi-exclamation-octagon-fill" style="color: red;" title="Außer Betrieb" v-if="hub.outOfService == true"></i></h5>
                    <h6 class="card-subtitle mb-2 text-muted" style="color: red!important; font-weight: bolder!important;" v-if="hub.outOfService == true">Außer Betrieb</h6>
                    <i>
                      <b>Passiv-Rettungen:</b> {{ hub.rescueCount }}<br>
                      <b>Aktiv-Rettungen:</b> {{ hub.rescueAktivCount }}<br>
                      <b>Abseilmeter:</b> {{ hub.metersDown }} m <br>
                      <b>Hubmeter:</b> {{ hub.metersUp.toFixed(2).replace(".", ",") }} m
                    </i>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-md-auto">
                        <p-button type="success" @click="newRescue(hub.key, hub.name)" :disabled="hub.outOfService == true" size="default" title="Rettung eintragen"
                      ><i class="bi bi-file-plus"></i> Rettung eintragen</p-button
                    >
                      </div>
                      <div class="col-md-auto">
                      <p-button type="warning" @click="showMaintenance(hub.key, hub.name, hub.metersUp, hub.metersDown, hub.rescueCount, hub.rescueAktivCount)" size="default" title="Wartungen"
                          ><i class="bi bi-gear"></i>
                          </p-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

    <modal
      :show.sync="showNewForm"
      headerClasses="justify-content-center"
    >
      <h5 slot="header" class="title mb-0">Rettung eintragen ({{ newRescueHubDeviceName }})</h5>
      <label class="pt-1">Parcours</label>
      <el-select
        placeholder="Parcours"
        v-model="newRescueParcours"
        filterable
        @change="updateHeight($event)">
        <el-option v-for="parc in parcours"
          :value=parc.parcoursName
          :label=parc.parcoursName
          :key=parc.parcoursName>
        </el-option>
      </el-select>
      <label class="pt-4 mb-0">Abseilmeter (m)</label>
      <el-slider v-model="newRescueHeightDown" show-input :min="1" :max="50" class="ml-2"></el-slider>
      <label class="pt-4 mb-0">Hubmeter (m)</label>
      <el-slider v-model="newRescueHeightUp" show-input :min="0" :max="1" :step="0.1" class="ml-2"></el-slider>
      <label class="pt-2">Trainer</label>
      <el-select
        placeholder="Trainer"
        v-model="newRescueTrainer">
        <el-option v-for="trainer in trainers"
          :value=trainer.trainerName
          :label=trainer.trainerName
          :key=trainer.trainerName>
        </el-option>
      </el-select>
      <fieldset>
        <div class="form-group">
          <label class="pt-4 control-label">Anmerkungen zur Rettung <small class="text-muted">(optional)</small></label>
          <div class="">
            <textarea
              class="form-control"
              placeholder="Anmerkungen zur Rettung (Element, Besonderheiten)"
              rows="2"
              v-model="newRescueAnmerkung"
            ></textarea>
          </div>
        </div>
        <el-checkbox v-model="newRescueScrew" label="Rolle wurde wieder mit 17 Nm verschlossen" class="pt-2" v-if="location == 'NB'" required size="merdium"><span style="color: red"><b>Rolle wurde wieder mit 17 Nm verschlossen</b></span></el-checkbox>
        <el-checkbox v-model="newRescueTraining" label="Übung" class="pt-2">Übung</el-checkbox>
        <el-checkbox v-model="newRescueAktivrettung" label="Aktivrettung (doppelte Personenlast)" class="pt-2">Aktivrettung <small class="text-muted">(doppelte Personenlast)</small></el-checkbox>
      </fieldset>
      
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showNewForm = false;"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="addRescue" :disabled="(location == 'NB' && newRescueScrew == false) || saveInProgress == true"
            >Speichern</p-button
          >
        </div>
      </template>
    </modal>

    <modal
      :show.sync="showRescues"
      headerClasses="justify-content-center"
      class="rescueModal"
      modalMaxWidth="90%"
    >
      <h5 slot="header" class="title mb-0">Alle Rettungen</h5>
      <el-table
            :data="rescues"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Rettungen gefunden werden."
          >
          <el-table-column prop="rescueDateTime" label="Datum"> </el-table-column>
          <el-table-column prop="rescueParcours" label="Parcours" sortable> </el-table-column>
          <el-table-column prop="rescueDown" label="Abseilmeter" sortable> </el-table-column>
          <el-table-column prop="rescueUp" label="Hubmeter" sortable> </el-table-column>
          <el-table-column prop="rescueHub" label="Abseilgerät" sortable> </el-table-column>
          <el-table-column prop="rescueTrainer" label="Trainer" sortable> </el-table-column>
          <el-table-column prop="rescueMode" label="Art" sortable> </el-table-column>
          <el-table-column prop="rescueNote" label="">
            <template slot-scope="scope">
                <div v-if="scope.row.rescueNote">
                  <el-tooltip
                    :content="
                      scope.row.rescueNote
                    "
                    placement="top"
                  >
                    <i
                      class="bi bi-info-circle"
                      style="font-size: 1.2rem; color: green"
                    ></i>
                  </el-tooltip>
                </div>
            </template>
          </el-table-column>

      </el-table>
    </modal>

    <modal
      :show.sync="showMaintenanceForm"
      headerClasses="justify-content-center"
      class="rescueModal"
      modalMaxWidth="95%"
    >
      <h5 slot="header" class="title mb-0">Wartungen {{ newMaintenanceHubName }}</h5>
        <h5>Neue Wartung erfassen</h5>
        <label class="pt-2">Wartung durchgeführt von</label>
        <el-select
          placeholder="Trainer"
          v-model="newMaintenanceTrainer"
          filterable>
          <el-option v-for="trainer in allTrainers"
            :value=trainer.trainerName
            :label=trainer.trainerName
            :key=trainer.trainerName>
          </el-option>
        </el-select>
        <fieldset>
        <div class="form-group">
          <label class="pt-4 control-label">Anmerkungen zur Wartung</label>
          <div class="">
            <textarea
              class="form-control"
              placeholder="Durchgeführte Arbeiten, getauschte Komponenten, Mängel..."
              rows="2"
              v-model="newMaintenanceAnmerkung"
            ></textarea>
          </div>
        </div>       
      </fieldset>
      <el-checkbox v-model="newMaintenanceOutOfService" label="Abseilgerät ist NICHT betriebsbereit" class="pt-2"><b>Abseilgerät ist NICHT betriebsbereit</b></el-checkbox>
      <div class="text-right pb-4" >
        <p-button type="success" @click="addMaintenance()" :disabled="saveInProgress == true"><i class="bi bi-file-plus"></i> Wartung speichern</p-button>
      </div>
        
        <h5>Bisherige Wartungen</h5>
        <el-table
            :data="maintenance"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Wartungen gefunden werden."
            show-summary
            sum-text="Gesamt"
            :summary-method="getSum"
            table-layout="auto"
          >
          <el-table-column prop="date" label="Datum" min-width="35"> </el-table-column>
          <el-table-column prop="metersDown" label="Abseilmeter" min-width="40">
            <template slot-scope="scope">
              {{ scope.row.metersDown.toFixed(0).replace(".", ",") + ' m' }}
            </template>
          </el-table-column>
          <el-table-column prop="metersUp" label="Hubmeter" min-width="40">
            <template slot-scope="scope">
              {{ scope.row.metersUp.toFixed(2).replace(".", ",") + ' m' }}
            </template>
          </el-table-column>
          <el-table-column prop="rescueCount" label="Passiv-Rettungen" min-width="40"> </el-table-column>
          <el-table-column prop="rescueAktivCount" label="Aktiv-Rettungen" min-width="40"> </el-table-column>
          <el-table-column prop="trainer" label="Trainer" min-width="40"> </el-table-column>
          <el-table-column prop="description" label="Anmerkungen" min-width="100"> </el-table-column>
      </el-table>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { Tabs, TabPane, Modal } from "src/components/UIComponents";
import { Loading, MessageBox, Select, Option, Slider, Checkbox } from "element-ui";
import router from "../../../../main";
import store from "../../../../store";
import { db, fc, FirebaseStorage } from "../../../../firebaseDb";
import { FieldValue } from 'firebase/firestore';
import firebase from 'firebase/compat/app'
import moment from "moment-timezone";
import SelectLocation from '/src/components/KET/Views/Components/KETSelectLocation.vue'
Vue.use(Loading);
Vue.use(Checkbox);

export default {
  components: {
    MessageBox,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Slider.name]: Slider,
    SelectLocation,
  },

  data() {
    return {
      hubs: [],
      trainers: [],
      allTrainers: [],
      parcours: [],
      rescues: [],
      maintenance: [],
      loading: true,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      showNewForm: false,
      newRescueDateTime: null,
      newRescueParcours: null,
      newRescueHubDeviceName: null,
      newRescueHubDeviceKey: null,
      newRescueHeightDown: 0,
      newRescueHeightUp: 0,
      newRescueTrainer: null,
      newRescueAnmerkung: "",
      newRescueTraining: false,
      newRescueScrew: false,
      newRescueAktivrettung: false,
      newMaintenanceAnmerkung: "",
      newMaintenanceTrainer: "",
      newMaintenanceHub: "",
      newMaintenanceHubName: "",
      newMaintenanceMetersUp: 0,
      newMaintenanceMetersDown: 0,
      newMaintenanceRescueCount: 0,
      newMaintenanceOutOfService: false,
      showRescues: false,
      showMaintenanceForm: false,
      saveInProgress: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const hubsRef = db.collection("hubs");
      hubsRef.where('locationID', '==', this.location).orderBy("name").onSnapshot((snapshotChange) => {
        this.hubs = [];
        snapshotChange.forEach((doc) => {
            this.hubs.push({
              key: doc.id,
              name: doc.data().name,
              metersDown: doc.data().metersDown,
              metersUp: doc.data().metersUp,
              rescueCount: doc.data().rescueCount,
              rescueAktivCount: doc.data().rescueAktivCount || 0,
              deviceType: doc.data().deviceType,
              outOfService: doc.data().outOfService || false
            });
        });
        this.loading = false
      });

      const parcoursRef = db.collection("parcours");
      parcoursRef.where('locationID', '==', this.location).orderBy("position").onSnapshot((snapshotChange) => {
        this.parcours = [];
        snapshotChange.forEach((doc) => {
          this.parcours.push({
            key: doc.id,
            parcoursName: doc.data().Name,
            parcoursPosition: doc.data().position,
            parcoursHeight: doc.data().height || 0,
          });
        });
      });

      const rescuesRef = db.collection("rescues");
      rescuesRef.where('locationID', '==', this.location).orderBy("DateTime", "desc").limit(100).onSnapshot((snapshotChange) => {
        this.rescues = [];
        snapshotChange.forEach((doc) => {
          var note = ""
          if(doc.data().Training) {
            note = 'Übung ' + doc.data().Note
          } else {
            note = doc.data().Note
          }

          var mode = ""
          if (doc.data().Aktivrettung == true) {
            mode = "Aktiv"
          } else {
            mode = "Passiv"
          }

          this.rescues.push({
            key: doc.id,
            rescueParcours: doc.data().Parcours,
            rescueDateTime: moment(new Date(doc.data().DateTime.seconds * 1000))
                .format("DD.MM.yyyy")
                .toString(),
            rescueDown: doc.data().Down + ' m',
            rescueUp: doc.data().Up.toFixed(2).replace(".", ",") + ' m',
            rescueNote: note,
            rescueTrainer: doc.data().Trainer,
            rescueHub: doc.data().Hub,
            rescueMode: mode
          });
        });
      });

      const trainerRef = db.collection('trainer')
      trainerRef.where('locationID', 'array-contains', this.location).where('isToday', '==', true).where('isTodayLocation', '==', this.location).orderBy('Name').onSnapshot((snapshotChange) => {
      this.trainers = []
      snapshotChange.forEach((doc) => {
        this.trainers.push({
          trainerName: doc.data().Name
        })
      })
    })
    trainerRef.where('locationID', 'array-contains', this.location).orderBy('Name').onSnapshot((snapshotChange) => {
      this.allTrainers = []
      snapshotChange.forEach((doc) => {
        this.allTrainers.push({
          trainerName: doc.data().Name
        })
      })
    })
    this.loading = false
    },
    updateHeight(parcours) {
      const height = this.parcours.find(p => p.parcoursName === parcours).parcoursHeight
      if(height) {
        this.newRescueHeightDown = height
      }
    },
    newRescue(key, name) {
      this.newRescueDateTime = new Date(),
      this.newRescueParcours = null,
      this.newRescueHubDeviceName = name,
      this.newRescueHubDeviceKey = key,
      this.newRescueHeightDown = 0,
      this.newRescueHeightUp = 0,
      this.newRescueTrainer = null,
      this.newRescueTraining = false,
      this.newRescueAnmerkung = "",
      this.newRescueScrew = false,
      this.newRescueAktivrettung = false,
      this.showNewForm = true;
    },
    addRescue() {
      if(this.newRescueParcours && this.newRescueHeightDown && this.newRescueTrainer && this.newRescueHubDeviceName && (this.location == 'DA' || this.newRescueScrew == true)) {
        this.saveInProgress = true
        var batch = db.batch()
        const hubsRef = db.collection('hubs')
        const rescuesRef = db.collection('rescues')

        const rescueId = rescuesRef.doc().id;
        batch.set(rescuesRef.doc(rescueId), {
          DateTime: this.newRescueDateTime,
          Parcours: this.newRescueParcours,
          Hub: this.newRescueHubDeviceName,
          HubId: this.newRescueHubDeviceKey,
          Down: this.newRescueHeightDown,
          Up: this.newRescueHeightUp,
          Trainer: this.newRescueTrainer,
          Training: this.newRescueTraining,
          Note: this.newRescueAnmerkung,
          Screw: this.newRescueScrew,
          Aktivrettung: this.newRescueAktivrettung,
          locationID: this.location,
        })

        if (this.newRescueAktivrettung == true) {
          batch.update(hubsRef.doc(this.newRescueHubDeviceKey), {
            rescueAktivCount: firebase.firestore.FieldValue.increment(1),
            metersDown: firebase.firestore.FieldValue.increment(this.newRescueHeightDown),
            metersUp: firebase.firestore.FieldValue.increment(this.newRescueHeightUp)
          })
        } else {
          batch.update(hubsRef.doc(this.newRescueHubDeviceKey), {
            rescueCount: firebase.firestore.FieldValue.increment(1),
            metersDown: firebase.firestore.FieldValue.increment(this.newRescueHeightDown),
            metersUp: firebase.firestore.FieldValue.increment(this.newRescueHeightUp)
          })
        }

        batch.commit().then(() => {
          this.newRescueDateTime = null,
          this.newRescueParcours = null,
          this.newRescueHubDeviceName = null,
          this.newRescueHubDeviceKey = null,
          this.newRescueHeightDown = 0,
          this.newRescueHeightUp = 0,
          this.newRescueTrainer = null,
          this.newRescueAnmerkung = ""
          this.newRescueTraining = false,
          this.newRescueScrew = false,
          this.newRescueAktivrettung = false,
          this.showNewForm = false;
          this.saveInProgress = false;
          this.$notify({
            message: 'Rettung wurde erfolgreich gespeichert!',
            icon: 'fa fa-check-circle',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'success',
            showClose: true,
            timeout: 5000
          })
        })
      }
    },

    showMaintenance(key, name, up, down, count, aktivcount) {
      this.newMaintenanceHub = key
      this.newMaintenanceHubName = name
      this.newMaintenanceMetersUp = up
      this.newMaintenanceMetersDown = down
      this.newMaintenanceRescueCount = count
      this.newMaintenanceRescueAktivCount = aktivcount
      this.newMaintenanceTrainer = ""
      this.newMaintenanceAnmerkung = ""
      const hubsRef = db.collection("hubs");
      hubsRef.doc(key).collection("maintenance").orderBy("date", "desc").onSnapshot((snapshotChange) => {
        this.maintenance = [];
        snapshotChange.forEach((doc) => {
            this.maintenance.push({
              key: doc.id,
              trainer: doc.data().trainer,
              metersDown: doc.data().metersDown,
              metersUp: doc.data().metersUp,
              rescueCount: doc.data().rescueCount,
              rescueAktivCount: doc.data().rescueAktivCount,
              deviceType: doc.data().deviceType,
              date: moment(new Date(doc.data().date.seconds * 1000))
                .format("DD.MM.yyyy")
                .toString(),
              description: doc.data().description,
            });
        });
        this.showMaintenanceForm = true
        this.saveInProgress = false
      });
    },
    addMaintenance() {
      if(this.newMaintenanceTrainer != "" && this.newMaintenanceAnmerkung != "") {
      this.saveInProgress = true
      db.collection("hubs").doc(this.newMaintenanceHub).collection("maintenance").add({
        date: new Date(),
        trainer: this.newMaintenanceTrainer,
        description: this.newMaintenanceAnmerkung,
        metersDown: this.newMaintenanceMetersDown,
        metersUp: this.newMaintenanceMetersUp,
        rescueCount: this.newMaintenanceRescueCount,
        rescueAktivCount: this.newMaintenanceRescueAktivCount
      }).then(() => {
        db.collection("hubs")
                .doc(this.newMaintenanceHub)
                .update({
                  metersDown: 0,
                  metersUp: 0,
                  rescueCount: 0,
                  rescueAktivCount: 0,
                  outOfService: this.newMaintenanceOutOfService,
                  lastReset: new Date()
                }).then(() => {
                  this.newMaintenanceHub = ""
                  this.newMaintenanceHubName = ""
                  this.newMaintenanceMetersUp = 0
                  this.newMaintenanceMetersDown = 0
                  this.newMaintenanceRescueCount = 0
                  this.newMaintenanceRescueAktivCount = 0
                  this.newMaintenanceAnmerkung = "",
                  this.newMaintenanceTrainer = ""
                  this.newMaintenanceOutOfService = false
                  this.saveInProgress = false
                  this.showMaintenanceForm = false
                }).catch((error) => {
                  console.error("Error updating hub: ", error);
                  this.saveInProgress = false
                });
      }).catch((error) => {
        console.error("Error adding maintenance: ", error);
        this.saveInProgress = false
      });
    }
    },
    getSum(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Gesamt";
          return;
        }

        const values = data.map((item) => Number(item[column.property]));
        if (index === 1) {
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values
              .reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0)
              .toFixed(0)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + ' m';
          } else {
            sums[index] = "N/A";
          }
        } else if (index === 2) {
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values
              .reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0)
              .toFixed(2)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + ' m';
          } else {
            sums[index] = "N/A";
          }
        } else if (index === 3 || index === 4) {
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values
              .reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0)
              .toFixed(0)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
          } else {
            sums[index] = "N/A";
          }
        }
      });

      return sums;
    },

    updateLocation(loc) {
      this.location = loc
      this.loadData()
    }
  },
};
</script>
<style>
</style>
