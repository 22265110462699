import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/de'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import { firebaseApp } from './firebaseDb'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';
import firebase from 'firebase/compat/app'
import { version } from '../package'
import store from "./store"
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

// router setup
import routes from './routes/routes'

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import './assets/sass/element-variables.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
import 'bootstrap-icons/font/bootstrap-icons.css'

// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, { sidebarLinks: sidebarLinks })
locale.use(lang)

store.commit('SET_APPVERSION', version)

// configure router

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach(async (to, from, next) => {
  if (to.meta.auth && !await firebaseApp.getCurrentUser()) {
    router.push({ path: 'login' })
  } else if (to.meta.trainerBasis == false && to.meta.admin == false) {
    firebase.auth().currentUser.getIdTokenResult().then(function ({
      claims
    }) {
      store.commit('SET_USERLOCATION', claims.location)
      store.commit('SET_USERROLE', claims.role)
      if (claims.role == 0) {
        router.push({ path: '/ket/overview' })
      } else {
        next()
      }
    })
  } 
  else if (to.meta.admin) {
    firebase.auth().currentUser.getIdTokenResult().then(function ({
      claims
    }) {
      store.commit('SET_USERLOCATION', claims.location)
      store.commit('SET_USERROLE', claims.role)
      if (!( claims.role == 2 || claims.role == 3)) {
        router.push({ path: '/ket/overview' })
      } else {
        next()
      }
    })
  } else {
    next()
  }
})

export default router
initProgress(router);

Sentry.init({
  Vue,
  dsn: "https://106419a605df47ffac3cd33b7829ff52@o1267921.ingest.sentry.io/6454736",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "ket.weitblick.gmbh", "ket.kletterwald-darmstadt.de", "ket.kletterwald-neroberg.de", /^\//],
    }),
    new CaptureConsoleIntegration(
      {
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
      }
    )
  ],
  tracesSampleRate: 1.0,
});

firebaseApp.auth().onAuthStateChanged(function (user) {
  store.dispatch("fetchUser", user)
  if (user) {
    if(user.email) {
      Sentry.setUser({ email: user.email });
    }
  }
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    render: h => h(App),
    router
  })
})
