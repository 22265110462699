import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    appVersion: '',
    userlocation: 0,
    userrole: 0,
    HT: null,
    cash: {
      data: null
    },
    cashfloat: 0,
    banktransfer: {
      data: null
    },
    banktransferTrainer: '',
    kassentrainer: '',
    kasse: 0,
    gutscheine: 0,
    onlinebez: 0,
    ausgaben: 0,
    ecschnitt: 0,
    berechnetzbon: 0,
    kassedate: '',
  },
  getters: {
    user (state) {
      return state.user
    },
    appVersion (state) {
      return state.appVersion
    },
    userlocation (state) {
        return state.userlocation
    },
    userrole (state) {
        return state.userrole
    },
    HT (state) {
      return state.HT
    },
    cash (state) {
      return state.cash
    },
    cashfloat (state) {
      return state.cashfloat
    },
    banktransfer (state) {
      return state.banktransfer
    },
    banktransferTrainer (state) {
      return state.banktransferTrainer
    },
    kassentrainer (state) {
      return state.kassentrainer
    },
    kasse (state) {
      return state.kasse
    },
    ecschnitt (state) {
      return state.ecschnitt
    },
    berechnetzbon (state) {
      return state.berechnetzbon
    },
    gutscheine (state) {
      return state.gutscheine
    },
    onlinebez (state) {
      return state.onlinebez
    },
    ausgaben (state) {
      return state.ausgaben
    },
    kassedate (state) {
      return state.kassedate
    },
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
    SET_APPVERSION (state, value) {
      state.appVersion = value
    },
    SET_USERLOCATION (state, value) {
        state.userlocation = value
    },
    SET_USERROLE (state, value) {
        state.userrole = value
    },
    SET_HT (state, value) {
      state.HT = value
    },
    SET_CASH (state, data) {
      state.cash.data = data
    },
    SET_KASSEDATE (state, value) {
      state.kassedate = value
    },
    SET_CASHFLOAT (state, value) {
      state.cashfloat = value
    },
    SET_BANKTRANSFER (state, data) {
      state.banktransfer.data = data
    },
    SET_BANKTRANSFERTRAINER (state, value) {
      state.banktransferTrainer = value
    },
    SET_KASSENTRAINER (state, value) {
      state.kassentrainer = value
    },
    SET_KASSE (state, value) {
      state.kasse = value
    },
    SET_ECSCHNITT (state, value) {
      state.ecschnitt = value
    },
    SET_BERECHNETZBON (state, value) {
      state.berechnetzbon = value
    },
    SET_GUTSCHEINE (state, value) {
      state.gutscheine = value
    },
    SET_ONLINEBEZ (state, value) {
      state.onlinebez = value
    },
    SET_AUSGABEN (state, value) {
      state.ausgaben = value
    },
  },
  actions: {
    fetchUser ({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null)
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid
        })
      } else {
        commit("SET_USER", null)
      }
    }
  }
})

export default store