<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Welche Kasse soll abgerechnet werden?</h5>
        </div>
        <div class="text-center pt-3">
          <el-radio-group v-model="kasse" size="large">
            <el-radio-button label="Kasse 1"></el-radio-button>
            <el-radio-button label="Kasse 2" :disabled="location == 'DA'"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
  RadioButton,
  RadioGroup
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store";
import Button from "../../../../UIComponents/Button.vue";

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);
Vue.use(RadioGroup);
Vue.use(RadioButton);

export default {
  components: {
    Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      kasse: "Kasse 1",
      location: store.getters.userlocation,
    };
  },
  created() {},
  mounted() {},
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        store.commit("SET_KASSE", this.kasse);
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
}
.text-large {
  font-size: 150%;
}
</style>
