<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Abwesenheiten</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6" v-if="role == 3">
            <div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadData"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>
            </div>
              <div class="col">
            <div>
              <p-button class="mb-0 mt-0" type="default" @click="startPsSync">Papershift Sync</p-button>
            </div>
            <div class="pt-0">
              <small class="text-muted"
                >Letzte Aktualisierung: {{ lastSync }}</small
              >
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
      <div class="col-md-10 ml-auto mr-auto">
        <div class="card card-calendar">
          <div class="card-body">
            <fullCalendar ref="calendar" :options="calendarOptions" />
          </div>
        </div>
      </div>
    </div>
      <div class="card-body row">
        <div class="col-sm-10 ml-auto mr-auto">
          
          <el-table :data="absences" header-row-class-name="text-primary" :cell-class-name="checkStatus" empty-text="Es konnten keine Abwesenheiten gefunden werden." v-loading="loading">
            <el-table-column prop="absenceTrainerName"
                             label="Name">
            </el-table-column>
            <el-table-column prop="absenceStart"
                             label="Beginn"
                             width="150">
            </el-table-column>
            <el-table-column prop="absenceEnd"
                             label="Ende"
                             width="150">
            </el-table-column>
            <el-table-column prop="absenceStatus"
                             label="Status"
                             width="120">
            </el-table-column>
            <el-table-column prop="absenceTitle"
                             label="Titel">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { db, fc } from '../../../../firebaseDb'
  import {Table, TableColumn, Tag, Loading, Select, Option, MessageBox} from 'element-ui'
  import moment from 'moment-timezone'
  import FullCalendar from "@fullcalendar/vue";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import store from "../../../../store";
  import { httpsCallable } from "firebase/functions";
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Tag)

  export default {
    components: {
      FullCalendar,
      [Select.name]: Select,
      [Option.name]: Option,
      MessageBox
    },

    data () {
      return {
          absences: [],
          lastSync: null,
          lastSyncTimestamp: null,
          loading: true,
          location: store.getters.userlocation,
          role: store.getters.userrole,
          calendarOptions: {
            plugins: [
              dayGridPlugin
            ],
            headerToolbar: {
              center: "",
              right: "prev,next,today",
            },
            buttonText: {
              today:    'Heute',
              month:    'Monat',
              week:     'Woche',
              day:      'Tag',
              list:     'Liste',
            },
            initialView: "dayGridMonth",
            events: [], 
            editable: false,
            selectable: false,
            weekNumbers: true,
            weekText: 'KW',
            weekTextLong: 'Kalenderwoche',
            weekNumberCalculation: 'ISO',
            locale: 'de',
            contentHeight: 500
        },
      }
    },
    created () {
        this.loadData()
    },
    methods: {
      loadData () {
        // get last Papershift sync time
        const lastSyncRef = db.collection('papershiftSyncStatus')
        var docname = "absences"
        lastSyncRef.doc(docname).get().then((doc) => {
          this.lastSync = moment(new Date(doc.data().lastSync.seconds * 1000)).format('DD.MM.YY HH:mm').toString(),
          this.lastSyncTimestamp = doc.data().lastSync.seconds * 1000
        })

        // get list of absences
        const absencesRef = db.collection('absences')
        absencesRef.where('end', '>=', moment().subtract(2, 'months').toDate()).orderBy('end').onSnapshot((snapshotChange) => {
            this.absences = []
            this.calendarOptions.events = []
            snapshotChange.forEach((doc) => {
              var status
                if(doc.data().confirmed == true) {
                  status = 'bestätigt'
                } else {
                  status = 'unbestätigt'
                }
                this.absences.push({
                    key: doc.id,
                    absenceTrainerName: doc.data().Name,
                    absenceStart: moment(new Date(doc.data().start.seconds * 1000)).format('DD.MM.YY').toString(),
                    absenceEnd: moment(new Date(doc.data().end.seconds * 1000)).format('DD.MM.YY').toString(),
                    absenceStatus: status,
                    absenceTitle: doc.data().title
                })
                var displayColor
                if(doc.data().confirmed === true) {
                  displayColor = 'green'
                } else {
                  displayColor = 'red'
                }
             this.calendarOptions.events.push({
                title: doc.data().Name + ' (' + doc.data().title + ')',
                start: moment(new Date(doc.data().start.seconds * 1000)).format('YYYY-MM-DD').toString(),
                end: moment(new Date((doc.data().end.seconds + 86400) * 1000)).format('YYYY-MM-DD').toString(), // add 1 day to show last day blocked in calendar too
                allDay: true,
                color: displayColor
              })
            })
            this.loading = false
        })
      },
      checkStatus ({row, column}) {
        if (column.property === 'absenceStatus') {
          const val = row[column.property];
          if (val == "unbestätigt" ) {
            return 'unconfirmed-row' 
          } 
          if (val == "bestätigt" ) {
            return 'confirmed-row' 
          } else {
            return ''
          }
        }
      },
      startPsSync() {
        if(new Date().getTime() - this.lastSyncTimestamp > 600000) { // greater 10 mins
          console.log("Start PS absence sync")
          const loading = this.$loading({
            lock: true,
            text: 'Papershift Sync läuft...',
            spinner: 'el-icon-loading'
          });
          var getAbsences = httpsCallable(fc, "papershift-getPSAbsences");
          getAbsences([]).then((result) => {
            console.log('PS Absence Sync done')
            this.loadData()
            loading.close()
          });
        } else {
          MessageBox.alert(
              "Die Abwesenheiten können erst 10 Minuten nach der letzten Synchronisation erneut aus Papershift abgerufen werden. Die letzte Synchronisation war am " + this.lastSync + " Uhr.",
              {
                confirmButtonText: "OK",
                type: "warning",
                callback: (action) => {},
              }
            );
        }
      },
    }
  }

</script>
<style>
.unconfirmed-row {
  background: #fff3cd !important;
}
.confirmed-row {
  background: #d4edda !important;
}
</style>
