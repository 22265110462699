<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Checkliste {{ checklistDisplayName }}</h5>
          </div>
          <div class="col-sm-3">
            <SelectLocation @locationChanged="updateLocation" v-if="checklistGroup == 'Buero'"></SelectLocation>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" v-if="checklistGroup == 'Buero'">
            <el-tabs v-model="activeTab" @tab-click="changeTab">
              <el-tab-pane label="wöchentlich" name="Buero-weekly"></el-tab-pane>
              <el-tab-pane label="monatlich" name="Buero-monthly"></el-tab-pane>
              <el-tab-pane label="Saisonende" name="Buero-endseason"></el-tab-pane>
              <el-tab-pane label="Winter" name="Buero-winter"></el-tab-pane>
              <el-tab-pane label="Saisonvorbereitung" name="Buero-preseason"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="checklistItems" header-row-class-name="text-primary" empty-text="Es wurden keine Aufgaben gefunden." v-loading="loading">
            <el-table-column prop="checkItem" label="Aufgabe" v-if="checklist != 'Huette'">
            </el-table-column>
            <el-table-column prop="checkItem" label="Zu prüfen" v-if="checklist == 'Huette'">
            </el-table-column>
            <el-table-column prop="checked" label="Erledigt" width="260" v-if="checklist != 'Huette'">
              <template slot-scope="scope">
                <div class="row pl-3">
                  <div class="column">
                    <p-checkbox v-model="scope.row.checked" value="1"></p-checkbox>
                  </div>
                  <div class="column">
                    <el-input size="mini" type="number" v-model="scope.row.inputValue"  v-if="scope.row.fieldType == 2" placeholder="0" min="0" :disabled="scope.row.checked == false"></el-input>
                    <el-input size="mini" type="text" v-model="scope.row.inputValue"  v-if="scope.row.fieldType == 4" placeholder="Bitte eingeben" :disabled="scope.row.checked == false"></el-input>
                    <el-select class="select"
                      v-if="scope.row.fieldType == 3"
                      placeholder="Trainer wählen"
                      filterable
                      size="mini"
                      v-model="scope.row.inputValue"
                      :disabled="scope.row.checked == false">
                      <el-option v-for="option in trainer"
                        class="select"
                        :value="option.trainerName"
                        :label="option.trainerName"
                        :key="option.trainerName">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="checked" label="Wird benötigt" width="260" v-if="checklist == 'Huette'">
              <template slot-scope="scope">
                <div class="row pl-3">
                  <div class="column">
                    <p-checkbox v-model="scope.row.checked" value="1"></p-checkbox>
                  </div>
                  <div class="column">
                    <el-input size="mini" type="text" v-model="scope.row.inputValue"  v-if="scope.row.fieldType == 4" placeholder="Benötigte Menge" :disabled="scope.row.checked == false"></el-input>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="row justify-content-end">            
            <div class="col-sm-auto pt-3 text-right">
              <el-select class="select pt-2"
                      placeholder="Ausgefüllt von..."
                      filterable
                      v-model="processedBy">
                      <el-option v-for="option in trainer"
                        class="select"
                        :value="option.trainerName"
                        :label="option.trainerName"
                        :key="option.trainerName">
                      </el-option>
                    </el-select>
            </div>
            <div class="col-sm-auto text-right pt-3">
              <button type="submit" class="btn btn-success btn-fill btn-wd" @click.prevent="validateList" :disabled="finishButtonDisabled || !processedBy">Fertigstellen</button>
            </div>
          </div>          
          <p class="small pt-3"><i>Begonnene Listen werden beim Wechsel zu anderen KET Funktionen automatisch als Entwurf gespeichert und können innerhalb von 1 Stunde fortgesetzt werden.<br>Durch Klicken auf "Fertigstellen" wird die Liste abgeschlossen und ist danach nicht mehr änderbar.</i></p>          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { db, fc } from '../../../../firebaseDb'
  import {Table, TableColumn, Tooltip, Input, Select, Option, Loading, MessageBox, TabPane, Tabs} from 'element-ui'
  import moment from 'moment-timezone'
  import router from '../../../../main'
  import store from "../../../../store";
  import { httpsCallable } from "firebase/functions";
  import SelectLocation from '/src/components/KET/Views/Components/KETSelectLocation.vue'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Tooltip)
  //Vue.use(Loading);

  export default {
    components: {
      [Input.name]: Input,
      [Select.name]: Select,
      [Option.name]: Option,
      [TabPane.name]: TabPane,
      [Tabs.name]: Tabs,
      MessageBox,
      SelectLocation,
    },

    data () {
      return {
          checklistItems: [],
          checklist: this.$route.params.list,
          checklistDisplayName: '',
          checklistGroup: '',
          activeTab: '',
          loading: true,
          location: store.getters.userlocation,
          saved: false,
          checklists: [ {
            name: 'Kasse Betriebsbeginn',
            value: 'KasseStart',
            group: 'Betrieb'
          },
          {
            name: 'Kasse Betriebsende',
            value: 'KasseEnde',
            group: 'Betrieb'
          },
          {
            name: 'HT Betriebsbeginn',
            value: 'HTStart',
            group: 'Betrieb'
          },
          {
            name: 'HT Betriebsende',
            value: 'HTEnde',
            group: 'Betrieb'
          },
          {
            name: 'Hüttencheckliste',
            value: 'Huette',
            group: 'Huette'
          },
          {
            name: 'Veranstaltung Beginn',
            value: 'VAStart',
            group: 'VA'
          },
          {
            name: 'Veranstaltung Ende',
            value: 'VAEnde',
            group: 'VA'
          },
          {
            name: 'wöchentlich',
            value: 'Buero-weekly',
            group: 'Buero'
          },
          {
            name: 'monatlich',
            value: 'Buero-monthly',
            group: 'Buero'
          },
          {
            name: 'Saisonende',
            value: 'Buero-endseason',
            group: 'Buero'
          },
          {
            name: 'Winter',
            value: 'Buero-winter',
            group: 'Buero'
          },
          {
            name: 'Saisonvorbeitung',
            value: 'Buero-preseason',
            group: 'Buero'
          }],
          trainer: [],
          reportName: '',
          processedBy: '',
          finishButtonDisabled: false
      }
    },
    created () {
      this.reportName = moment().format('YYYYMMDD') + '-' + store.getters.userlocation
      const listRef = db.collection('tagesreports/' + this.reportName + '/' + this.checklist)
      listRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          MessageBox.alert('Diese Checkliste wurde bereits für heute gespeichert und kann daher nicht erneut ausgefüllt werden.', 'Achtung', {
            confirmButtonText: 'OK',
            callback: action => {
              router.push({ name: 'Overview' })
            }
          })
        }
      })
      this.loadList(this.$route.params.list)
      // get list of todays trainers
        const trainerRef = db.collection('trainer')
        if(this.checklistGroup == 'Buero') {
          trainerRef.orderBy('Name').onSnapshot((snapshotChange) => {
            this.trainer = []
            snapshotChange.forEach((doc) => {
                this.trainer.push({
                  trainerName: doc.data().Name
                })
            })
        })
        } else {
          trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).orderBy('Name').onSnapshot((snapshotChange) => {
            this.trainer = []
            snapshotChange.forEach((doc) => {
                this.trainer.push({
                  trainerName: doc.data().Name
                })
            })
        })
        }
    },
    beforeDestroy () {
      this.cacheList()
    },
    methods: {
      changeTab (tab) {
        this.activeTab = tab.name
        this.loadList(tab.name)
      },
      loadList (requestedList) {
        this.checklist = requestedList
        this.checklistGroup = this.checklists.find(o => o.value === this.checklist)['group']
        if (this.checklistGroup == 'Buero') {
          this.checklistDisplayName = "Büro " + this.checklists.find(o => o.value === this.checklist)['name']
          this.activeTab = this.checklist
        } else {
          this.checklistDisplayName = this.checklists.find(o => o.value === this.checklist)['name']
        }

        // check cache
        if (!(localStorage['Checklist-' + this.checklist + '-' + this.location]) || localStorage['Checklist-' + this.checklist + '-' + this.location + '-Valid'] <= Date.now()) {
        // get checklist items
        const listItemsRef = db.collection('checklists/' + this.checklist + '/Items')

          listItemsRef.where('locationID', '==', this.location).orderBy('position').onSnapshot((snapshotChange) => {
            this.checklistItems = []
            snapshotChange.forEach((doc) => {
                this.checklistItems.push({
                    key: doc.id,
                    checkItem: doc.data().Item,
                    fieldType: doc.data().fieldType,
                    position: doc.data().position,
                    checked: false,
                    inputValue: ''
                })
            })
            this.loading = false
        })
        
        } else {
          // restore cached data
          this.checklistItems = []
          this.checklistItems = JSON.parse(localStorage['Checklist-' + this.checklist + '-' + this.location])
          localStorage.removeItem(('Checklist-' + this.checklist + '-' + this.location))
          this.loading = false
        }
      },
      validateList () {
        this.finishButtonDisabled = true;
        const listRef = db.collection('tagesreports/' + this.reportName + '/' + this.checklist)
        listRef.get().then((doc) => {
          if(doc.docs.length > 0) {
            MessageBox.alert('Diese Checkliste wurde bereits für heute gespeichert und kann daher nicht erneut ausgefüllt werden.', 'Achtung', {
              confirmButtonText: 'OK',
              callback: action => {}
            })
          } else {
            // check if all tasks are marked as done
            if(this.checklistItems.find(o => o.checked == false) && this.checklist != 'Huette') {
              MessageBox.confirm('Es wurden nicht alle Aufgaben erledigt. Trotzdem fertigstellen? Die Checkliste kann danach nicht mehr verändert werden!', 'Achtung', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Abbrechen',
                type: 'warning'
              }).then(() => {
                this.saveList()
              }).catch(() => {
                this.finishButtonDisabled = false
              });
            } else {
              this.saveList()
            }
          }
        })
      },
      saveList () {
        const listRef = db.collection('tagesreports/' + this.reportName + '/' + this.checklist)
        var batch = db.batch()
        this.checklistItems.forEach(item => {
          const id = listRef.doc().id
          batch.set(listRef.doc(id), { Task: item.checkItem, checked: item.checked, value: item.inputValue, position: item.position }, { merge: true })
        });
        batch.set(listRef.doc('trainer'), { processedBy: this.processedBy, position: 9999 })

        batch.commit().then(() => {
          if(this.checklist == "Huette") {
            var sendMail = httpsCallable(fc, "reports-sendChecklist");
            sendMail({
              report: this.reportName,
              loc: store.getters.userlocation
            })
          }
          this.saved = true
          this.$notify({
            message: 'Die Checkliste wurde erfolgreich gespeichert!',
            icon: 'fa fa-check-circle',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'success',
            showClose: true,
            timeout: 5000
          })
          router.push({ name: 'Overview' })
        })
      },
      cacheList () {
        if(this.checklistItems.find(o => o.checked == true) && this.saved === false) {
          localStorage['Checklist-' + this.checklist + '-' + this.location] = JSON.stringify(this.checklistItems)
          localStorage['Checklist-' + this.checklist + '-Valid'] = (Date.now() + +3600000) //1h
        }
      },
      updateLocation(loc) {
        this.location = loc
        this.loadList(this.$route.params.list)
      }
    },
    watch: {
      '$route.params.list': function (list) {
        this.cacheList()
        this.loadList(list)
      }
    }
  }

</script>
<style>

</style>
