<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Zeiten & Wetter</h5>
        </div>
        <div class="row pt-1 pb-3 justify-content-center">
          <div class="col-sm-7">
          <el-alert
            title="Bitte das Tagesprotokoll erst zum Betriebsschluss ausfüllen."
            type="info"
            effect="dark"
            :closable="false"
            center
            show-icon>
        </el-alert>
        </div>
        </div>
        <div class="row pb-2 pt-4">
          <label class="col-md-3 col-form-label">Datum</label>
          <div class="col-sm-4">
            <fg-input name="date" v-model="date" disabled></fg-input>
          </div>
        </div>
        <div class="row pb-3">
          <label class="col-md-3 col-form-label">Öffnungszeit</label>
          <div class="col-sm-4">
            <ValidationProvider
              name="open"
              rules="required"
              v-slot="{ errors }"
            >
              <el-time-select
                name="open"
                v-model="open"
                value-format="HH:mm"
                :picker-options="{
                  start: '08:00',
                  step: '00:15',
                  end: '14:00'
                }"
                placeholder="Öffnungszeit"
              >
              </el-time-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Die Öffnungszeit muss angegeben werden.': null}}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row pb-3">
          <label class="col-md-3 col-form-label">Schließzeit</label>
          <div class="col-sm-4">
            <ValidationProvider
              name="close"
              rules="required"
              v-slot="{ errors }"
            >
              <el-time-select
                name="close"
                v-model="close"
                value-format="HH:mm"
                :picker-options="{
                  start: '13:00',
                  step: '00:15',
                  end: '21:00'
                }"
                placeholder="Schließzeit"
              >
              </el-time-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Das Schließzeit muss angegeben werden.': null}}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <label class="col-md-3 col-form-label">Wetter</label>
          <div class="col-sm-4">
            <ValidationProvider
              name="wetter"
              rules="required"
              v-slot="{ errors }"
            >
              <el-select
                v-model="wetter"
                class=""
                name="wetter"
                placeholder="Bitte wählen"
              >
                <el-option
                  class=""
                  label="sonnig"
                  value="sonnig"
                  key="sonnig"
                ></el-option>
                <el-option
                  class=""
                  label="Regen"
                  value="Regen"
                  key="Regen"
                ></el-option>
                <el-option
                  class=""
                  label="wechselhaft"
                  value="wechselhaft"
                  key="wechselhaft"
                ></el-option>
                <el-option
                  class=""
                  label="Wind"
                  value="Wind"
                  key="Wind"
                ></el-option>
                <el-option
                  class=""
                  label="Sturm/Gewitter"
                  value="Sturm/Gewitter"
                  key="Sturm/Gewitter"
                ></el-option>
              </el-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Das Wetter muss ausgewählt werden.': null}}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import Vue from 'vue'
  import { db } from '../../../../../firebaseDb'
  import {Table, TableColumn, Tooltip, Input, Select, Option, TimeSelect, TimePicker} from 'element-ui'
  import moment from 'moment-timezone'
  import router from '../../../../../main'
  import { MessageBox } from 'element-ui';
  import { Wizard, WizardTab} from 'src/components/UIComponents'
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";

  extend("required", required);

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Tooltip)
  Vue.use(TimePicker)

export default {
  components: {
      [Input.name]: Input,
      [Select.name]: Select,
      [Option.name]: Option,
      [TimeSelect.name]: TimeSelect
  },
  data() {
    return {
          date: moment().format('DD.MM.YYYY'),
          open: '',
          close: '',
          wetter: ''
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}

.invalid {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
