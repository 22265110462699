<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Kasse zählen</h5>
        </div>
        <div class="alert alert-success col-sm-6 offset-3 mb-5" role="alert">
          <b>Achtung:</b> Hier bitte den gesamten Kassenbestand,
          <b>inklusive</b> der späteren Bankeinzahlung eintragen!
        </div>

        <div class="row">
          <div class="col-sm-4 offset-1 mr-5">
            <h6>Münzgeld</h6>
            <table class="table table-borderless table-sm mt-3 table-hover">
              <thead>
                <th scope="col" class="w-25"></th>
                <th scope="col" class="w-25">Anzahl</th>
                <th scope="col" class="text-right w-25">Wert</th>
              </thead>
              <tbody>
                <tr>
                  <td>1 Cent</td>
                  <td>
                    <ValidationProvider
                      name="Cent1"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Cent1" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Cent1 * 0.01)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>2 Cent</td>
                  <td>
                    <ValidationProvider
                      name="Cent2"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Cent2" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Cent2 * 0.02)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>5 Cent</td>
                  <td>
                    <ValidationProvider
                      name="Cent5"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Cent5" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Cent5 * 0.05)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>10 Cent</td>
                  <td>
                    <ValidationProvider
                      name="Cent10"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Cent10" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Cent10 * 0.1)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>20 Cent</td>
                  <td>
                    <ValidationProvider
                      name="Cent20"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Cent20" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Cent20 * 0.2)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>50 Cent</td>
                  <td>
                    <ValidationProvider
                      name="Cent50"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Cent50" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Cent50 * 0.5)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>1 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro1"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro1" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro1 * 1)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>2 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro2"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro2" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro2 * 2)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-sm-4 ml-4">
            <h6>Scheingeld</h6>
            <table class="table table-borderless table-sm mt-3 table-hover">
              <thead>
                <th scope="col" class="w-25"></th>
                <th scope="col" class="w-25">Anzahl</th>
                <th scope="col" class="text-right w-25">Wert</th>
              </thead>
              <tbody>
                <tr>
                  <td>5 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro5"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro5" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro5 * 5)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>10 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro10"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro10" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro10 * 10)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>20 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro20"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro20" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro20 * 20)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>50 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro50"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro50" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro50 * 50)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>100 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro100"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro100" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro100 * 100)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>200 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro200"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro200" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro200 * 200)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
                <tr>
                  <td>500 Euro</td>
                  <td>
                    <ValidationProvider
                      name="Euro500"
                      rules="required|numeric"
                      v-slot="{}"
                    >
                      <fg-input type="number" v-model="Euro500" min="0">
                      </fg-input>
                    </ValidationProvider>
                  </td>
                  <td class="text-right">
                    {{
                      (Euro500 * 500)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    €
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- row count tables -->

        <div class="d-flex justify-content-end pr-5 pt-0">
          <h4 style="margin-top: 0 !important">
            Gesamt:
            {{
              (
                Cent1 * 0.01 +
                Cent2 * 0.02 +
                Cent5 * 0.05 +
                Cent10 * 0.1 +
                Cent20 * 0.2 +
                Cent50 * 0.5 +
                Euro1 * 1 +
                Euro2 * 2 +
                Euro5 * 5 +
                Euro10 * 10 +
                Euro20 * 20 +
                Euro50 * 50 +
                Euro100 * 100 +
                Euro200 * 200 +
                Euro500 * 500
              )
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            }}
            €
          </h4>
        </div>

        <div class="row pl-1 pt-4">
          <div class="col-sm-4 offset-1 pb-3">
            <label>Nachgezählt durch</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider name="recount" rules="required" v-slot="{errors}">
                <el-select
                  class="select"
                  placeholder="Trainer wählen"
                  filterable
                  v-model="recountTrainer"
                >
                  <el-option
                    v-for="option in trainer"
                    class="select"
                    :value="option.trainerName"
                    :label="option.trainerName"
                    :key="option.trainerName"
                  >
                  </el-option>
                </el-select>
                <span class="invalid mt-2">{{ errors[0] ? 'Der Trainer, der die Kasse nachgezählt hat, muss angegeben werden.': null}}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
import store from "../../../../../store";

extend("required", required);
extend("numeric", numeric);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      trainer: [],
      recountTrainer: "",
      kassenbestand: 0,
      Cent1: 0,
      Cent2: 0,
      Cent5: 0,
      Cent10: 0,
      Cent20: 0,
      Cent50: 0,
      Euro1: 0,
      Euro2: 0,
      Euro5: 0,
      Euro10: 0,
      Euro20: 0,
      Euro50: 0,
      Euro100: 0,
      Euro200: 0,
      Euro500: 0,
    };
  },
  created() {
    // get list of todays trainers
    const trainerRef = db.collection("trainer");
    trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).orderBy("Name").onSnapshot((snapshotChange) => {
      this.trainer = [];
      snapshotChange.forEach((doc) => {
        this.trainer.push({
          trainerName: doc.data().Name,
        });
      });
    });
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.kassenbestand =
          this.Cent1 * 0.01 +
          this.Cent2 * 0.02 +
          this.Cent5 * 0.05 +
          this.Cent10 * 0.1 +
          this.Cent20 * 0.2 +
          this.Cent50 * 0.5 +
          this.Euro1 * 1 +
          this.Euro2 * 2 +
          this.Euro5 * 5 +
          this.Euro10 * 10 +
          this.Euro20 * 20 +
          this.Euro50 * 50 +
          this.Euro100 * 100 +
          this.Euro200 * 200 +
          this.Euro500 * 500;
        store.commit("SET_CASH", {
          Cent1: this.Cent1,
          Cent2: this.Cent2,
          Cent5: this.Cent5,
          Cent10: this.Cent10,
          Cent20: this.Cent20,
          Cent50: this.Cent50,
          Euro1: this.Euro1,
          Euro2: this.Euro2,
          Euro5: this.Euro5,
          Euro10: this.Euro10,
          Euro20: this.Euro20,
          Euro50: this.Euro50,
          Euro100: this.Euro100,
          Euro200: this.Euro200,
          Euro500: this.Euro500,
          SumAll: this.kassenbestand,
        });
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
}
.table-sm td,
.table-sm th {
  padding: 0.2rem !important;
}
input[type="number"] {
  text-align: right;
}
</style>
