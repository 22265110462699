<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">
            Datum, Wechselgeldbestand & Verantwortlichkeiten ({{ kasse }})
          </h5>
        </div>
        <div class="row ml-1">
          <div class="col-sm-4 pb-3">
            <label>Datum des Kassenabschlusses</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider
                name="date"
                rules="required"
                v-slot="{ errors }"
              >
                <el-date-picker
                  v-model="date"
                  type="date"
                  placeholder="Datum wählen"
                  :picker-options="pickerOptions1"
                  format="dd.MM.yyyy"
                  :disabled="userrole != '3'"
                >
                </el-date-picker>
                <span class="invalid mt-2">{{ errors[0] ? 'Das Datum des Kassenabschlusses muss angegeben werden.': null}}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-4"></div>
        </div>
        <div class="row ml-1">
          <div class="col-sm-4 pb-3">
            <label>Wechselgeld in Kasse vor Betriebsbeginn</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider
                name="wechselgeldstart"
                rules="required"
                v-slot="{ failed }"
              >
                <fg-input
                  placeholder="0,00"
                  type="number"
                  v-model="wechselgeldstart"
                  min="0"
                  :error="failed ? 'Die Summe des Wechselgeldes muss angegeben werden.': null"
                >
                  <template slot="addonRight">€</template>
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-4 pb-3">
            <label>Stand vom</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider
                name="wechselgeldstand"
                rules="required"
                v-slot="{ errors }"
              >
                <el-date-picker
                  v-model="wechselgeldstand"
                  type="date"
                  placeholder="Datum wählen"
                  :picker-options="pickerOptions1"
                  format="dd.MM.yyyy"
                >
                </el-date-picker>
                <span class="invalid mt-2">{{ errors[0] ? 'Das Datum des Wechselgeldbestands muss angegeben werden.': null}}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row ml-1">
          <div class="col-sm-4 pb-3">
            <label>Kassenabschluss durch</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider
                name="Kassentrainer"
                rules="required"
                v-slot="{errors}"
              >
                <el-select
                  class="select"
                  placeholder="Trainer wählen"
                  filterable
                  v-model="kassentrainer"
                >
                  <el-option
                    v-for="option in trainer"
                    class="select"
                    :value="option.trainerName"
                    :label="option.trainerName"
                    :key="option.trainerName"
                  >
                  </el-option>
                </el-select>
                <span class="invalid mt-2">{{ errors[0] ? 'Der Trainer, der den Kassenabschluss erstellt, muss angegeben werden.': null}}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-4 pb-3">
            <label>Haupttrainer</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider name="HT" rules="required" v-slot="{errors}">
                <el-select
                  class="select"
                  placeholder="Haupttrainer wählen"
                  filterable
                  v-model="HT"
                >
                  <el-option
                    v-for="option in trainer"
                    class="select"
                    :value="option.trainerName"
                    :label="option.trainerName"
                    :key="option.trainerName"
                  >
                  </el-option>
                </el-select>
                <span class="invalid mt-2">{{ errors[0] ? 'Der Haupttrainer muss angegeben werden.': null}}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-4">
            <label>Bankeinzahlung durch</label>
            <div class="col-sm-10 pl-0">
              <ValidationProvider
                name="Bankeinzahlungtrainer"
                rules="required"
                v-slot="{errors}"
              >
                <el-select
                  class="select"
                  placeholder="Trainer wählen"
                  filterable
                  v-model="bankeinzahlungtrainer"
                >
                  <el-option
                    class="select"
                    value="Im Safe hinterlegt"
                    label="Im Safe hinterlegt"
                    key="Im Safe hinterlegt"
                  ></el-option>
                  <el-option
                    v-for="option in trainer"
                    class="select"
                    :value="option.trainerName"
                    :label="option.trainerName"
                    :key="option.trainerName"
                  >
                  </el-option>
                </el-select>
                <span class="invalid mt-2">{{ errors[0] ? 'Der Trainer, der die Bankeinzahlung übernimmt, muss angegeben werden. Kann niemand die Bankeinzahlung übernehmen, dann "Im Safe hinterlegt" auswählen.': null}}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import { Table, TableColumn, Tooltip, Input, Select, Option } from "element-ui";
import moment from "moment-timezone";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store";

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      date: moment(),
      kasse: "",
      wechselgeldstart: "",
      wechselgeldstand: "",
      kassentrainer: "",
      HT: "",
      bankeinzahlungtrainer: "",
      trainer: [],
      userrole: store.getters.userrole || "1",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
    };
  },
  created() {
    // get list of trainers (HT and Kasse)
    async function getTrainerKasseOrHT() {
      const trainerRef = db.collection("trainer");
      const isKasse = trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).where("isKasse", "==", true).get();
      const isHT = trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).where("isHT", "==", true).get();

      const [KasseQuerySnapshot, HTQuerySnapshot] = await Promise.all([
        isKasse,
        isHT,
      ]);

      const KasseArray = KasseQuerySnapshot.docs;
      const HTArray = HTQuerySnapshot.docs;

      const trainerArray = KasseArray.concat(HTArray);

      return trainerArray;
    }

    getTrainerKasseOrHT().then((result) => {
      this.trainer = [];
      result.forEach((doc) => {
        const index = this.trainer.findIndex((element) => {
          if (element.trainerName == doc.data().Name) {
            return true;
          }
        });
        if (index === -1) {
          this.trainer.push({
            trainerName: doc.data().Name,
          });
        }
      });
    });
  },
  mounted() {
    this.$watch("$parent.active", (newVal, oldVal) => {
      if (newVal === true) {
        this.updateKasse();
      }
    });
  },
  methods: {
    updateKasse() {
      this.kasse = store.getters.kasse;

      const kassenbestandRef = db.collection("kassenbestand");
      kassenbestandRef
        .doc(this.kasse + '-' + store.getters.userlocation)
        .get()
        .then((doc) => {
          this.wechselgeldstart = doc.data().Bestand.toFixed(2);
          this.wechselgeldstand = new Date(doc.data().Date.seconds * 1000);
        });
      this.userrole = store.getters.userrole
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        store.commit("SET_CASHFLOAT", this.wechselgeldstart);
        store.commit("SET_HT", this.HT);
        store.commit("SET_KASSENTRAINER", this.kassentrainer);
        store.commit("SET_BANKTRANSFERTRAINER", this.bankeinzahlungtrainer);
        store.commit("SET_KASSEDATE", this.date);
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.invalid {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
