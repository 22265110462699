<template>
  <div>
    <div class="row">
      <div class="col-md-auto">
        <h5 class="title">Betriebsordner</h5>
      </div>
      <div class="col text-right" v-if="role > 1">
        <p-button type="success" @click="newDoc()" class="mt-0"><i
            class="bi bi-file-earmark-plus"></i> Hinzufügen</p-button>
      </div>
    </div>
    <div class="card-body row">
      <div class="col">
        <tabs
          pills
          type="primary"
          icons
          centered
          tab-content-classes="tab-space"
          stretch
        >
          <tab-pane id="allgemein" key="allgemein">
            <span slot="title">
              <i class="bi bi-file-medical"></i> Allgemein
            </span>
            <div class="row mt-3">
              <div
                class="col-lg-4"
                v-for="document in documents"
                :key="document.title"
              >
                <div class="card mt-3" style="max-width: 20rem">
                  <div class="card-body">
                    <h5 class="card-title">{{ document.title }}</h5>
                    <p-button type="success" @click="loadVideo(document.title, document.url)" v-if="document.contentType == 'video'"
                      ><i class="bi bi-file-play"></i> Video
                      anzeigen</p-button
                    >
                    <p-button type="success" @click="loadDoc(document.filename)" v-if="document.contentType == 'pdf'"
                      ><i class="bi bi-file-pdf"></i> Dokument
                      anzeigen</p-button
                    >
                  </div>
                  <div class="card-footer text-right" v-if="role > 1">
                    <div class="row text-right">
                      <div class="col">
                        <p-button type="default" @click="editDoc(document.key, document.title, document.filename, document.category, document.locationID)" size="sm" title="Element bearbeiten" class="mr-2"
                          ><i class="bi bi-pencil-square"></i>
                          </p-button
                        >
                      <p-button type="danger" @click="removeDoc(document.key, document.title)" size="sm" title="Element löschen"
                          ><i class="bi bi-trash"></i>
                          </p-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane id="arbeitssicherheit" key="arbeitssicherheit">
            <span slot="title">
              <i class="bi bi-cone-striped"></i> Arbeitssicherheit
            </span>
            <div class="row mt-3">
              <div
                class="col-md-4"
                v-for="document in documentsArbeitssicherheit"
                :key="document.title"
              >
                <div class="card mt-3" style="width: 20rem">
                  <div class="card-body">
                    <h5 class="card-title">{{ document.title }}</h5>
                    <p-button type="success" @click="loadVideo(document.title, document.url)" v-if="document.contentType == 'video'"
                      ><i class="bi bi-file-play"></i> Video
                      anzeigen</p-button
                    >
                    <p-button type="success" @click="loadDoc(document.filename)" v-if="document.contentType == 'pdf'"
                      ><i class="bi bi-file-pdf"></i> Dokument
                      anzeigen</p-button
                    >
                  </div>
                  <div class="card-footer text-right" v-if="role > 1">
                    <div class="row text-right">
                      <div class="col">
                        <p-button type="default" @click="editDoc(document.key, document.title, document.filename, document.category, document.locationID)" size="sm" title="Element bearbeiten" class="mr-2"
                          ><i class="bi bi-pencil-square"></i>
                          </p-button
                        >
                      <p-button type="danger" @click="removeDoc(document.key, document.title)" size="sm" title="Element löschen"
                          ><i class="bi bi-trash"></i>
                          </p-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
    </div>

    <modal
      :show.sync="showEditForm"
      headerClasses="justify-content-center"
      modalClasses="modal-betriebsordner"
      modalMaxWidth="700px"
    >
      <h5 slot="header" class="title mb-0" v-if="editMode == 'Edit'">Element bearbeiten</h5>
      <h5 slot="header" class="title mb-0" v-if="editMode == 'New'">Element hinzufügen</h5>
      <fg-input placeholder="Titel des Dokuments/Videos" label="Titel"
        v-model="editDocTitle"></fg-input>
      <label class="pt-1">Kategorie</label>
      <el-select
          placeholder="Kategorie"
          v-model="editDocCategory">
        <el-option
          value="Allgemein"
          label="Allgemein"
          key="Allgemein">
        </el-option>
        <el-option
          value="Arbeitssicherheit"
          label="Arbeitssicherheit"
          key="Arbeitssicherheit">
        </el-option>
      </el-select>
      <label class="pt-2">Standorte</label>
      <el-select multiple
          placeholder="Standorte"
          v-model="editDocLocation">
        <el-option
          value="DA"
          label="Darmstadt"
          key="DA">
        </el-option>
        <el-option
          value="NB"
          label="Neroberg"
          key="NB">
        </el-option>
        <el-option
          value="FRA"
          label="Frankfurt"
          key="FRA">
        </el-option>
      </el-select>
      <div class="pt-3">
        <p-button type="default" @click="chooseFile" v-if="!editDocFiledata">Datei hochladen (PDF oder Video)</p-button>
        <p class="pt-3" v-if="editDocFiledata">Datei: {{ editDocFiledata.name }}</p>
        <input type="file" @change="prepareFile" ref="fileUpload" accept="application/pdf,video/*" style="display: none">
      </div>
      <div class="py-3" v-if="uploading">
        <el-progress :percentage="uploadProgress" :stroke-width="10" color="#15a34a"></el-progress>
      </div>
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showEditForm = false;"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="updateDoc" v-if="editMode == 'Edit'"
            >Speichern</p-button
          >
          <p-button type="success" link @click="addDoc" v-if="editMode == 'New'"
            >Hinzufügen</p-button
          >
        </div>
      </template>
    </modal>

    <modal
      :show.sync="showVideoPlayer"
      headerClasses="justify-content-center"
      modalClasses="modal-betriebsordner"
      modalMaxWidth="700px"
    >
      <h5 slot="header" class="title mb-0">{{videoTitle}}</h5>
      <video width="100%" controls crossorigin="anonymous" preload="none" ref="videoRef" autoplay>
        <source src="" type="video/*">
        Dein Browser unterstützt leider keine Video-Wiedergabe.
      </video>
      
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { Tabs, TabPane, Modal } from "src/components/UIComponents";
import { Loading, MessageBox, Select, Option, Progress } from "element-ui";
import router from "../../../../main";
import store from "../../../../store";
import { db, fc, FirebaseStorage } from "../../../../firebaseDb";
Vue.use(Loading);
Vue.use(Tabs);
Vue.use(TabPane);

export default {
  components: {
    TabPane,
    Tabs,
    MessageBox,
    Modal,
    [Progress.name]: Progress,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      documents: [],
      documentsArbeitssicherheit: [],
      loading: true,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      activeTabName: "allgemein",
      showEditForm: false,
      showVideoPlayer: false,
      editDocId: null,
      editDocTitle: null,
      editDocCategory: null,
      editDocFilename: null,
      editDocLocation: [],
      editDocFiledata: null,
      editDocContentType: null,
      uploadValue: 0,
      editMode: '',
      videoTitle: '',
      videoUrl: '',
      uploading: false,
      uploadProgress: 0,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadDoc(doc) {
      router.push({ name: "Print", params: { file: doc } });
    },
    loadVideo(title, url) {
      this.videoTitle = title;
      this.videoUrl = url;
      this.$refs.videoRef.src = url;
      this.showVideoPlayer = true;
    },
    loadData() {
      const betriebsordnerRef = db.collection("betriebsordner");
      betriebsordnerRef.where('locationID', 'array-contains', this.location).orderBy("title").onSnapshot((snapshotChange) => {
        this.documents = [];
        this.documentsArbeitssicherheit = [];
        snapshotChange.forEach((doc) => {
          if (doc.data().category == "Arbeitssicherheit") {
            this.documentsArbeitssicherheit.push({
              key: doc.id,
              title: doc.data().title,
              category: doc.data().category,
              filename: doc.data().filename,
              locationID: doc.data().locationID,
              contentType: doc.data().contentType || "pdf",
            });
          } else {
            this.documents.push({
              key: doc.id,
              title: doc.data().title,
              category: doc.data().category,
              filename: doc.data().filename,
              url: doc.data().url,
              locationID: doc.data().locationID,
              contentType: doc.data().contentType || "pdf",
            });
          }
          
        });
        this.loading = false
      });
    },
    newDoc() {
      this.editDocId = null;
      this.editDocTitle = null;
      this.editDocFilename = null;
      this.editDocLocation = null;
      this.editDocCategory = null;
      this.editDocFiledata = null;
      this.editDocContentType = null;
      this.uploadProgress = 0;
      this.editMode = "New";
      this.showEditForm = true;
    },
    addDoc() {
      let folder = null;
      let filename = null;
      if (this.editDocFiledata && this.editDocTitle && this.editDocLocation && this.editDocCategory) {
        filename = this.editDocFiledata.name;
        if (this.editDocFiledata.type.includes("video")) {
          this.editDocContentType = "video";
          folder = "Video";
        } else {
          this.editDocContentType = "pdf";
          folder = "PDF";
        }
          this.uploading = true;
          const storageRef = FirebaseStorage.ref("/" + folder + "/" + filename).put(this.editDocFiledata);
          storageRef.on('state_changed', snapshot => {
            this.uploadProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          }, error => { console.log(error.message) },
            () => {
              this.uploadProgress = 100;
              storageRef.snapshot.ref.getDownloadURL().then((url) => {
                console.log("url: " + url)
                if (this.editDocContentType == "pdf") {
                  filename = this.editDocFiledata.name.replace(".pdf", "");
                }
                db.collection("betriebsordner")
                  .add({
                    title: this.editDocTitle,
                    category: this.editDocCategory,
                    filename: filename,
                    locationID: this.editDocLocation,
                    url: url,
                    contentType: this.editDocContentType,
                  })
                  .then(() => {
                    this.editDocId = null;
                    this.editDocTitle = null;
                    this.editDocFilename = null;
                    this.editDocLocation = null;
                    this.editDocCategory = null;
                    this.editDocFiledata = null;
                    this.editDocContentType = null;
                    this.uploading = false;
                    this.showEditForm = false;
                    this.editMode = "";
                    console.log("Document with attachment added.");
                  })
                  .catch(() => {
                    console.log("Error adding document with attachment " + error);
                  });
              });
            }
          );
      } else {
        console.log('Missing data')
      }
    },
    editDoc(id, title, filename, category, location) {
      this.editDocId = id;
      this.editDocTitle = title;
      this.editDocFilename = filename;
      this.editDocLocation = location;
      this.editDocCategory = category;
      this.uploadProgress = 0;
      this.editMode = "Edit";
      this.showEditForm = true;
    },
    updateDoc() {
      let folder = null;
      let filename = null;
      if (this.editDocTitle && this.editDocLocation && this.editDocCategory) {
        filename = this.editDocFiledata.name;
        if (this.editDocFiledata.type.includes("video")) {
          this.editDocContentType = "video";
          folder = "Video";
        } else {
          this.editDocContentType = "pdf";
          folder = "PDF";
        }
          this.uploading = true;
          const storageRef = FirebaseStorage.ref("/" + folder + "/" + filename).put(this.editDocFiledata);
          storageRef.on('state_changed', snapshot => {
            this.uploadProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          }, error => { console.log(error.message) },
            () => {
              this.uploadProgress = 100;
              storageRef.snapshot.ref.getDownloadURL().then((url) => {
                console.log("url: " + url)
                if (this.editDocContentType == "pdf") {
                  filename = this.editDocFiledata.name.replace(".pdf", "");
                }
                db.collection("betriebsordner").doc(this.editDocId)
                  .update({
                    title: this.editDocTitle,
                    category: this.editDocCategory,
                    filename: filename,
                    locationID: this.editDocLocation,
                    url: url,
                    contentType: this.editDocContentType,
                  })
                  .then(() => {
                    this.editDocId = null;
                    this.editDocTitle = null;
                    this.editDocFilename = null;
                    this.editDocLocation = null;
                    this.editDocCategory = null;
                    this.editDocFiledata = null;
                    this.editDocContentType = null;
                    this.uploading = false;
                    this.showEditForm = false;
                    this.editMode = "";
                    console.log("Document with attachment updated.");
                  })
                  .catch(() => {
                    console.log("Error updating document with attachment " + error);
                  });
              });
            }
          );
      } else {
        console.log('Missing data')
      }
    },
    removeDoc(id, title) {
      MessageBox.confirm(
        'Soll das Element "' + title + '" wirklich aus dem Betriebsordner gelöscht werden?',
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              // remove doc
              db.collection("betriebsordner")
                .doc(id)
                .delete();
            }
          },
        }
      );
    },
    chooseFile() {
      this.$refs.fileUpload.click()
    },
    prepareFile(event) {
      this.uploadValue = 0;
      this.editDocFiledata = event.target.files[0];
    },
  },
};
</script>
<style>
</style>
