var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-3 text-right"},[_c('p-button',{staticClass:"mt-0",attrs:{"type":"primary"},on:{"click":function($event){return _vm.newVA()}}},[_vm._v("Neue Veranstaltung")])],1),_c('div',{staticClass:"col-sm-3 text-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',[_c('el-input',{attrs:{"placeholder":"Veranstaltung suchen..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('i',{staticClass:"bi bi-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1)])])])])]),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-sm-12"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.veranstaltungen.filter(
              (data) =>
                !_vm.search ||
                data.company.toLowerCase().includes(_vm.search.toLowerCase()) ||
                data.Id.toLowerCase().includes(_vm.search.toLowerCase()) ||
                data.contact.toLowerCase().includes(_vm.search.toLowerCase())
            ),"header-row-class-name":"text-primary","row-class-name":_vm.tablePastEvents,"empty-text":"Es konnten keine Veranstaltungen gefunden werden."},on:{"row-click":_vm.showDetails}},[_c('el-table-column',{attrs:{"prop":"Id","label":"Angebot-Nr."}}),_c('el-table-column',{attrs:{"prop":"company","label":"Firma/Verein"}}),_c('el-table-column',{attrs:{"prop":"contact","label":"Ansprechpartner"}}),_c('el-table-column',{attrs:{"prop":"date","label":"Datum"}}),_c('el-table-column',{attrs:{"prop":"vatrainer","label":"Trainer"}}),_c('el-table-column',{attrs:{"class-name":"action-buttons td-actions","align":"right","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-tooltip',{attrs:{"content":"Veranstaltung löschen","placement":"top"}},[_c('p-button',{attrs:{"type":"danger","size":"sm","icon":""},on:{"click":function($event){return _vm.removeVA(props.$index, props.row)}}},[_c('i',{staticClass:"fa fa-times"})])],1)]}}])})],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"card-title"},[_vm._v("Veranstaltungen")])])
}]

export { render, staticRenderFns }