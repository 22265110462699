<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12 text-center pb-3">
          <h5 class="info-text">Besondere Vorkommnisse</h5>
          <span class="info-text py-3"
            >Besondere Vorkommnisse während des Betriebs wie z.B. Unfälle, Verletzungen, beinahe
            Unfälle, gravierende Sicherungsfehler etc.</span>
        </div>
        <div class="row pb-3 pt-2 justify-content-center">
          <div class="col-sm-8">
            <textarea
              class="form-control px-2"
              placeholder="Besondere Vorkommnisse..."
              rows="7"
              v-model="besondereVorkommnisse"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store"
import PSwitch from 'src/components/UIComponents/Switch.vue'

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    PSwitch,
  },
  data() {
    return {
      locationID: store.getters.userlocation,
      sendTrainerFeedback: false,
      besondereVorkommnisse: "",
    };
  },
  created () {
    
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
  //width: 100% !important
}
</style>
