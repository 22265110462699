<template>
    <div class="col-md-auto text-right">
        <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="updateLocation"
                :disabled="role < 3"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
        </el-select>
    </div>
</template>
<script>
  import Vue from "vue";
  import store from "../../../../store";
  import { Select, Option } from "element-ui";
  
  export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
  
    data() {
      return {
        location: store.getters.userlocation,
        role: store.getters.userrole,
      };
    },
    created() {
    },
    methods: {
        updateLocation() {
            this.$emit('locationChanged', this.location)
        }
    },
  };
</script>
<style>
</style>
  