<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Benutzer verwalten</h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="users"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Benutzer gefunden werden."
            v-loading="loading"
            :default-sort="{ prop: 'displayName', order: 'ascending' }"
          >
            <el-table-column prop="displayName" label="Anzeigename">
            </el-table-column>
            <el-table-column prop="email" label="Benutzername">
              <template slot-scope="scope">{{
                scope.row.email.split("@")[0]
              }}</template>
            </el-table-column>
            <el-table-column prop="customClaims.location" label="Standort">
              <template slot-scope="scope"
                ><span v-if="scope.row.customClaims.location == 'DA'"
                  >Darmstadt</span
                ><span v-else-if="scope.row.customClaims.location == 'NB'"
                  >Neroberg</span
                ><span v-else-if="scope.row.customClaims.location == 'FRA'"
                  >Frankfurt</span
                ><span v-else>-</span></template
              >
            </el-table-column>
            <el-table-column prop="customClaims.role" label="Rolle">
              <template slot-scope="scope"
                ><span v-if="scope.row.customClaims.role == 0">Trainer (Basis)</span
                ><span v-else-if="scope.row.customClaims.role == 1">Trainer (Standard)</span
                ><span v-else-if="scope.row.customClaims.role == 2"
                  >Parkleitung</span
                ><span v-else-if="scope.row.customClaims.role == 3"
                  >Büro</span
                ></template
              >
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label=""
            >
              <template slot-scope="props">
                <el-tooltip content="Benutzer bearbeiten" placement="top">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="editUser(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Passwort zurücksetzen" placement="top">
                  <p-button
                    type="warning"
                    size="sm"
                    icon
                    @click="resetPassword(props.$index, props.row)"
                  >
                    <i class="fa fa-key"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Benutzer löschen" placement="top">
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="removeUser(props.$index, props.row)"
                  >
                    <i class="fa fa-times"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-12 text-right mt-4 mb-2">
          <p-button type="primary" @click="modals.newUser = true"
            >Neuer Benutzer</p-button
          >
        </div>
        <div class="col-md-5">
          <h6>Berechtigungen der Benutzerrollen</h6>
          <ul>
            <li>
              <b>Trainer (Basis):</b> Kann nur auf die Menüpunkte "Übersicht",
              "Retterstatus", "Kontaktliste", "Buschtrommel" und "Betriebsordner" für den gewählten Standort zugreifen.
            </li>
            <li>
              <b>Trainer (Standard):</b> Kann nur auf die Menüpunkte "Übersicht",
              "Haupttrainer", "Kasse" und "Veranstaltungen" für den gewählten Standort zugreifen.
            </li>
            <li class="mt-2">
              <b>Parkleitung:</b> Kann zusätzlich auf den Menüpunkt
              "Parkleitung/Büro" für den gewählten Standort zugreifen
              (ausgenommen Benutzerverwaltung).
            </li>
            <li class="mt-2">
              <b>Büro:</b> Kann auf alle Menüpunkte zugreifen und im Bereich
              Parkleitung/Büro zwischen den Standorten wechseln. Zusätzlich
              können Benutzer mit dieser Rolle auch auf die Benutzerverwaltung
              zugreifen.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.editUser" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">
        Benutzer {{ this.editUsername }} bearbeiten
      </h5>
      <div class="row">
        <label class="col-md-4 col-form-label">Benutzername</label>
        <div class="col-md-8">
          <fg-input type="text" v-model="editUsername"></fg-input>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Anzeigename</label>
        <div class="col-md-8">
          <fg-input type="text" v-model="editDisplayname"></fg-input>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Standort</label>
        <div class="col-md-8">
          <el-select
            class="select"
            placeholder="Standort wählen"
            filterable
            v-model="editLocation"
          >
            <el-option class="select" value="NB" label="Neroberg" key="NB">
            </el-option>
            <el-option class="select" value="DA" label="Darmstadt" key="DA">
            </el-option>
            <el-option class="select" value="FRA" label="Frankfurt" key="FRA">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Rolle</label>
        <div class="col-md-8">
          <el-select
            class="select"
            placeholder="Rolle wählen"
            filterable
            v-model="editRole"
          >
            <el-option class="select" value="0" label="Trainer (Basis)" key="0">
            </el-option>
            <el-option class="select" value="1" label="Trainer (Standard)" key="1">
            </el-option>
            <el-option class="select" value="2" label="Parkleitung" key="2">
            </el-option>
            <el-option class="select" value="3" label="Büro" key="3">
            </el-option>
          </el-select>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="modals.editUser = false"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="modifyUser">Speichern</p-button>
        </div>
      </template>
    </modal>

    <modal :show.sync="modals.newUser" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">Neuen Benutzer anlegen</h5>
      <div class="row">
        <label class="col-md-4 col-form-label">Benutzername</label>
        <div class="col-md-8">
          <fg-input type="text" v-model="newUsername"></fg-input>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Anzeigename</label>
        <div class="col-md-8">
          <fg-input type="text" v-model="newDisplayname"></fg-input>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Standort</label>
        <div class="col-md-8">
          <el-select
            class="select"
            placeholder="Standort wählen"
            filterable
            v-model="newLocation"
          >
            <el-option class="select" value="NB" label="Neroberg" key="NB">
            </el-option>
            <el-option class="select" value="DA" label="Darmstadt" key="DA">
            </el-option>
            <el-option class="select" value="FRA" label="Frankfurt" key="FRA">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Rolle</label>
        <div class="col-md-8">
          <el-select
            class="select"
            placeholder="Rolle wählen"
            filterable
            v-model="newRole"
          >
            <el-option class="select" value="0" label="Trainer (Basis)" key="0">
            </el-option>
            <el-option class="select" value="1" label="Trainer (Standard)" key="1">
            </el-option>
            <el-option class="select" value="2" label="Parkleitung" key="2">
            </el-option>
            <el-option class="select" value="3" label="Büro" key="3">
            </el-option>
          </el-select>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button
            type="default"
            link
            @click="
              modals.newUser = false;
              newUsername = null;
              newDisplayname = null;
              newLocation = null;
              newRole = null;
            "
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="addUser"
            >Benutzer anlegen</p-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { firebaseApp, fc } from "../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Loading,
  Select,
  Option,
  MessageBox,
} from "element-ui";
import { Modal } from "src/components/UIComponents";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import firebase from "firebase/compat/app";
import { httpsCallable } from "firebase/functions";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    MessageBox,
    Modal,
  },

  data() {
    return {
      users: [],
      loading: true,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      modals: {
        editUser: false,
        newUser: false,
      },
      editUsername: null,
      editUID: null,
      editDisplayname: null,
      editRole: null,
      editLocation: null,

      newUsername: null,
      newDisplayname: null,
      newRole: null,
      newLocation: null,
    };
  },
  created() {
    if(store.getters.userrole != "3") {
      MessageBox.alert(
        "Zugriff verweigert.",
        {
          confirmButtonText: "OK",
          type: "error",
          callback: (action) => {
            router.push({ name: 'Overview' })
          },
        }
      );
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.users = [];
      // get list of users
      var list = httpsCallable(fc, "users-listUsers");
      list([]).then((result) => {
        this.users = result.data.users;
        this.loading = false;
      });
    },
    editUser(index, row) {
      this.editUID = row.uid;
      this.editUsername = row.email.split("@")[0];
      this.editDisplayname = row.displayName;
      this.editRole = row.customClaims.role;
      this.editLocation = row.customClaims.location;
      this.modals.editUser = true;
    },
    modifyUser() {
      this.modals.editUser = false;
      this.loading = true;
      var modify = httpsCallable(fc, "users-modifyUser");
      modify({
        uid: this.editUID,
        username: this.editUsername,
        displayName: this.editDisplayname,
        location: this.editLocation,
        role: this.editRole,
      }).then((result) => {
        this.loadData();
      });
    },
    addUser() {
      if (
        this.newUsername &&
        this.newDisplayname &&
        this.newLocation &&
        this.newRole
      ) {
        if (this.userExists(this.newUsername)) {
          MessageBox.alert(
            "Der Benutzer " + this.newUsername + " existiert bereits.",
            {
              confirmButtonText: "OK",
              type: "warning",
              callback: (action) => {},
            }
          );
        } else {
          this.modals.newUser = false;
          this.loading = true;
          var add = httpsCallable(fc, "users-addUser");
          add({
            username: this.newUsername,
            displayName: this.newDisplayname,
            location: this.newLocation,
            role: this.newRole,
          }).then((result) => {
            this.loading = false;
            MessageBox.alert(
              "Der Benutzer " +
                this.newUsername +
                " wurde erfolgreich angelegt. Das Passwort lautet: " +
                result.data,
              {
                confirmButtonText: "OK",
                type: "success",
                callback: (action) => {},
              }
            );
            this.newUsername = null;
            this.newDisplayname = null;
            this.newLocation = null;
            this.newRole = null;
            this.loadData();
          });
        }
      }
    },
    resetPassword(index, row) {
      this.loading = true;
      var reset = httpsCallable(fc, "users-resetPassword");
      reset({ uid: row.uid }).then((result) => {
        this.loading = false;
        MessageBox.alert(
          "Das Passwort von " +
            row.displayName +
            " wurde erfolgreich geändert. Das neue Passwort lautet: " +
            result.data,
          {
            confirmButtonText: "OK",
            type: "success",
            callback: (action) => {},
          }
        );
      });
    },
    removeUser(index, row) {
      MessageBox.confirm(
        "Soll der Benutzer " + row.displayName + " endgültig gelöscht werden?",
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              // remove user
              this.loading = true;
              var remove = httpsCallable(fc, "users-removeUser");
              remove({ uid: row.uid }).then((result) => {
                this.loadData();
              });
            }
          },
        }
      );
    },
    userExists(name) {
      return this.users.some(function (el) {
        return el.email.split("@")[0] === name;
      });
    },
  },
};
</script>
<style>
</style>
