<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <div class="text-center">
                    <img src='static/img/Kletterwald-Logo.png' width="50%" slot="header">
                  </div>
                  
                  <h3  class="header text-center">KET</h3>

                  <fg-input v-model="form.username" addon-left-icon="bi bi-person"
                            placeholder="Benutzername" autofocus required></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="bi bi-key" placeholder="Passwort"
                            type="password" required></fg-input>

                  <br>

                  <p-button native-type="submit" slot="footer" type="success" block class="mb-3">Anmelden</p-button>
                  <div v-if="error" class="alert alert-danger text-center mt-1"><i class="bi bi-exclamation-triangle-fill"></i> {{error}}</div>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-login.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppFooter from './Layout/AppFooter'
  import { firebaseApp } from '../../../../firebaseDb'

  export default {
    components: {
      Card,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    methods: {
      login() {
        firebaseApp
          .auth()
          .signInWithEmailAndPassword(this.form.username + '@weitblick-kletterwaelder.de', this.form.password)
          .then(data => {
            this.$router.push({ name: "Overview" })
          })
          .catch(err => {
            console.log("Auth error: " + err.code)
            this.form.password = ""
            if (err.code === "auth/user-not-found") {
              this.error = "Benutzer wurde nicht gefunden."
            } else if (err.code === "auth/wrong-password") {
              this.error = "Passwort ungültig."
            } else if (err.code === "auth/invalid-email") {
              this.error = "Der Benutzername ist ungültig."
            } else if (err.code === "auth/too-many-requests") {
              this.error = "Der Benutzer wurde aufgrund zu vieler fehlgeschlagener Anmeldeversuche temporär gesperrt."
            } else if (err.code === "auth/user-disabled") {
              this.error = "Der Benutzer ist deaktiviert."
            } else {
              this.error = "Anmeldung fehlgeschlagen (" + err.message + ")"
            }
          })
      }
    },
    data() {
      return {
        form: {
          username: '',
          password: ''
        },
        error: null
      }
    }
  }
</script>
<style>
</style>
