<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="title">Prüfungen</h5>
      </div>
      <SelectLocation v-if="role > 2" @locationChanged="updateLocation"></SelectLocation>
      <div class="col-md-auto text-right" v-if="role > 1">
        <p-button type="success" @click="newExam()" class="mt-0" v-if="!editMode"><i
            class="bi bi-file-earmark-plus"></i> Neue Prüfung</p-button>
        <p-button type="default" @click="editMode = false;" class="mt-0" v-if="editMode"><i class="bi bi-x-square"></i>
          Schließen</p-button>
      </div>
    </div>
    <div class="row pt-3" v-if="editMode == true">
      <div class="col">
        <div class="card mt-2 pl-3 pr-3">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-9">
                <h6 class="card-title">Prüfung erstellen/bearbeiten</h6>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col">
              
            </div>
          </div>
          <div class="row pr-3 mb-2">
            <div class="col text-right">
              <p-button type="default" @click="saveDraft()" class="mt-2 mr-3"><i class="bi bi-file-earmark-plus"></i>
                Entwurf speichern</p-button>
              <p-button type="success" @click="publish()" class="mt-2"><i class="bi bi-send"></i>
                Veröffentlichen</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <collapse>
          <div class="card mt-2 pl-3 pr-3" v-for="item in exams" :key="item.key">
            <collapse-item :title="item.title">
              <div class="row">
                <div class="col" v-html="item.text"></div>
              </div>
              <div class="row pt-3" v-if="item.status == 'Draft'">
                <div class="col text-right">
                  <p-button type="default" @click="edit(item.key, item.text)"><i class="bi bi-pencil-square"></i>
                    Bearbeiten</p-button>
                </div>
              </div>
            </collapse-item>
          </div>
        </collapse>
      </div>
    </div>
</div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover, Checkbox, MessageBox } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import Collapse from '../../../UIComponents/Collapse/Collapse.vue';
import CollapseItem from '../../../UIComponents/Collapse/CollapseItem.vue';
import { httpsCallable } from "firebase/functions";
import SelectLocation from '/src/components/KET/Views/Components/KETSelectLocation.vue'

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(Loading);

export default {
  components: {
    Collapse,
    CollapseItem,
    [Input.name]: Input,
    MessageBox,
    SelectLocation,
  },

  data() {
    return {
      exams: [],
      loading: false,
      editQuestions: [],
      editId: null,
      editMode: false,
      editCorrectAnswer: null,
      unsaved: false,
      location: store.getters.userlocation,
      role: store.getters.userrole,
    };
  },
  created() {
    this.loadData()
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsaved) {
      MessageBox.confirm(
        "Es gibt nicht gespeicherte Änderungen. Alle Änderungen gehen verloren. Seite wirklich verlassen?",
        "Warnung",
        {
          confirmButtonText: "Verlassen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              next()
            } else {
              next(false)
            }
          },
        }
      );
    } else {
      next()
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  methods: {
    loadData() {
      moment.locale("de");
      const examsRef = db.collection("exams");
      examsRef.where('locationID', 'array-contains', this.location).orderBy("createdAt", "desc").onSnapshot((snapshotChange) => {
        this.exams = [];
        snapshotChange.forEach((doc) => {
          var title = ""
          if (doc.data().status == "Draft") {
            title = "ENTWURF - " + moment(new Date(doc.data().createdAt.seconds * 1000))
              .format("DD. MMMM yyyy")
              .toString()
          } else if (doc.data().status == "Published") {
            title = "Prüfung - " + moment(new Date(doc.data().publishedAt.seconds * 1000))
              .format("DD. MMMM yyyy")
              .toString()
          }
            this.exams.push({
              key: doc.id,
              createdAt: doc.data().createdAt,
              publishedAt: doc.data().publishedAt,

              status: doc.data().status,
            });

        });
        this.loading = false
      });
    },
    saveDraft() {
      if (this.editId != null) {
        db.collection("exams").doc(this.editId)
          .update({

            status: 'Draft',
          })
          .then((docRef) => {
            this.unsaved = false;
            this.editId = docRef.id
            this.$notify({
              message: 'Entwurf wurde erfolgreich gespeichert!',
              icon: 'fa fa-check-circle',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'success',
              showClose: true,
              timeout: 5000
            })
          })
          .catch(() => {
            console.log("Saving draft failed");
          });
      } else {
        db.collection("exams")
          .add({

            status: 'Draft',
            createdAt: new Date(),
            locationID: [this.location]
          })
          .then((docRef) => {
            this.unsaved = false;
            this.editId = docRef.id
            this.$notify({
              message: 'Entwurf wurde erfolgreich gespeichert!',
              icon: 'fa fa-check-circle',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'success',
              showClose: true,
              timeout: 5000
            })
          })
          .catch(() => {
            console.log("Saving draft failed");
          });
      }
    },
    publish() {
      if (this.editId != null) {
        db.collection("exams").doc(this.editId)
          .update({

            publishedAt: new Date(),
            status: 'Published',

          })
          .then(() => {

              console.log("Send mail")
              var sendExam = httpsCallable(fc, "exam-sendMail");
              sendExam({
                examId: this.editId,
                loc: this.location
              })
            
            this.unsaved = false;
            this.editId = null;
            this.editMode = false;
            this.$notify({
              message: 'Prüfung wurde erfolgreich gesendet!',
              icon: 'fa fa-check-circle',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'success',
              showClose: true,
              timeout: 5000
            })
          })
          .catch((err) => {
            console.log("Publishing failed " + err);
          });
      } else {
        db.collection("exams")
          .add({
            
            status: 'Published',
            createdAt: new Date(),
            publishedAt: new Date(),
            locationID: [this.location],

          })
          .then((docRef) => {
              var sendExam = httpsCallable(fc, "exam-sendMail");
              sendExam({
                examId: docRef.Id,
                loc: this.location
              })
            
            this.unsaved = false;
            this.editId = null;
            this.editMode = false;
            this.$notify({
              message: 'Prüfung wurde erfolgreich gesendet!',
              icon: 'fa fa-check-circle',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'success',
              showClose: true,
              timeout: 5000
            })
          })
          .catch(() => {
            console.log("Publishing failed");
          });
      }

    },
    edit(id, text) {
      this.editText = text
      this.editId = id
      this.editMode = true;
      this.unsaved = true;
    },
    newExam() {
      this.editId = null;
      this.editMode = true;
      this.unsaved = true;
    },
    preventNav(event) {
      if (!this.unsaved) return
      event.preventDefault()
      event.returnValue = ""
    },
    updateLocation(loc) {
      this.location = loc
      this.loadData()
    }
  },
};
</script>
<style>
.el-table .cell {
  word-break: break-word !important;
}

</style>
