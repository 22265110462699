<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Trainer</h5>
        </div>
        <div class="row pb-3">
          <label class="col-md-3 col-form-label"
            >Verantwortlicher Trainer</label
          >
          <div class="col-sm-6">
            <ValidationProvider name="HT" rules="required" v-slot="{errors}">
              <el-select
                class="select"
                placeholder="Trainer wählen"
                filterable
                v-model="HT"
              >
                <el-option
                  v-for="option in trainer"
                  class="select"
                  :value="option.trainerName"
                  :label="option.trainerName"
                  :key="option.trainerName"
                >
                </el-option>
              </el-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Der Haupttrainer muss angegeben werden.': null}}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row pb-3">
          <label class="col-md-3 col-form-label">Weitere Trainer</label>
          <div class="col-sm-6">
            <ValidationProvider name="Trainer" rules="required" v-slot="{errors}">
              <el-select
                class="select"
                placeholder="Trainer wählen"
                filterable
                v-model="allTrainer"
                multiple
              >
                <el-option
                  v-for="option in trainer"
                  class="select"
                  :value="option.trainerName"
                  :label="option.trainerName"
                  :key="option.trainerName"                  
                >
                </el-option>
              </el-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Die Trainer müssen angegeben werden.': null}}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row pb-3" v-if="locationID != 'NB'">
          <label class="col-md-3 col-form-label"
            >Toiletten-Reinigung</label
          >
          <div class="col-sm-6">
            <ValidationProvider name="Toiletten" rules="required" v-slot="{errors}">
              <el-select
                class="select"
                placeholder="Trainer wählen"
                filterable
                v-model="Toiletten"
              >
                <el-option class="select" value="Keine Reinigung" label="Keine Reinigung" key="Keine Reinigung" selected></el-option>
                <el-option
                  v-for="option in trainer"
                  class="select"
                  :value="option.trainerName"
                  :label="option.trainerName"
                  :key="option.trainerName"
                >
                </el-option>
              </el-select>
              <span class="invalid mt-2">{{ errors[0] ? 'Bitte angeben, wer die Toiletten gereinigt hat.': null}}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row pb-3 pt-4">
          <label class="col-md-3 col-form-label"
            ></label
          >
          <div class="col-sm-6">
            <p-switch v-model="sendTrainerFeedback" type="primary">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
            </p-switch> Feedback an die Trainer senden (optional)
          </div>
        </div>
        <div class="row pb-3" v-if="sendTrainerFeedback">
          <label class="col-md-3 col-form-label"
            ></label
          >
          <div class="col-sm-6">
            <textarea
              class="form-control px-2"
              placeholder="Feedback an die Trainer..."
              rows="7"
              v-model="trainerFeedback"
            ></textarea>
            <span class="small"><i>Das Feedback wird per E-Mail an alle Trainer gesendet, die an dem Tag im Einsatz waren.</i></span>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store"
import PSwitch from 'src/components/UIComponents/Switch.vue'

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    PSwitch,
  },
  data() {
    return {
      trainer: [],
      HT: "",
      allTrainer: [],
      Toiletten: "Keine Reinigung",
      locationID: store.getters.userlocation,
      sendTrainerFeedback: false,
      trainerFeedback: "",
    };
  },
  created () {
    // get list of todays trainers
    const trainerRef = db.collection('trainer')
    trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).orderBy('Name').onSnapshot((snapshotChange) => {
      this.trainer = []
      this.allTrainer = []
      snapshotChange.forEach((doc) => {
        this.trainer.push({
          trainerName: doc.data().Name
        })
        this.allTrainer.push(
          doc.data().Name
        )
      })
    })
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        store.commit('SET_HT', this.HT)
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
  //width: 100% !important
}
</style>
