<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Bankeinzahlungen {{ monthHeader }}</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6" v-if="role == 3">
            <div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadReport"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>
            </div>
              <div class="col">
              <div>
              <el-date-picker
                v-model="monthStart"
                type="month"
                placeholder="Monat wählen"
                :clearable="false"
                :picker-options="pickerOptions1"
                format="MMMM yyyy"
                @change="loadReport"
              >
              </el-date-picker>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="report"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Einzahlungen gefunden werden."
            v-loading="loading"
          >
            <el-table-column prop="date" label="Datum">
            </el-table-column>
            <el-table-column prop="betrag" label="Betrag" align="right"> </el-table-column>
            <el-table-column prop="kasse" label="Kasse"> </el-table-column>
            <el-table-column prop="trainerName" label="Einzahlung durch">
            </el-table-column>
            <el-table-column prop="confirmed" label="">
              <template slot-scope="scope">
                <div v-if="scope.row.confirmed && scope.row.betragRaw != 0">
                  <el-tooltip
                    :content="
                      'Bestätigt am ' +
                      scope.row.confirmedAt +
                      ' Uhr von ' +
                      scope.row.confirmedBy
                    "
                    placement="top"
                  >
                    <i
                      class="bi bi-check-circle-fill"
                      style="font-size: 1.2rem; color: green"
                      @click="unconfirmTransfer(scope.$index, scope.row)"
                    ></i>
                  </el-tooltip>
                </div>

                <div v-if="!scope.row.confirmed && scope.row.betragRaw != 0">
                  <el-tooltip content="Einzahlung bestätigen" placement="top">
                    <p-button
                      type="default"
                      size="sm"
                      @click="confirmTransfer(scope.$index, scope.row)"
                    >
                      <i class="bi bi-check"></i> Bestätigen
                    </p-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Tooltip,
  Loading,
  Select,
  Option,
  MessageBox
} from "element-ui";
import moment from "moment-timezone";
import store from "../../../../store";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Loading);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    MessageBox
  },

  data() {
    return {
      report: [],
      monthStart: moment().startOf("month").toDate(),
      monthEnd: moment().endOf("month").toDate(),
      monthHeader: moment().format("MMMM YYYY"),
      loading: true,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
        shortcuts: [],
      },
    };
  },
  created() {
    moment.locale("de");
    // get data from reports of selected month
    this.loadReport();
  },
  methods: {
    loadReport() {
      this.loading = true
      this.monthEnd = moment(this.monthStart).endOf("month").toDate();
      this.monthHeader = moment(this.monthStart).format("MMMM YYYY");
      const listRef = db.collection("banktransfer");
      listRef
        .where('locationID', '==', this.location)
        .where("date", ">=", this.monthStart)
        .where("date", "<=", this.monthEnd)
        .orderBy("date")
        .onSnapshot((snapshotChange) => {
          this.report = [];
          snapshotChange.forEach((doc) => {
            var confirmedAt;
            var confirmedBy;
            var trainerEinzahlung;
            if (doc.data().confirmed) {
              confirmedAt = moment(
                new Date(doc.data().confirmedAt.seconds * 1000)
              )
                .format("DD.MM.YY HH:mm")
                .toString();
              confirmedBy = doc.data().confirmedBy;
            } else {
              confirmedAt = " ";
              confirmedBy = " ";
            }
            if (doc.data().betrag != 0) {
              trainerEinzahlung = doc.data().trainer
            } else {
              trainerEinzahlung = " "
            }
            this.report.push({
              key: doc.id,
              date: moment(new Date(doc.data().date.seconds * 1000))
                .format("dd, DD.MM.yyyy")
                .toString(),
              trainerName: trainerEinzahlung,
              kasse: doc.data().kasse,
              betrag:
                doc
                  .data()
                  .betrag.toFixed(2)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €",
              confirmed: doc.data().confirmed,
              confirmedAt: confirmedAt,
              confirmedBy: confirmedBy,
              betragRaw: doc.data().betrag,
            });
          });
          this.loading = false
        });
    },
    confirmTransfer(index, row) {
      const transferRef = db.collection("banktransfer");
      transferRef
        .doc(row.key)
        .update({
          confirmed: true,
          confirmedAt: new Date(),
          confirmedBy: store.getters.user.data.displayName,
        });
    },
    unconfirmTransfer(index, row) {
      MessageBox.confirm(
        "Diese Bankeinzahlung wurde bereits bestätigt. Soll die Bestätigung zurückgenommen werden?",
        "Warnung",
        {
          confirmButtonText: "Bestätigung zurücknehmen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              const transferRef = db.collection("banktransfer");
              transferRef
                .doc(row.key)
                .update({
                  confirmed: false,
                });
            } else {}
          },
        }
      );
    },
  },
};
</script>
<style>
</style>
