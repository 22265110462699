<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="card-title">Veranstaltungen</h5>
          </div>
          <div class="col-sm-3 text-right">
          <p-button type="primary" @click="newVA()" class="mt-0"
            >Neue Veranstaltung</p-button
          >
        </div>
          <div class="col-sm-3 text-right">
            <div class="row">
              <div class="col-md-12">
                <div>
                  <el-input
                    v-model="search"
                    placeholder="Veranstaltung suchen..."
                    ><i slot="prefix" class="bi bi-search"></i
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="
              veranstaltungen.filter(
                (data) =>
                  !search ||
                  data.company.toLowerCase().includes(search.toLowerCase()) ||
                  data.Id.toLowerCase().includes(search.toLowerCase()) ||
                  data.contact.toLowerCase().includes(search.toLowerCase())
              )
            "
            header-row-class-name="text-primary"
            :row-class-name="tablePastEvents"
            empty-text="Es konnten keine Veranstaltungen gefunden werden."
            v-loading="loading"
            @row-click="showDetails"
          >
            <el-table-column prop="Id" label="Angebot-Nr."> </el-table-column>
            <el-table-column prop="company" label="Firma/Verein">
            </el-table-column>
            <el-table-column prop="contact" label="Ansprechpartner">
            </el-table-column>
            <el-table-column prop="date" label="Datum"> </el-table-column>
            <el-table-column prop="vatrainer" label="Trainer">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label=""
            >
              <template slot-scope="props">
                <el-tooltip content="Veranstaltung löschen" placement="top">
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="removeVA(props.$index, props.row)"
                  >
                    <i class="fa fa-times"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Loading,
  Select,
  Option,
  MessageBox,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import { httpsCallable } from "firebase/functions";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
//Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    MessageBox,
  },

  data() {
    return {
      veranstaltungen: [],
      search: "",
      loading: true,
      location: store.getters.userlocation,
      role: store.getters.userrole,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      // get list of Veranstaltungen
      const veranstaltungenRef = db.collection("veranstaltungen");
      const dateLimit = moment().subtract(7, 'd').toDate();
      veranstaltungenRef
        .where("removed", "==", false)
        .orderBy("date", "desc")
        .limit(1000)
        .onSnapshot((snapshotChange) => {
          this.veranstaltungen = [];
          snapshotChange.forEach((doc) => {
            var location = "";
            if (doc.data.locationID == "DA") {
              location = "Darmstadt";
            } else if (doc.data.locationID == "NB") {
              location = "Neroberg";
            } else if (doc.data.locationID == "FRA") {
              location = "Frankfurt";
            }
            this.veranstaltungen.push({
              Id: doc.id,
              company: doc.data().company,
              contact: doc.data().contact,
              date: moment(new Date(doc.data().date.seconds * 1000))
                .format("DD.MM.yyyy")
                .toString(),
              vatrainer: doc.data().vatrainer,
              location: location,
            });
          });
          this.loading = false;
        });
    },
    showDetails(row, column) {
      if (column.property) {
        router.push({ name: "VaDetails", params: { vaId: row.Id } });
      }
    },
    newVA(index, row) {
      router.push({ name: "VaDetails", params: {} });
    },
    removeVA(index, row) {
      MessageBox.confirm(
        "Soll die Veranstaltung für " +
          row.company +
          " (" +
          row.Id +
          ") endültig gelöscht werden?",
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              // remove VA
              db.collection("veranstaltungen")
                .doc(row.Id)
                .update({ removed: true, lastUpdate: new Date() });
            }
          },
        }
      );
    },
    tablePastEvents({row, rowIndex}) {
        if (moment(row.date + ' 23:59:59', 'DD.MM.yyyy HH:mm:ss').isSameOrAfter() === false) {
          return 'pastEvent-row';
        }
        return '';
      }
  },
};
</script>
<style>
.el-table .pastEvent-row {
    background: lightyellow !important;
    font-style: italic !important;
    color: #767676 !important;
  }
</style>
