<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-7">
            <h5 class="card-title">Trainerschlüssel {{ monthHeader }}</h5>
          </div>
          <div class="col-sm-5 text-right">
            <div class="row">
              <div class="col" v-if="role == 3">
                <div>
                  <el-select
                    v-model="location"
                    placeholder="Standort wählen"
                    @change="loadReport"
                  >
                    <el-option key="NB" label="Neroberg" value="NB"></el-option>
                    <el-option
                      key="DA"
                      label="Darmstadt"
                      value="DA"
                    ></el-option>
                    <el-option
                      key="FRA"
                      label="Frankfurt"
                      value="FRA"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="col">
                <div>
                  <el-date-picker
                    v-model="monthStart"
                    type="month"
                    placeholder="Monat wählen"
                    :clearable="false"
                    :picker-options="pickerOptions1"
                    format="MMMM yyyy"
                    @change="loadReport"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="col">
                <div>
                  <p-button class="mb-0 mt-0" type="default" @click="startPsSync"
                    >Papershift Sync</p-button
                  >
                </div>
                <div class="pt-0">
                  <small class="text-muted"
                    >Letzte Aktualisierung: {{ lastSync }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="card-body row">
        <div class="col-sm-12 pb-3">
         <!-- <el-alert
            title="Hinweis"
            description="Ab 01. Oktober 2022 wird der Trainerschlüssel mit 20€/Stunde berechnet statt mit 15€/Stunde in vorherigen Zeiträumen."
            type="warning"
            show-icon
            :closable="false"
            class="mb-3"
            v-if="showHourRateHint">
          </el-alert> -->
          <el-table
            :data="report"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Einträge gefunden werden."
            v-loading="loading"
            show-summary
            sum-text="GESAMT"
            :summary-method="getSum"
            :cell-class-name="checkRate"
          >
            <el-table-column prop="date" label="Datum" width="220">
            </el-table-column>
            <el-table-column prop="zfinTotal" label="Z-Fin Gesamt" align="right" v-if="role == 3">
              <template slot-scope="scope">
                {{
                  scope.row.zfinTotal
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="trainerHours" label="Trainerstunden" align="right" v-if="role == 3">
              <template slot-scope="scope">
                {{
                  scope.row.trainerHours
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
              </template>
            </el-table-column>
            <el-table-column prop="trainerCosts" label="Trainerkosten" align="right" v-if="role == 3">
              <template slot-scope="scope">
                {{
                  scope.row.trainerCosts
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="rate" label="Anteil" align="right">
              <template slot-scope="scope">
                {{
                  scope.row.rate
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " %"
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../firebaseDb";
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Tooltip,
  Loading,
  Select,
  Option,
  MessageBox,
  Alert
} from "element-ui";
import moment from "moment-timezone";
import store from "../../../../store";
import router from "../../../../main";
import { httpsCallable } from "firebase/functions";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Alert);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    MessageBox
  },

  data() {
    return {
      loading: true,
      report: [],
      monthStart: moment().startOf("month").toDate(),
      monthEnd: moment().endOf("month").toDate(),
      monthHeader: moment().format("MMMM YYYY"),
      sumZfin: 0,
      sumCosts: 0,
      lastSync: null,
      lastSyncTimestamp: null,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
        shortcuts: [],
      },
      showHourRateHint: false
    };
  },
  created() {
    moment.locale("de");
    // get data from reports of selected month
    this.loadReport();
  },
  methods: {
    loadReport() {
        this.loading = true;
      // get last Papershift sync time
      const lastSyncRef = db.collection("papershiftSyncStatus");
      var docname = "loggedHours"
      lastSyncRef
        .doc(docname)
        .get()
        .then((doc) => {
          this.lastSync = moment(new Date(doc.data().lastSync.seconds * 1000))
            .format("DD.MM.YY HH:mm")
            .toString();
          this.lastSyncTimestamp = doc.data().lastSync.seconds * 1000
        });

        this.monthEnd = moment(this.monthStart).endOf("month").toDate();
        this.monthHeader = moment(this.monthStart).format("MMMM YYYY");
        const listRef = db.collection("tagesreports");
        var zfin = 0;
        var trainerHoursSum = 0
        listRef.where("Date", ">=", this.monthStart).where("Date", "<=", this.monthEnd).where("locationID", "==", this.location).orderBy("Date").onSnapshot((snapshotChange) => {
          this.report = [];
          snapshotChange.forEach((doc) => {
            var kasseRef = db.collection("tagesreports/" + doc.id + "/Kassenabschluss");
            var trainerHoursRef = db.collection("tagesreports/" + doc.id + "/trainerHours");
            var promises = []
            promises.push(kasseRef.where('isValid', '==', true).get())
            promises.push(trainerHoursRef.get())

            Promise.all(promises).then((res) => {
              zfin = 0;
              trainerHoursSum = 0
              res[0].forEach(element => {
                zfin = zfin + +element.data().ZFin
              })
              res[1].forEach(element => {
                trainerHoursSum = trainerHoursSum + +element.data().durationNet
              })

              let trainerHoursResult = trainerHoursSum / 1000 / 60 / 60
              var hourRate = 0
              if (this.monthStart >= new Date('2022-10-01 00:00:00')) {
                this.showHourRateHint = true
                hourRate = 20
              } else {
                this.showHourRateHint = false
                hourRate = 15
              }
              let trainerCosts = trainerHoursResult * hourRate
              let rate = 0
              if(trainerCosts > 0 && zfin > 0) {
                rate = (trainerCosts / zfin) * 100
              } else {
                rate = 0
              }

              this.report.push({
                date: moment(new Date(doc.data().Date.seconds * 1000))
                  .format("dd, DD.MM.yyyy")
                  .toString(),
                trainerHours: trainerHoursResult,
                trainerCosts: trainerCosts,
                zfinTotal: zfin,
                rate: rate,
              });
            })
          })
        });
      this.loading = false;
    },
    startPsSync() {
      if(new Date().getTime() - this.lastSyncTimestamp > 600000) { // greater 10mins
        let startDate = moment(this.monthStart).format("YYYY-MM-DD").toString()
        let endDate = moment(this.monthEnd).format("YYYY-MM-DD").toString()
        console.log("Start PS time tracking sync from " + startDate + " to " + endDate)
        const loading = this.$loading({
            lock: true,
            text: 'Papershift Sync läuft...',
            spinner: 'el-icon-loading'
          });
       var getTimes = httpsCallable(fc, "papershift-getPSTimeTracking");
        getTimes({
          start: startDate,
          end: endDate
        }).then((result) => {
          console.log('PS time tracking sync done')
          this.loadReport()
          loading.close()
        });
      } else {
        MessageBox.alert(
            "Die erfassten Zeiten können erst 10 Minuten nach der letzten Synchronisation erneut aus Papershift abgerufen werden. Die letzte Synchronisation war am " + this.lastSync + " Uhr.",
            {
              confirmButtonText: "OK",
              type: "warning",
              callback: (action) => {},
            }
          );
      }
    },
    getSum(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (this.role != 3) {
          sums[index] = "";
          return;
        }
        if (index === 0) {
          sums[index] = "GESAMT";
          return;
        }

        const values = data.map((item) => Number(item[column.property]));
        if (index === 2) {
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values
              .reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0)
              .toFixed(2)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
          } else {
            sums[index] = "N/A";
          }
        } else if (index === 4) {
          if(this.sumZfin > 0 && this.sumCosts > 0) {
            sums[index] = (this.sumCosts / this.sumZfin * 100)
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " %";
          } else {
            sums[index] = "N/A";
          }
        } else {
          if (!values.every((value) => isNaN(value))) {
            let result = values
                .reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0)
            if (index === 1) {
              this.sumZfin = result
            } else if (index === 3) {
              this.sumCosts = result
            }
            sums[index] =
              result
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";
          } else {
            sums[index] = "N/A";
          }
        }
      });

      return sums;
    },
    checkRate({ row, column }) {
      if (column.property === "rate") {
        const val = row[column.property];
        if (val <= 30) {
          return "green-row";
        } else if (val > 30 && val <= 50) {
          return "yellow-row";
        } else {
          return "red-row";
        }
      }
    },
  },
};
</script>
<style>
.el-table__footer-wrapper tbody td.el-table__cell,
.el-table__header-wrapper tbody td.el-table__cell {
  font-weight: bold;
}
.red-row {
  background: #f8d7da !important;
}
.yellow-row {
  background: #fff3cd !important;
}
.green-row {
  background: #d4edda !important;
}
</style>
