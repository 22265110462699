<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      Angemeldeter Benutzer: {{ displayName }}
    </div>

    <template slot="navbar-menu">
      <li class="nav-item">
        <a
          class="nav-link"
          @click.prevent="showFeedback"
          style="cursor: pointer"
        >
          <i
            class="bi bi-chat-left-heart mr-1"
            style="font-size: 1.2rem"
            title="Feedback zur Arbeit im Kletterwald"
          ></i>
          <p>
            <span class="d-md-block"> Feedback</span>
          </p>
        </a>
      </li>
      <li class="nav-item" v-if="role > 0">
        <a
          class="nav-link"
          @click.prevent="showUserSettings"
          style="cursor: pointer"
        >
          <i
            class="bi bi-person"
            style="font-size: 1.2rem"
            title="Passwort ändern"
          ></i>
          <p>
            <span class="d-lg-none d-md-block">Passwort ändern</span>
          </p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click.prevent="signOut" style="cursor: pointer">
          <i
            class="bi bi-box-arrow-right"
            style="font-size: 1.2rem"
            title="Abmelden"
          ></i>
          <p>
            <span class="d-lg-none d-md-block"> Abmelden</span>
          </p>
        </a>
      </li>
    </template>
    <modal :show.sync="modals.feedback" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">Feedback Kletterwald</h5>
      <div class="row">
        <p>
          Feedback zur Arbeit im Kletterwald? Nutze gerne das folgende Feld um deine Rückmeldung dem Team der Parkleitung zukommen zu lassen. Dein Feedback ist anonym und nur für die Parkleitung zugänglich.
        </p>
      </div>
      <div class="row pt-3 pb-3">
        <textarea
          class="form-control px-2"
          placeholder="Dein Feedback..."
          rows="7"
          v-model="newFeedback"
        ></textarea>
      </div>
      <div class="row pt-3 pb-3">
        <label>Auf welchen Standort bezieht sich dein Feedback?</label>
        <el-select
                v-model="newFeedbackLocation"
                placeholder="Standort wählen"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
        </el-select>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button
            type="default"
            link
            @click="
              modals.feedback = false;
              newFeedback = '';
            "
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="saveFeedback"
            >Senden</p-button
          >
        </div>
      </template>
    </modal>
    <modal
      :show.sync="modals.changePassword"
      headerClasses="justify-content-center"
    >
      <h5 slot="header" class="title mb-0">Passwort ändern</h5>
      <div class="row">
        <p>
          Das neue Passwort muss 8-20 Zeichen lang sein und
          Groß-/Kleinbuchstaben, Zahlen sowie Sonderzeichen enthalten.
        </p>
      </div>
      <div class="row pt-4">
        <fg-input
          placeholder="Aktuelles Passwort"
          label="Aktuelles Passwort"
          type="password"
          class="col-8"
          v-model="currentPassword"
        ></fg-input>
      </div>
      <div class="row pt-3">
        <fg-input
          placeholder="Neues Passwort"
          label="Neues Passwort"
          type="password"
          class="col-8"
          v-model="newPassword"
        ></fg-input>
      </div>
      <div class="row pt-3">
        <fg-input
          placeholder="Neues Passwort wiederholen"
          label="Neues Passwort wiederholen"
          type="password"
          class="col-8"
          v-model="newPasswordRepeat"
        ></fg-input>
      </div>
      <div class="row pt-4">
        <div
          class="alert alert-danger"
          role="alert"
          v-if="passwordFormValidationErrors.length > 0"
        >
          <b>Bitte die folgenden Fehler beheben:</b>
          <ul>
            <li v-for="error in passwordFormValidationErrors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button
            type="default"
            link
            @click="
              modals.changePassword = false;
              newPassword = '';
              newPasswordRepeat = '';
              currentPassword = '';
            "
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="changePassword"
            >Passwort ändern</p-button
          >
        </div>
      </template>
    </modal>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";
import { provider, db, firebaseApp } from "../../../firebaseDb";
import store from "../../../store";
import { Modal } from "src/components/UIComponents";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { Select, Option } from "element-ui";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      displayName: store.getters.user.data.displayName,
      username: store.getters.user.data.email,
      role: store.getters.userrole,
      modals: {
        feedback: false,
        changePassword: false,
      },
      newFeedback: "",
      newFeedbackLocation: "",
      passwordFormValidationErrors: [],
      newPassword: null,
      newPasswordRepeat: null,
      currentPassword: null,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    signOut() {
      firebaseApp
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Login",
          });
        });
    },
    showFeedback() {
      this.modals.feedback = true;
    },
    showUserSettings() {
      this.modals.changePassword = true;
    },
    saveFeedback() {
      if (this.newFeedback) {
        db.collection("feedback")
          .add({
            text: this.newFeedback,
            user: this.displayName,
            timestamp: new Date(),
            location: this.newFeedbackLocation,
          })
          .then(() => {
            this.modals.feedback = false;
            this.newFeedback = "";
            this.$notify({
              message: 'Feedback wurde erfolgreich gesendet!',
              icon: 'fa fa-check-circle',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'success',
              showClose: true,
              timeout: 5000
            })
          })
          .catch(() => {
            console.log("Adding Feedback failed");
          });
      } else {
        this.modals.feedback = false;
      }
    },
    changePassword() {
      this.passwordFormValidationErrors = [];
      if (this.newPassword !== this.newPasswordRepeat) {
        this.passwordFormValidationErrors.push(
          "Die eingebenen Passwörter sind nicht identisch."
        );
      }
      if (this.newPassword.length < 8) {
        this.passwordFormValidationErrors.push(
          "Das Passwort ist nicht ausreichend lang (mind. 8 Zeichen)."
        );
      }
      if (this.newPassword.length > 20) {
        this.passwordFormValidationErrors.push(
          "Das Passwort ist zu lang (max. 20 Zeichen)"
        );
      }
      if (!this.newPassword.match(/[a-z]+/)) {
        this.passwordFormValidationErrors.push(
          "Das Passwort enthält keine Kleinbuchstaben."
        );
      }
      if (!this.newPassword.match(/[A-Z]+/)) {
        this.passwordFormValidationErrors.push(
          "Das Passwort enthält keine Großbuchstaben."
        );
      }
      if (!this.newPassword.match(/[0-9]+/)) {
        this.passwordFormValidationErrors.push(
          "Das Passwort enthält keine Ziffern."
        );
      }
      if (!this.newPassword.match(/[$@$!%*?&+-.,]+/)) {
        this.passwordFormValidationErrors.push(
          "Das Passwort enthält keine Sonderzeichen"
        );
      }
      if (this.passwordFormValidationErrors.length < 1) {
        const user = firebaseApp.auth().currentUser;
        user
          .updatePassword(this.newPassword)
          .then(() => {
            this.$notify({
              message: "Das Passwort wurde erfolgreich geändert!",
              icon: "fa fa-check-circle",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "success",
              showClose: true,
              timeout: 5000,
            });
            this.newPassword = null;
            this.newPasswordRepeat = null;
            this.modals.changePassword = false;
          })
          .catch((error) => {
            if (error.code === "auth/requires-recent-login") {
              const cred = firebase.auth.EmailAuthProvider.credential(
                this.username,
                this.currentPassword
              );
              user
                .reauthenticateWithCredential(cred)
                .then(() => {
                  user.updatePassword(this.newPassword).then(() => {
                    this.$notify({
                      message: "Das Passwort wurde erfolgreich geändert!",
                      icon: "fa fa-check-circle",
                      horizontalAlign: "right",
                      verticalAlign: "bottom",
                      type: "success",
                      showClose: true,
                      timeout: 5000,
                    });
                    this.newPassword = null;
                    this.newPasswordRepeat = null;
                    this.currentPassword = null;
                    this.modals.changePassword = false;
                  });
                })
                .catch(() => {
                  this.$notify({
                    message: "Die Passwortänderung ist fehlgeschlagen!",
                    icon: "fa fa-triangle-exclamation",
                    horizontalAlign: "right",
                    verticalAlign: "bottom",
                    type: "warning",
                    showClose: true,
                    timeout: 5000,
                  });
                  this.newPassword = null;
                  this.newPasswordRepeat = null;
                  this.currentPassword = null;
                  this.modals.changePassword = false;
                });
            } else {
              this.$notify({
                message: "Die Passwortänderung ist fehlgeschlagen!",
                icon: "fa fa-triangle-exclamation", 
                horizontalAlign: "right",
                verticalAlign: "bottom",
                type: "warning",
                showClose: true,
                timeout: 5000,
              });
              this.newPassword = null;
              this.newPasswordRepeat = null;
              this.currentPassword = null;
              this.modals.changePassword = false;
            }
            console.log(error);
          });
      }
    },
  },
  created() {
    firebaseApp.auth().currentUser.getIdTokenResult(true).then((tokenResult) => {
      this.role = tokenResult.claims.role;
    });
  },
};
</script>
<style>
</style>
