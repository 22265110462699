<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <h5>Tagesprotokoll</h5>
        <wizard @complete="wizardComplete">
          <template slot="header">
            <h3 class="card-title"></h3>
            <h3 class="description"></h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="bi bi-clock"></i>
              Zeiten & Wetter
            </template>
            <Step1
              ref="step1"
              @on-validated="onStepValidated"
            ></Step1>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="bi bi-people"></i>
              Trainer
            </template>
            <Step2
              ref="step2"
              @on-validated="onStepValidated"
            ></Step2>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="bi bi-map"></i>
              Parcours
            </template>
            <Step3
              ref="step3"
              @on-validated="onStepValidated"
            ></Step3>
          </wizard-tab>
          
          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="bi bi-bandaid"></i>
              Besondere Vorkommnisse
            </template>
            <Step4
              ref="step4"
              @on-validated="onStepValidated"
            ></Step4>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, Timestamp } from "../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Step1 from "./Tagesprotokoll/Step1.vue";
import Step2 from './Tagesprotokoll/Step2.vue'
import Step3 from './Tagesprotokoll/Step3.vue'
import Step4 from './Tagesprotokoll/Step4.vue'
import store from "../../../../store";

extend("required", required);


export default {
  components: {
    MessageBox,
    Wizard,
    WizardTab,
    Step1,
    Step2,
    Step3,
    Step4
  },

  data() {
    return {
      wizardModel: {},
      reportName: '',
      locationID: store.getters.userlocation,
      saved: false
    };
  },
  created() {
    this.locationID = store.getters.userlocation
    this.reportName = moment().format('YYYYMMDD') + '-' + store.getters.userlocation
    const listRef = db.collection('tagesreports/' + this.reportName + '/Parcours')
      listRef.get().then((doc) => {
          if(doc.docs.length > 0) {
            this.saved = true
            MessageBox.alert('Das Tagesprotokoll wurde bereits für heute gespeichert und kann daher nicht erneut ausgefüllt werden.', 'Achtung', {
              confirmButtonText: 'OK',
              callback: action => {
                router.push({ name: 'Overview' })
              }
            })
          }
      })
  },
  beforeRouteLeave(to, from, next) {
    if(!this.saved) {
      MessageBox.confirm(
        "Das Tagesprotokoll wurde nicht gespeichert. Alle Änderungen gehen verloren. Seite wirklich verlassen?",
        "Warnung",
        {
          confirmButtonText: "Verlassen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              next()
            } else {
              next(false)
            }
          },
        }
      );
    } else {
      next()
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      const listRef = db.collection('tagesreports/' + this.reportName + '/Parcours')
      listRef.get().then((doc) => {
          if(doc.docs.length > 0) {
            MessageBox.alert('Das Tagesprotokoll wurde bereits für heute gespeichert und kann daher nicht erneut ausgefüllt werden.', 'Achtung', {
              confirmButtonText: 'OK',
              callback: action => {}
            })
          } else {
            this.saveList()
          }
      })
    },
    saveList() {
      const loading = this.$loading({
          lock: true,
          text: 'Tagesprotokoll wird gespeichert...',
          spinner: 'el-icon-loading'
      });

      const listRef = db.collection('tagesreports')
      const listParcRef = db.collection('tagesreports/' + this.reportName + '/Parcours')

      var batch = db.batch()

      console.log("Save Tagesprotokoll")
      console.log("VALUES - Open: " + this.$refs['step1'].open + " Close: " + this.$refs['step1'].close + " Wetter: " + this.$refs['step1'].wetter + " HT: " + this.$refs['step2'].HT + " Toiletten: " + this.$refs['step2'].Toiletten)

      batch.set(listRef.doc(this.reportName), {
        Date: Timestamp.fromDate(new Date()),
        Open: this.$refs['step1'].open,
        Close: this.$refs['step1'].close,
        Wetter: this.$refs['step1'].wetter,
        HT: this.$refs['step2'].HT,
        Trainer: this.$refs['step2'].allTrainer.join(', '),
        Toiletten: this.$refs['step2'].Toiletten,
        TrainerFeedback: this.$refs['step2'].trainerFeedback,
        BesondereVorkommnisse: this.$refs['step4'].besondereVorkommnisse,
        locationID: this.locationID
      }, { merge: true })

      this.$refs['step3'].parcours.forEach(parc => {
        const id = listParcRef.doc().id
        batch.set(listParcRef.doc(id), { Name: parc.parcoursName, Inspector: parc.inspector, Condition: parc.condition, Position: parc.position, Note: parc.note })
      });
        batch.commit().then(() => {
          this.saved = true
          loading.close();
          this.$notify({
            message: 'Das Tagesprotokoll wurde erfolgreich gespeichert!',
            icon: 'fa fa-check-circle',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'success',
            showClose: true,
            timeout: 5000
          })
          router.push({ name: 'Overview' })
        })
        
    },
    preventNav(event) {
      if (this.saved) return
      event.preventDefault()
      event.returnValue = ""
    }
  },
};
</script>
<style>
</style>
